import React from 'react';
import { Row, Col, Form, Accordion, Card, Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
// import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { textApi } from "services";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Modeling
      config_model: {},
      modeling_execute_modeling_logistic_regression: 0,
      modeling_execute_modeling_random_forest: 0,
      modeling_parameters_random_search: 0,
      modeling_parameters_grid_search: 0,
    }
  }

  componentDidMount() {
    //Modeling
    fetch("http://localhost:3030" + "/config/text/model")
      .then(response => response.json())
      .then(data => {
        this.setState({ config_model: data._sections });
        const { config_model } = this.state
        console.log('Modeling');
        console.log(config_model);
        this.setState({ modeling_execute_modeling_logistic_regression: config_model.modeling['execute.modeling.logistic_regression'] === 'True' ? true : false })
        this.setState({ modeling_execute_modeling_random_forest: config_model.modeling['execute.modeling.random_forest'] === 'True' ? true : false })
        this.setState({ modeling_parameters_random_search: config_model.modeling['parameters.random_search'] === 'True' ? true : false })
        this.setState({ modeling_parameters_grid_search: config_model.modeling['parameters.grid_search'] === 'True' ? true : false })
      })
  }

  handleClick = () => {
    //Modeling
    const { config_model, modeling_execute_modeling_logistic_regression, modeling_execute_modeling_random_forest, modeling_parameters_random_search, modeling_parameters_grid_search } = this.state

    config_model.modeling['execute.modeling.logistic_regression'] = modeling_execute_modeling_logistic_regression === true ? 'True' : 'False'
    config_model.modeling['execute.modeling.random_forest'] = modeling_execute_modeling_random_forest === true ? 'True' : 'False'
    config_model.modeling['parameters.random_search'] = modeling_parameters_random_search === true ? 'True' : 'False'
    config_model.modeling['parameters.grid_search'] = modeling_parameters_grid_search === true ? 'True' : 'False'

    fetch("http://localhost:3030" + '/config/text/model', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config_model)
    }).then(() => {
      console.log('Modeling Request posted')
    })
  }

  render() {
    //Modeling
    const { modeling_execute_modeling_logistic_regression, modeling_execute_modeling_random_forest, modeling_parameters_random_search, modeling_parameters_grid_search } = this.state

    return (
      <Accordion.Item eventKey="1">
        <Accordion.Header >
          Modeling
        </Accordion.Header>
        <Accordion.Body>
            <h5>[modeling]</h5>
            <Row>
              <Col md={4}>
                <Form.Check inline label="execute.modeling.logistic_regression" type={'switch'} id={'check19'} checked={modeling_execute_modeling_logistic_regression} onChange={changeEvent => this.setState({ modeling_execute_modeling_logistic_regression: changeEvent.target.checked })} />
                <br />
              </Col>
              <Col md={4}>
                <Form.Check inline label="execute.modeling.random_forest" type={'switch'} id={'check20'} checked={modeling_execute_modeling_random_forest} onChange={changeEvent => this.setState({ modeling_execute_modeling_random_forest: changeEvent.target.checked })} />
                <br />
              </Col>
              <Col md={4}>
                <Form.Check inline label="parameters.random_search" type={'switch'} id={'check21'} checked={modeling_parameters_random_search} onChange={changeEvent => this.setState({ modeling_parameters_random_search: changeEvent.target.checked })} />
                <br />
              </Col>
              <Col md={4}>
                <Form.Check inline label="parameters.grid_search" type={'switch'} id={'check22'} checked={modeling_parameters_grid_search} onChange={changeEvent => this.setState({ modeling_parameters_grid_search: changeEvent.target.checked })} />
                <br />
              </Col>
            </Row>
        </Accordion.Body>
      </Accordion.Item>
    )
  }
}

export default Index;
