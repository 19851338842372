import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Row, Col, Form, Accordion, Card, Button } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import RangeSlider from "react-bootstrap-range-slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";

const Index = forwardRef((props, ref) => {
  const [transformation_blackout, setTransformationBlackout] = useState(false);
  const [transformation_contrast, setTransformationContrast] = useState(false);
  const [transformation_occlusion, setTransformationOcclusion] =
    useState(false);
  const [transformation_translate, setTransformationTranslate] =
    useState(false);
  const [transformation_blur, setTransformationBlur] = useState(false);
  const [transformation_shear, setTransformationShear] = useState(false);
  const [transformation_rotation, setTransformationRotation] = useState(false);
  const [transformation_zoom, setTransformationZoom] = useState(false);
  const [parameter_n_samples, setParameterNSamples] = useState(0);
  const [
    parameter_neuron_coverage_threshold,
    setParameterNeuronCoverageThreshold,
  ] = useState(0);
  const [configDeepxplore, setConfigDeepxplore] = useState({});

  useEffect(() => {
    fetch("http://localhost:3030" + "/config/image/deep")
      .then((response) => response.json())
      .then((data) => {
        setConfigDeepxplore(data._sections);
        const { transformation } = data._sections;
        setTransformationBlackout(
          transformation["constrain.blackout"] === "True"
        );
        setTransformationContrast(
          transformation["constrain.contrast"] === "True"
        );
        setTransformationOcclusion(
          transformation["constrain.occlusion"] === "True"
        );
        setTransformationTranslate(
          transformation["constrain.translate"] === "True"
        );
        setTransformationBlur(transformation["constrain.blur"] === "True");
        setTransformationShear(transformation["constrain.shear"] === "True");
        setTransformationRotation(
          transformation["constrain.rotation"] === "True"
        );
        setTransformationZoom(transformation["constrain.zoom"] === "True");
        setParameterNSamples(data._sections.parameter.n_samples);
        setParameterNeuronCoverageThreshold(
          data._sections.parameter.neuron_coverage_threshold
        );
      });
  }, []);
  useImperativeHandle(ref, () => ({
    handleClick() {
      handleClick();
    },
  }));
  const handleClick = () => {
    setConfigDeepxplore((prev) => {
      let tempVariable = { ...prev };
      tempVariable.transformation["constrain.blackout"] =
        transformation_blackout ? "True" : "False";
      tempVariable.transformation["constrain.contrast"] =
        transformation_contrast ? "True" : "False";
      tempVariable.transformation["constrain.occlusion"] =
        transformation_occlusion ? "True" : "False";
      tempVariable.transformation["constrain.translate"] =
        transformation_translate ? "True" : "False";
      tempVariable.transformation["constrain.blur"] = transformation_blur
        ? "True"
        : "False";
      tempVariable.transformation["constrain.shear"] = transformation_shear
        ? "True"
        : "False";
      tempVariable.transformation["constrain.rotation"] =
        transformation_rotation ? "True" : "False";
      tempVariable.transformation["constrain.zoom"] = transformation_zoom
        ? "True"
        : "False";
      tempVariable.parameter.n_samples = parameter_n_samples;
      tempVariable.parameter.neuron_coverage_threshold =
        parameter_neuron_coverage_threshold;
      return tempVariable;
    });

    fetch("http://localhost:3030" + "/config/image/deep", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(configDeepxplore),
    }).then(() => {
      console.log("Deepxplore Request posted");
    });
  };

  return (
    <div>
      <h5>[transformation]</h5>
      <Form>
        <Form.Check
          inline
          label="Blackout"
          type="switch"
          id="check34"
          checked={transformation_blackout}
          onChange={(e) => setTransformationBlackout(e.target.checked)}
        />
        <Form.Check
          inline
          label="Contrast"
          type="switch"
          id="check35"
          checked={transformation_contrast}
          onChange={(e) => setTransformationContrast(e.target.checked)}
        />
        <Form.Check
          inline
          label="Occlusion"
          type="switch"
          id="check36"
          checked={transformation_occlusion}
          onChange={(e) => setTransformationOcclusion(e.target.checked)}
        />
        <Form.Check
          inline
          label="Translate"
          type="switch"
          id="check37"
          checked={transformation_translate}
          onChange={(e) => setTransformationTranslate(e.target.checked)}
        />
        <Form.Check
          inline
          label="Blur"
          type="switch"
          id="check38"
          checked={transformation_blur}
          onChange={(e) => setTransformationBlur(e.target.checked)}
        />
        <Form.Check
          inline
          label="Shear"
          type="switch"
          id="check39"
          checked={transformation_shear}
          onChange={(e) => setTransformationShear(e.target.checked)}
        />
        <Form.Check
          inline
          label="Rotation"
          type="switch"
          id="check40"
          checked={transformation_rotation}
          onChange={(e) => setTransformationRotation(e.target.checked)}
        />
        <Form.Check
          inline
          label="Zoom"
          type="switch"
          id="check41"
          checked={transformation_zoom}
          onChange={(e) => setTransformationZoom(e.target.checked)}
        />
        <br />
        <br />
        <h5>[parameter]</h5>
        <Row>
          <Col md={6}>
            n_samples
            <RangeSlider
              value={parameter_n_samples}
              min={32}
              max={128}
              step={2}
              tooltip={"on"}
              onChange={(e) => setParameterNSamples(e.target.value)}
            />
          </Col>
          <Col md={6}>
            neuron_coverage_threshold
            <RangeSlider
              value={parameter_neuron_coverage_threshold}
              min={0}
              max={1}
              step={0.01}
              tooltip={"on"}
              onChange={(e) =>
                setParameterNeuronCoverageThreshold(e.target.value)
              }
            />
          </Col>
        </Row>
      </Form>
      <br />
      <Button onClick={handleClick}>Save</Button>
    </div>
  );
});

export default Index;
