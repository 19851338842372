import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Row, Col, Accordion, Card, Button } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import RangeSlider from "react-bootstrap-range-slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";

const Index = forwardRef((props, ref) => {
  const [configModel, setConfigModel] = useState({});
  const [argumentEpochs, setArgumentEpochs] = useState(0);
  const [argumentBatchSize, setArgumentBatchSize] = useState(0);

  useEffect(() => {
    fetch("http://localhost:3030/config/image/model")
      .then((response) => response.json())
      .then((data) => {
        setConfigModel(data._sections);
        setArgumentEpochs(data._sections.argument.epochs);
        setArgumentBatchSize(data._sections.argument.batch_size);
      });
  }, []);
  useImperativeHandle(ref, () => ({
    handleClick() {
      handleClick();
    },
  }));
  const handleClick = () => {
    //Modeling
    setConfigModel((prev) => {
      let TempVariable = { ...prev };
      TempVariable.argument.epochs = argumentEpochs;
      TempVariable.argument.batch_size = argumentBatchSize;
      return TempVariable;
    });
    fetch("http://localhost:3030/config/image/model", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(configModel),
    }).then(() => {
      console.log("Modeling Request posted");
    });
  };
  return (
    <div>
      <h5>[argument]</h5>
      <Row>
        <Col md={6}>
          epochs
          <RangeSlider
            value={argumentEpochs}
            min={0}
            max={100}
            step={2}
            tooltip={"on"}
            tooltipPlacement={"bottom"}
            onChange={(e) => setArgumentEpochs(e.target.value)}
          />
        </Col>
        <Col md={6}>
          batch_size
          <RangeSlider
            value={argumentBatchSize}
            min={32}
            max={128}
            step={2}
            tooltip={"on"}
            tooltipPlacement={"bottom"}
            onChange={(e) => setArgumentBatchSize(e.target.value)}
          />
        </Col>
      </Row>
      <br />
    </div>
  );
});

export default Index;
