import React from "react";
import ImageGallery from "react-image-gallery";
import "./Gallery.css";
import LazyLoad from "react-lazy-load";
import useImageLightBox from "hooks/Lightbox/useImageLightBox";
const Gallery = (props) => {
  const { Imagebox, setData } = useImageLightBox();
  const { data, path } = props;
  const fetchImages = (data, path) => {
    var imagesList = [];
    var images = data.img_path;
    for (var i = 0; i < images.length; i++) {
      imagesList.push({
        original: path + images[i],
        thumbnail: path + images[i],
      });
    }
    return imagesList;
  };

  if (data == null) return null;
  if (data.img_path !== undefined) {
    var imagesList = fetchImages(data, path);
    if (imagesList.length === 0) return null;
    return (
      <div className="py-2">
        <LazyLoad offset={300}>
          <ImageGallery
            items={imagesList}
            lazyLoad
            thumbnailPosition="left"
            showPlayButton={false}
            useBrowserFullscreen={false}
            onClick={(data) => {
              console.log(data.target.currentSrc);
              setData(data.target.currentSrc);
            }}
          />
        </LazyLoad>
        <Imagebox />
      </div>
    );
  } else if (data.data_path !== undefined) {
    return null;
  }
  return null;
};

export default Gallery;
