import Parse from "./parseService";

export function resetPassword(username) {
  return Parse.User.requestPasswordReset(username);
}

export function getCurrentUser() {
  return Parse.User.current();
}

export function login(username, password) {
  return Parse.User.logIn(username, password, { usePost: true });
}

export function logout() {
  return Parse.User.logOut();
}

export function createRoles() {
  const roles = [
    { name: "Administrator", acl: new Parse.ACL() },
    { name: "User", acl: new Parse.ACL() },
    { name: "Admin", acl: new Parse.ACL() },
  ];

  roles.forEach((role) => {
    role.acl.setPublicReadAccess(true);
    role.acl.setPublicWriteAccess(true);

    const query = new Parse.Query(Parse.Role);
    query.equalTo("name", role.name);

    query
      .first()
      .then((result) => {
        if (!result) {
          const newRole = new Parse.Role(role.name, role.acl);
          newRole
            .save()
            .then(() => {
              console.log(`Role ${role.name} created`);
            })
            .catch((error) => {
              console.log(`Error creating role ${role.name}: ${error}`);
            });
        } else {
          console.log(`Role ${role.name} already exists`);
        }
      })
      .catch((error) => {
        console.log(`Error querying role ${role.name}: ${error}`);
      });
  });
}

export async function signup(username, password) {
  const user = new Parse.User({
    username,
    password,
    email: username,
    Themecolor: "#a03c64",
    BGcolor: "#000",
    Textcolor: "#fff",
  });
  try {
    const adminRoleQuery = new Parse.Query(Parse.Role);
    adminRoleQuery.equalTo("name", "Administrator");
    const adminRole = await adminRoleQuery.first();
    const relation = adminRole.getUsers();

    const usersQuery = relation.query();
    const users = await usersQuery.find();

    if (users.length < 1) {
      await user
        .signUp()
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err;
        });

      const adminRoleRelation = adminRole.relation("users");
      adminRoleRelation.add(user);

      initialEnterprises();

      await adminRole.save();
    } else {
      const userRoleQuery = new Parse.Query(Parse.Role);
      userRoleQuery.equalTo("name", "User");
      const userRole = await userRoleQuery.first();
      const userRoleRelation = userRole.relation("users");
      let response = await user
        .signUp()
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err.message;
        });
      if (response === "Account already exists for this username.") {
        return response;
      } else {
        userRoleRelation.add(user);
        await userRole.save();
        return response;
      }
    }

    // window.location = "/auth/login";
  } catch (error) {
    // handle error
  }
}

export const Themes = () => {
  let query = new Parse.Query("Profile");
  query.equalTo("user", getCurrentUser());
  return query
    .first()
    .then((user) => {
      console.log(user);
      return user;
    })
    .catch((error) => {
      console.log(error);
    });
};
export const orgLogo = (file) => {
  let Enterprise = new Parse.Object.extend("Enterprise");
  let licensequery = new Parse.Query(Enterprise);
  return licensequery.find().then((results) => {
    for (var i = 0; i < results.length; i++) {
      licensequery.equalTo("objectId", results[i].id);
      return licensequery.first().then((data) => {
        // console.log(e.target.files[0]);
        let parseFile = new Parse.File(file.name, file);
        parseFile
          .save()
          .then((res) => {
            data.set("orglogo", res._url);
            data.save().then(() => {
              var newStore = new Parse.Object("File");
              newStore.set("File", parseFile);
              newStore
                .save()
                .then((res) => {
                  return "sucessfully changed";
                })
                .catch((error) => {
                  console.log(error, "failure");
                });
            });
          })
          .catch((error) => {
            console.log(error, "failure");
          });
      });
    }
  });
};
export const profilePic = (file) => {
  let curruser = Parse.User.current();
  console.log(file);
  let parseFile = new Parse.File(file.name, file);
  parseFile
    .save()
    .then((res) => {
      curruser.set("profile", res._url);
      curruser.save();
      console.log("changed");
    })
    .catch((error) => {
      console.log(error, "failure");
    });
  var newStore = new Parse.Object("File");
  newStore.set("File", parseFile);
  newStore.save().then((res) => {
    // console.log(res.id);
    // CurrentUser.set("profile", res);
    // CurrentUser.save();
    // console.log(CurrentUser.get("profile"));
  });
};
export const initialEnterprises = () => {
  let query = new Parse.Query("Enterprise");
  query.find().then((results) => {
    console.log(results);
    if (results.length === 0) {
      const Enterprise = Parse.Object.extend("Enterprise");
      const enterprise = new Enterprise();
      enterprise.set("image", true);
      enterprise.set("text", true);
      enterprise.set("structured", true);
      enterprise.set("regression", true);
      enterprise.set("timeseries", true);
      enterprise.set("audio", true);
      enterprise.set("od", true);
      enterprise.set("sematic", true);
      enterprise.set("recommendation", true);
      enterprise.set("address", "my demo address");
      enterprise.set("orgname", "testAing");
      enterprise.set("domain", "testaing.com");
      enterprise.save();
    } else {
      console.log("nothing");
    }
  });
};
// export const initialEnterprises = async () => {
//   const query = new Parse.Query("Enterprise");
//   const results = await query.find();

//   console.log(results);

//   if (results.length === 0) {
//     const Enterprise = Parse.Object.extend("Enterprise");
//     const enterprise = new Enterprise();

//     enterprise.set({
//       image: true,
//       text: true,
//       structured: true,
//       regression: true,
//       timeseries: true,
//       audio: true,
//       od: true,
//       sematic: true,
//       recommendation: true,
//       address: "my demo address",
//       orgname: "testAing",
//       domain: "testaing.com",
//     });

//     await enterprise.save();
//   } else {
//     console.log("Nothing to do");
//   }
// };
export const License = () => {
  let newEnterprise = new Parse.Object("Enterprise");
  let Enterprise = new Parse.Object.extend(newEnterprise);
  let licensequery = new Parse.Query(Enterprise);
  return licensequery
    .find()
    .then((results) => {
      for (var i = 0; i < results.length; i++) {
        licensequery.equalTo("objectId", results[i].id);
        return licensequery
          .first()
          .then((data) => {
            return data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // results.forEach((element) => {
      //   // console.log(element.get("text"));
      //   licensequery.equalTo("objectId", element.id);
      //   return licensequery
      //     .first()
      //     .then((data) => {
      //       console.log(data.get("text"));
      //       return data;
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // });
    })
    .catch((error) => {
      console.log(error);
    });
};
export const addnewadmin = (addadmin) => {
  var query = new Parse.Query(Parse.User);
  query.equalTo("username", addadmin);
  return query.first().then(function (user) {
    var newquery = new Parse.Query(Parse.Role);
    newquery.equalTo("name", "Admin");
    return newquery.first().then(function (role) {
      let relation = role.relation("users");
      try {
        relation.add(user);
        role.save();
        return "User Added Sucessfully";
      } catch (error) {
        if (
          error.message === "Cannot read properties of undefined (reading 'id')"
        ) {
          return "User's Email not Found";
        }
      }
    });
  });
};

export const removeadmin = (addadmin) => {
  var query = new Parse.Query(Parse.User);
  query.equalTo("username", addadmin);
  return query.first().then(function (user) {
    var newquery = new Parse.Query(Parse.Role);
    newquery.equalTo("name", "Admin");
    return newquery.first().then(function (role) {
      let relation = role.relation("users");
      try {
        relation.remove(user);
        role.save();
        return "User Removed Sucessfully";
      } catch (error) {
        if (
          error.message === "Cannot read properties of undefined (reading 'id')"
        ) {
          return "User's Email not Found";
        }
      }
    });
  });
};
export function checkIfSuperAdmin() {
  var query = new Parse.Query(Parse.Role);
  query.equalTo("name", "Administrator");
  return query
    .first()
    .then(function (role) {
      var relation = role.getUsers();
      return relation
        .query()
        .find()
        .then(function (users) {
          return users;
        });
    })
    .catch((err) => {
      console.log(err);
    });
}

export function checkIfAdmin() {
  var query = new Parse.Query(Parse.Role);
  query.equalTo("name", "Admin");

  return query
    .first()
    .then(function (role) {
      var relation = role.getUsers();
      return relation
        .query()
        .find()
        .then(function (users) {
          return users;
        });
    })
    .catch((err) => {
      console.log(err);
    });
}

export function setAdmin() {}

export function fetchAdminList() {
  var query = new Parse.Query(Parse.Role);
  query.equalTo("name", "Admin");
  query.first().then(function (role) {
    var relation = role.getUsers();
    relation
      .query()
      .find()
      .then(function (results) {
        var adminlist = [];
        for (var i = 0; i < results.length; i++) {
          adminlist.push(results[i].get("username"));
        }
        return adminlist.sort();
      })
      .catch((err) => {
        return err;
      });
  });
}
