import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { auth } from "services";

const UserDetails = (props) => {
  const {
    setFullname,
    fullname,
    setUsername,
    username,
    setContact,
    contact,
    textdisabled,
    CurrentUserMail,
    nameError,
    contactError,
  } = props;

  const reset = () => {
    auth.resetPassword(username);
  };

  return (
    <div>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="labels">Username</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              placeholder="Username"
              onChange={(event) => setUsername(event.target.value)}
              value={textdisabled ? username : null}
              disabled={textdisabled}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="labels">Email</Form.Label>
            <Form.Control
              type="email"
              className="form-control"
              value={CurrentUserMail}
              placeholder="jesse@example.com"
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="labels">Full Name</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              placeholder="eg: Harry Potter"
              onChange={(event) => setFullname(event.target.value)}
              value={fullname}
              disabled={textdisabled}
            />
            <Form.Text className="text-danger" >
              {!textdisabled && nameError} 
            </Form.Text>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="labels">Contact Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="eg: +91-1234567890"
              pattern="[+][0-9]{1,3}-[0-9]{10}"
              value={contact}
              onChange={(event) => setContact(event.target.value)}
              disabled={textdisabled}
            />
            <Form.Text className="text-danger" >
              {!textdisabled && contactError}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="labels">Password</Form.Label>
            <Form.Control type="password" value="demo123" disabled />
          </Form.Group>
        </Col>
      </Row>
      <Button
        onClick={() => {
          reset();
        }}
        className="myButton"
      >
        Reset
      </Button>
    </div>
  );
};

export default UserDetails;
