import React from 'react';
import { Row, Col, Accordion, Card, Button, Form } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { textApi } from 'services';

class MainConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Presets
      conf1: {},
      conf2: {},

      //Main Config
      config_main: {},
      loading_data_diagnostic_set_execution: 0,
      model_stage_set_execution: 0,
      model_explainability_set_execution: 0,
      DeepXplore_set_execution: 0,
      Performance_Testing_set_execution: 0,
      Json_dump_set_execution: 0,
      move_previous_results_set_execution: 0,
    }
  }

  componentDidMount() {
    textApi.fetchTextMainConfig().then((data) => {
      console.log(data);
      this.setState({ config_main: data._sections });
      const { config_main } = this.state;
      this.setState({ loading_data_diagnostic_set_execution: config_main.loading_data_diagnostic.set_execution === 'True' ? true : false })
      this.setState({ model_stage_set_execution: config_main.model_stage.set_execution === 'True' ? true : false })
      this.setState({ model_explainability_set_execution: config_main.model_explainability.set_execution === 'True' ? true : false })
      this.setState({ DeepXplore_set_execution: config_main.DeepXplore.set_execution === 'True' ? true : false })
      this.setState({ Performance_Testing_set_execution: config_main.Performance_Testing.set_execution === 'True' ? true : false })
      this.setState({ Json_dump_set_execution: config_main.Json_dump.set_execution === 'True' ? true : false })
      this.setState({ move_previous_results_set_execution: config_main.move_previous_results.set_execution === 'True' ? true : false })
    }).catch((error) => console.log(error));
  }

  handleClick = () => {
    //Main Config
    const { config_main, loading_data_diagnostic_set_execution, model_stage_set_execution, model_explainability_set_execution, DeepXplore_set_execution, performance_testing_set_execution, Json_dump_set_execution, move_previous_results_set_execution } = this.state

    config_main.loading_data_diagnostic.set_execution = loading_data_diagnostic_set_execution === true ? 'True' : 'False'
    config_main.model_stage.set_execution = model_stage_set_execution === true ? 'True' : 'False'
    config_main.model_explainability.set_execution = model_explainability_set_execution === true ? 'True' : 'False'
    config_main.DeepXplore.set_execution = DeepXplore_set_execution === true ? 'True' : 'False'
    config_main.Performance_Testing.set_execution = performance_testing_set_execution === true ? 'True' : 'False'
    config_main.Json_dump.set_execution = Json_dump_set_execution === true ? 'True' : 'False'
    config_main.move_previous_results.set_execution = move_previous_results_set_execution === true ? 'True' : 'False'

    textApi.postTextConfigMain(config_main).then(() => console.log("Main Config Request posted"));
  }
/*
  handlePreset1 = () => {
    fetch("http://localhost:3030/config/text/preset1")
      .then(response => response.json())
      .then(data => {
        this.setState({ conf1: data._sections });
        const { conf1 } = this.state
        console.log('Main Config');
        console.log(conf1);
        this.setState({ loading_data_diagnostic_set_execution: conf1.loading_data_diagnostic.set_execution === 'True' ? true : false })
        this.setState({ model_stage_set_execution: conf1.model_stage.set_execution === 'True' ? true : false })
        this.setState({ model_explainability_set_execution: conf1.model_explainability.set_execution === 'True' ? true : false })
        this.setState({ DeepXplore_set_execution: conf1.DeepXplore.set_execution === 'True' ? true : false })
        this.setState({ performance_testing_set_execution: conf1.Performance_Testing.set_execution === 'True' ? true : false })
        this.setState({ Json_dump_set_execution: conf1.Json_dump.set_execution === 'True' ? true : false })
        this.setState({ move_previous_results_set_execution: conf1.move_previous_results.set_execution === 'True' ? true : false })
      })
  }

  handlePreset2 = () => {
    fetch("http://localhost:3030/config/text/preset2")
      .then(response => response.json())
      .then(data => {
        this.setState({ conf2: data._sections });
        const { conf2 } = this.state;
        this.setState({ loading_data_diagnostic_set_execution: conf2.loading_data_diagnostic.set_execution === 'True' ? true : false })
        this.setState({ model_stage_set_execution: conf2.model_stage.set_execution === 'True' ? true : false })
        this.setState({ model_explainability_set_execution: conf2.model_explainability.set_execution === 'True' ? true : false })
        this.setState({ DeepXplore_set_execution: conf2.DeepXplore.set_execution === 'True' ? true : false })
        this.setState({ performance_testing_set_execution: conf2.Performance_Testing.set_execution === 'True' ? true : false })
        this.setState({ Json_dump_set_execution: conf2.Json_dump.set_execution === 'True' ? true : false })
        this.setState({ move_previous_results_set_execution: conf2.move_previous_results.set_execution === 'True' ? true : false })
      })
  }
*/
  render() {
    //Main Config
    const { loading_data_diagnostic_set_execution, model_stage_set_execution, model_explainability_set_execution, DeepXplore_set_execution, Json_dump_set_execution, move_previous_results_set_execution, performance_testing_set_execution } = this.state;

    return (
      <Accordion.Item eventKey="4">
        <Accordion.Header >
          Main Config
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col md={4}>
              <h5>[loading_data_diagnostic]</h5>
              <Form.Check inline label="set_execution" type={'switch'} id={'check34'} checked={loading_data_diagnostic_set_execution} onChange={changeEvent => this.setState({ loading_data_diagnostic_set_execution: changeEvent.target.checked })} />
              <br /><br />
            </Col>
            <Col md={4}>
              <h5>[model_stage]</h5>
              <Form.Check inline label="set_execution" type={'switch'} id={'check35'} checked={model_stage_set_execution} onChange={changeEvent => this.setState({ model_stage_set_execution: changeEvent.target.checked })} />
              <br /><br />
            </Col>
            <Col md={4}>
              <h5>[model_explainability]</h5>
              <Form.Check inline label="set_execution" type={'switch'} id={'check36'} checked={model_explainability_set_execution} onChange={changeEvent => this.setState({ model_explainability_set_execution: changeEvent.target.checked })} />
              <br /><br />
            </Col>
            <Col md={4}>
              <h5>[Performance_Testing]</h5>
              <Form.Check inline label="set_execution" type={'switch'} id={'check39'} checked={performance_testing_set_execution} onChange={changeEvent => this.setState({ performance_testing_set_execution: changeEvent.target.checked })} />
              <br /><br />
            </Col>
            <Col md={4}>
              <h5>[deepXplore]</h5>
              <Form.Check inline label="set_execution" type={'switch'} id={'check37'} checked={DeepXplore_set_execution} onChange={changeEvent => this.setState({ DeepXplore_set_execution: changeEvent.target.checked })} />
              <br /><br />
            </Col>
            <Col md={4}>
              <h5>[json_dump]</h5>
              <Form.Check inline label="set_execution" type={'switch'} id={'check38'} checked={Json_dump_set_execution} onChange={changeEvent => this.setState({ Json_dump_set_execution: changeEvent.target.checked })} />
              <br /><br />
            </Col>
            <Col md={4}>
              <h5>[move_previous_results]</h5>
              <Form.Check inline label="set_execution" type={'switch'} id={'check39'} checked={move_previous_results_set_execution} onChange={changeEvent => this.setState({ move_previous_results_set_execution: changeEvent.target.checked })} />
              <br /><br />
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    )
  }
}

export default MainConfig;
