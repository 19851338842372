import React from 'react';
import { Row, Col, Form, Accordion, Card, Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
// import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Config
      config: {},
      data_paths_data_path_folder: '',
      data: '',
      data_paths_test_x_df: '',
      data_paths_train_x_df: '',
      data_paths_test_y_df: '',
      data_paths_train_y_df: '',
      data_paths_test_x: '',
      data_paths_train_x: '',
      data_paths_test_y: '',
      data_paths_train_y: '',
      data_paths_test_preds: '',
      data_paths_data_top_10: '',
      data_paths_data_stats: '',
      model_paths_model_path_folder: '',
      model_paths_model: '',
      Local_visualization_ice: '',
      Local_visualization_loco: '',
      Local_visualization_shap: '',
      Local_visualization_scoped_anchor: '',
      Local_visualization_lime: '',
      Global_visualization_pdp: '',
      Global_visualization_global_visualize: '',
      Global_visualization_decision_tree_surrogates_modelling: '',
      Global_visualization_shap_global_graphics: '',
      Global_visualization_permutation: '',
      Global_visualization_gis: '',
      fairml_visualization_fairml: '',
    }
  }

  componentDidMount() {
    //Config
    fetch("http://localhost:3030" + "/config/regression/config")
      .then(response => response.json())
      .then(data => {
        this.setState({ config: data._sections });
        const { config } = this.state
        console.log('Config');
        console.log(config);
        this.setState({ data_paths_data_path_folder: config.data_paths.data_path_folder })
        this.setState({ data_paths_data: config.data_paths.data })
        this.setState({ data_paths_test_x_df: config.data_paths.test_x_df })
        this.setState({ data_paths_train_x_df: config.data_paths.train_x_df })
        this.setState({ data_paths_test_y_df: config.data_paths.test_y_df })
        this.setState({ data_paths_train_y_df: config.data_paths.train_y_df })
        this.setState({ data_paths_test_x: config.data_paths.test_x })
        this.setState({ data_paths_train_x: config.data_paths.train_x })
        this.setState({ data_paths_test_y: config.data_paths.test_y })
        this.setState({ data_paths_train_y: config.data_paths.train_y })
        this.setState({ data_paths_test_preds: config.data_paths.test_preds })
        this.setState({ data_paths_data_top_10: config.data_paths.data_top_10 })
        this.setState({ data_paths_data_stats: config.data_paths.data_stats })
        this.setState({ model_paths_model_path_folder: config.model_paths.model_path_folder })
        this.setState({ model_paths_model: config.model_paths.model })
        this.setState({ Local_visualization_ice: config.Local_visualization.ice })
        this.setState({ Local_visualization_loco: config.Local_visualization.loco })
        this.setState({ Local_visualization_shap: config.Local_visualization.shap })
        this.setState({ Local_visualization_scoped_anchor: config.Local_visualization.scoped_anchor })
        this.setState({ Local_visualization_lime: config.Local_visualization.lime })
        this.setState({ Global_visualization_pdp: config.Global_visualization.pdp })
        this.setState({ Global_visualization_global_visualize: config.Global_visualization.global_visualize })
        this.setState({ Global_visualization_decision_tree_surrogates_modelling: config.Global_visualization.decision_tree_surrogates_modelling })
        this.setState({ Global_visualization_shap_global_graphics: config.Global_visualization.shap_global_graphics })
        this.setState({ Global_visualization_gis: config.Global_visualization.gis })
        this.setState({ fairml_visualization_fairml: config.fairml_visualization.fairml })
      })
  }

  handleClick = () => {
    //Config
    const { config, data_paths_data_path_folder, data_paths_data, data_paths_test_x_df, data_paths_train_x_df, data_paths_test_y_df, data_paths_train_y_df, data_paths_test_x, data_paths_train_x, data_paths_test_y, data_paths_train_y, data_paths_test_preds, data_paths_data_top_10, data_paths_data_stats, model_paths_model_path_folder, model_paths_model, Local_visualization_ice, Local_visualization_loco, Local_visualization_shap, Local_visualization_scoped_anchor, Local_visualization_lime, Global_visualization_pdp, Global_visualization_global_visualize, Global_visualization_decision_tree_surrogates_modelling, Global_visualization_shap_global_graphics, Global_visualization_gis, fairml_visualization_fairml } = this.state

    config.data_paths.data_path_folder = data_paths_data_path_folder
    config.data_paths.data = data_paths_data
    config.data_paths.test_x_df = data_paths_test_x_df
    config.data_paths.train_x_df = data_paths_train_x_df
    config.data_paths.test_y_df = data_paths_test_y_df
    config.data_paths.train_y_df = data_paths_train_y_df
    config.data_paths.test_x = data_paths_test_x
    config.data_paths.train_x = data_paths_train_x
    config.data_paths.test_y = data_paths_test_y
    config.data_paths.train_y = data_paths_train_y
    config.data_paths.test_preds = data_paths_test_preds
    config.data_paths.data_top_10 = data_paths_data_top_10
    config.data_paths.data_stats = data_paths_data_stats
    config.model_paths.model_path_folder = model_paths_model_path_folder
    config.model_paths.model = model_paths_model
    config.Local_visualization.ice = Local_visualization_ice
    config.Local_visualization.loco = Local_visualization_loco
    config.Local_visualization.shap = Local_visualization_shap
    config.Local_visualization.scoped_anchor = Local_visualization_scoped_anchor
    config.Local_visualization.lime = Local_visualization_lime
    config.Global_visualization.pdp = Global_visualization_pdp
    config.Global_visualization.global_visualize = Global_visualization_global_visualize
    config.Global_visualization.decision_tree_surrogates_modelling = Global_visualization_decision_tree_surrogates_modelling
    config.Global_visualization.shap_global_graphics = Global_visualization_shap_global_graphics
    config.Global_visualization.gis = Global_visualization_gis
    config.fairml_visualization.fairml = fairml_visualization_fairml

    fetch("http://localhost:3030" + '/config/regression/config', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config)
    }).then(() => {
      console.log('Config Request posted')
    })
  }

  render() {
    //Config
    const { data_paths_data_path_folder, data_paths_data, data_paths_test_x_df, data_paths_train_x_df, data_paths_test_y_df, data_paths_train_y_df, data_paths_test_x, data_paths_train_x, data_paths_test_y, data_paths_train_y, data_paths_test_preds, data_paths_data_top_10, data_paths_data_stats, model_paths_model_path_folder, model_paths_model, Local_visualization_ice, Local_visualization_loco, Local_visualization_shap, Local_visualization_scoped_anchor, Local_visualization_lime, Global_visualization_pdp, Global_visualization_global_visualize, Global_visualization_decision_tree_surrogates_modelling, Global_visualization_shap_global_graphics, Global_visualization_gis, fairml_visualization_fairml } = this.state

    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="5" block>
            <Row>
              <Col md={8} align="left">
                Config
              </Col>
              <Col md={4} align="right">
                {this.props.isActive ? <FontAwesomeIcon icon={faArrowCircleUp} /> : <FontAwesomeIcon icon={faArrowCircleDown} />}
              </Col>
            </Row>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="5">
          <Card.Body>
            <h5>[data_paths]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>data_path_folder</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_data_path_folder} onChange={e => this.setState({ data_paths_data_path_folder: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>data</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_data} onChange={e => this.setState({ data_paths_data: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>test_x_df</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_test_x_df} onChange={e => this.setState({ data_paths_test_x_df: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>train_x_df</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_train_x_df} onChange={e => this.setState({ data_paths_train_x_df: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>test_y_df</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_test_y_df} onChange={e => this.setState({ data_paths_test_y_df: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>train_y_df</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_train_y_df} onChange={e => this.setState({ data_paths_train_y_df: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>test_x</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_test_x} onChange={e => this.setState({ data_paths_test_x: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>train_x</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_train_x} onChange={e => this.setState({ data_paths_train_x: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>test_y</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_test_y} onChange={e => this.setState({ data_paths_test_y: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>train_y</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_train_y} onChange={e => this.setState({ data_paths_train_y: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>test_preds</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_test_preds} onChange={e => this.setState({ data_paths_test_preds: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>data_top_10</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_data_top_10} onChange={e => this.setState({ data_paths_data_top_10: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>data_stats</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_data_stats} onChange={e => this.setState({ data_paths_data_stats: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[model_paths]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>model_path_folder</Form.Label>
                <Form.Control size='sm' defaultValue={model_paths_model_path_folder} onChange={e => this.setState({ model_paths_model_path_folder: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>model</Form.Label>
                <Form.Control size='sm' defaultValue={model_paths_model} onChange={e => this.setState({ model_paths_model: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[Local_visualization]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>ice</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_ice} onChange={e => this.setState({ Local_visualization_ice: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>loco</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_loco} onChange={e => this.setState({ Local_visualization_loco: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>shap</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_shap} onChange={e => this.setState({ Local_visualization_shap: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>scoped_anchor</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_scoped_anchor} onChange={e => this.setState({ Local_visualization_scoped_anchor: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>lime</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_lime} onChange={e => this.setState({ Local_visualization_lime: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[Global_visualization]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>pdp</Form.Label>
                <Form.Control size='sm' defaultValue={Global_visualization_pdp} onChange={e => this.setState({ Global_visualization_pdp: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>global_visualize</Form.Label>
                <Form.Control size='sm' defaultValue={Global_visualization_global_visualize} onChange={e => this.setState({ Global_visualization_global_visualize: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>decision_tree_surrogates_modelling</Form.Label>
                <Form.Control size='sm' defaultValue={Global_visualization_decision_tree_surrogates_modelling} onChange={e => this.setState({ Global_visualization_decision_tree_surrogates_modelling: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>shap_global_graphics</Form.Label>
                <Form.Control size='sm' defaultValue={Global_visualization_shap_global_graphics} onChange={e => this.setState({ Global_visualization_shap_global_graphics: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>gis</Form.Label>
                <Form.Control size='sm' defaultValue={Global_visualization_gis} onChange={e => this.setState({ Global_visualization_gis: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[fairml_visualization]</h5>
            <Col md={4}>
              <Form.Label>fairml</Form.Label>
              <Form.Control size='sm' defaultValue={fairml_visualization_fairml} onChange={e => this.setState({ fairml_visualization_fairml: e.target.value })} />
            </Col>
            <br />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }
}

export default Index;
