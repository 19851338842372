import React, { useState } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Header, Footer } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import FormImage from "./FormImage";
import FormText from "./FormText";
import FormStructured from "./FormStructured";
import FormRegression from "./FormRegression";
import FormAudio from "./FormAudio";
import FormTimeseries from "./FormTimeseries";

function Index(props) {
  const [choice, setChoice] = useState(0);

  return (
    <Container>
      <Header title="Customize Report" />
      <Row className="my-4">
        <Col md={8} className="selectOption">
          <Form.Select
            value={choice}
            onChange={(e) => setChoice(e.target.value)}
          >
            <option>Image Pipeline</option>
            <option>Text Pipeline</option>
            <option>Structured Pipeline</option>
            <option>Regression Pipeline</option>
            <option>Audio Pipeline</option>
            <option>Timeseries Pipeline</option>
          </Form.Select>
        </Col>
      </Row>
      <Row>
        {choice === 0 && <FormImage />}
        {/* {choice === 1 && <FormText />}
        {choice === 2 && <FormStructured />}
        {choice === 3 && <FormRegression />}
        {choice === 4 && <FormAudio />}
        {choice === 5 && <FormTimeseries />} */}
      </Row>
      <Footer />
    </Container>
  );
}

export default Index;
