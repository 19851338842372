import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";

import Parse from "services/parseService";

const Index = forwardRef((props, ref) => {
  const [conf1, setConf1] = useState({});
  const [conf2, setConf2] = useState({});
  const [configMain, setConfigMain] = useState({});
  const [loadingDataPipelineSetExecution, setLoadingDataPipelineSetExecution] =
    useState(null);
  const [modelingPipelineSetExecution, setModelingPipelineSetExecution] =
    useState(null);
  const [modelPrivacySetExecution, setModelPrivacySetExecution] = useState(null);
  const [
    saliencyVisualisationPipelineSetExecution,
    setSaliencyVisualisationPipelineSetExecution,
  ] = useState(null);
  const [artImplementationSetExecution, setArtImplementationSetExecution] =
    useState(null);
  const [
    metamorphicTestingPipelineSetExecution,
    setMetamorphicTestingPipelineSetExecution,
  ] = useState(null);
  const [
    metamorphicMisclassifiedVisualisationSetExecution,
    setMetamorphicMisclassifiedVisualisationSetExecution,
  ] = useState(null);
  const [
    deepxploreImplementationSetExecution,
    setDeepxploreImplementationSetExecution,
  ] = useState(null);
  const [
    modelTuningImplementationSetExecution,
    setModelTuningImplementationSetExecution,
  ] = useState(null);
  const [
    allBlackBoxExecutionOnlySetExecution,
    setAllBlackBoxExecutionOnlySetExecution,
  ] = useState(null);
  const [
    removePreviousResultsSetExecution,
    setRemovePreviousResultsSetExecution,
  ] = useState(null);
  const [modelQuantization, setModelQuantization] = useState(null);
  const [performanceTesting, setPerformanceTesting] = useState(null);

  useEffect(() => {
    fetch("http://localhost:3030/config/image/main")
      .then((response) => response.json())
      .then((data) => {
        const responseData = data._sections;
        setConfigMain(responseData);
        setLoadingDataPipelineSetExecution(
          responseData.loading_data_pipeline.set_execution === "True"
            ? true
            : false
        );
        setModelingPipelineSetExecution(
          responseData.modeling_pipeline.set_execution === "True" ? true : false
        );
        setModelPrivacySetExecution(
          responseData.model_privacy.set_execution === "True" ? true : false
        );
        setSaliencyVisualisationPipelineSetExecution(
          responseData.saliency_visualisation_pipeline.set_execution === "True"
            ? true
            : false
        );
        setArtImplementationSetExecution(
          responseData.art_implementation.set_execution === "True"
            ? true
            : false
        );
        setMetamorphicTestingPipelineSetExecution(
          responseData.metamorphic_testing_pipeline.set_execution === "True"
            ? true
            : false
        );
        setMetamorphicMisclassifiedVisualisationSetExecution(
          responseData.metamorphic_misclassified_visualisation.set_execution ===
            "True"
            ? true
            : false
        );
        setDeepxploreImplementationSetExecution(
          responseData.deepxplore_implementation.set_execution === "True"
            ? true
            : false
        );
        setModelTuningImplementationSetExecution(
          responseData.model_tuning_implementation.set_execution === "True"
            ? true
            : false
        );
        setAllBlackBoxExecutionOnlySetExecution(
          responseData.all_black_box_execution_only.set_execution === "True"
            ? true
            : false
        );
        setRemovePreviousResultsSetExecution(
          responseData.remove_previous_results.set_execution === "True"
            ? true
            : false
        );
        setModelQuantization(
          responseData.model_quantization.set_execution === "True"
            ? true
            : false
        );
        setPerformanceTesting(
          responseData.performance_testing.set_execution === "True"
            ? true
            : false
        );
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, []);
  useImperativeHandle(ref, () => ({
    handleClick() {
      handleClick();
    },
    handlePreset1() {
      handlePreset1();
    },
    handlePreset2() {
      handlePreset2();
    },
  }));
  const handleClick = async () => {
    let tempVarialbe = { ...configMain };

    tempVarialbe.loading_data_pipeline.set_execution =
      loadingDataPipelineSetExecution === true ? "True" : "False";

    tempVarialbe.modeling_pipeline.set_execution =
      modelingPipelineSetExecution === true ? "True" : "False";

    tempVarialbe.model_privacy.set_execution =
      modelPrivacySetExecution === true ? "True" : "False";

    tempVarialbe.saliency_visualisation_pipeline.set_execution =
      saliencyVisualisationPipelineSetExecution === true ? "True" : "False";

    tempVarialbe.art_implementation.set_execution =
      artImplementationSetExecution === true ? "True" : "False";

    tempVarialbe.metamorphic_testing_pipeline.set_execution =
      metamorphicTestingPipelineSetExecution === true ? "True" : "False";

    tempVarialbe.metamorphic_misclassified_visualisation.set_execution =
      metamorphicMisclassifiedVisualisationSetExecution === true
        ? "True"
        : "False";

    tempVarialbe.deepxplore_implementation.set_execution =
      deepxploreImplementationSetExecution === true ? "True" : "False";

    tempVarialbe.model_tuning_implementation.set_execution =
      modelTuningImplementationSetExecution === true ? "True" : "False";

    tempVarialbe.all_black_box_execution_only.set_execution =
      allBlackBoxExecutionOnlySetExecution === true ? "True" : "False";

    tempVarialbe.remove_previous_results.set_execution =
      removePreviousResultsSetExecution === true ? "True" : "False";

    tempVarialbe.remove_previous_results.set_execution =
      removePreviousResultsSetExecution === true ? "True" : "False";
    tempVarialbe.remove_previous_results.set_execution =
      removePreviousResultsSetExecution === true ? "True" : "False";
    tempVarialbe.model_quantization.set_execution =
      modelQuantization === true ? "True" : "False";
    tempVarialbe.performance_testing.set_execution =
      performanceTesting === true ? "True" : "False";
    setConfigMain(tempVarialbe);
    try {
      let response = await fetch(
        "http://localhost:3030" + "/config/image/main",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(configMain),
        }
      );

      console.log(response);
    } catch {
      console.log("Error");
    }
    let params = {
      command: "Run",
    };
    Parse.Cloud.run("execute", params).then((res) => {
      console.log(res);
    });
  };

  const handlePreset1 = () => {
    fetch("http://localhost:3030" + "/config/image/preset1")
      .then((response) => response.json())
      .then((data) => {
        const responseData = data._sections;

        setConf1(responseData);
        setLoadingDataPipelineSetExecution(
          responseData.loading_data_pipeline.set_execution === "True"
            ? true
            : false
        );
        setModelingPipelineSetExecution(
          responseData.modeling_pipeline.set_execution === "True" ? true : false
        );
        setModelPrivacySetExecution(
          responseData.model_privacy.set_execution === "True" ? true : false
        );
        setSaliencyVisualisationPipelineSetExecution(
          responseData.saliency_visualisation_pipeline.set_execution === "True"
            ? true
            : false
        );
        setArtImplementationSetExecution(
          responseData.art_implementation.set_execution === "True"
            ? true
            : false
        );
        setMetamorphicTestingPipelineSetExecution(
          responseData.metamorphic_testing_pipeline.set_execution === "True"
            ? true
            : false
        );
        setMetamorphicMisclassifiedVisualisationSetExecution(
          responseData.metamorphic_misclassified_visualisation.set_execution ===
            "True"
            ? true
            : false
        );
        setDeepxploreImplementationSetExecution(
          responseData.deepxplore_implementation.set_execution === "True"
            ? true
            : false
        );
        setModelTuningImplementationSetExecution(
          responseData.model_tuning_implementation.set_execution === "True"
            ? true
            : false
        );
        setAllBlackBoxExecutionOnlySetExecution(
          responseData.all_black_box_execution_only.set_execution === "True"
            ? true
            : false
        );
        setRemovePreviousResultsSetExecution(
          responseData.remove_previous_results.set_execution === "True"
            ? true
            : false
        );
      });
  };
  const handlePreset2 = () => {
    fetch("http://localhost:3030" + "/config/image/preset2")
      .then((response) => response.json())
      .then((data) => {
        const responseData = data._sections;

        setConf2(responseData);
        setLoadingDataPipelineSetExecution(
          responseData.loading_data_pipeline.set_execution === "True"
            ? true
            : false
        );
        setModelingPipelineSetExecution(
          responseData.modeling_pipeline.set_execution === "True" ? true : false
        );
        setModelPrivacySetExecution(
          responseData.model_privacy.set_execution === "True" ? true : false
        );
        setSaliencyVisualisationPipelineSetExecution(
          responseData.saliency_visualisation_pipeline.set_execution === "True"
            ? true
            : false
        );
        setArtImplementationSetExecution(
          responseData.art_implementation.set_execution === "True"
            ? true
            : false
        );
        setMetamorphicTestingPipelineSetExecution(
          responseData.metamorphic_testing_pipeline.set_execution === "True"
            ? true
            : false
        );
        setMetamorphicMisclassifiedVisualisationSetExecution(
          responseData.metamorphic_misclassified_visualisation.set_execution ===
            "True"
            ? true
            : false
        );
        setDeepxploreImplementationSetExecution(
          responseData.deepxplore_implementation.set_execution === "True"
            ? true
            : false
        );
        setModelTuningImplementationSetExecution(
          responseData.model_tuning_implementation.set_execution === "True"
            ? true
            : false
        );
        setAllBlackBoxExecutionOnlySetExecution(
          responseData.all_black_box_execution_only.set_execution === "True"
            ? true
            : false
        );
        setRemovePreviousResultsSetExecution(
          responseData.remove_previous_results.set_execution === "True"
            ? true
            : false
        );
      });
  };
  return (
    <Row>
      <Col md={4}>
        <h5>[loading_data_pipeline]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check42"}
          checked={loadingDataPipelineSetExecution}
          onChange={(e) => setLoadingDataPipelineSetExecution(e.target.checked)}
        />
        <br />
        <br />
      </Col>
      <Col md={4}>
        <h5>[modeling_pipeline]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check43"}
          checked={modelingPipelineSetExecution}
          onChange={(e) => setModelingPipelineSetExecution(e.target.checked)}
        />
        <br />
        <br />
      </Col>
      <Col md={4}>
        <h5>[model_privacy]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check44"}
          checked={modelPrivacySetExecution}
          onChange={(e) => setModelPrivacySetExecution(e.target.checked)}
        />
        <br />
        <br />
      </Col>
      <Col md={4}>
        <h5>[saliency_visualisation_pipeline]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check45"}
          checked={saliencyVisualisationPipelineSetExecution}
          onChange={(e) =>
            setSaliencyVisualisationPipelineSetExecution(e.target.checked)
          }
        />
        <br />
        <br />
      </Col>
      <Col md={4}>
        <h5>[art_implementation]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check46"}
          checked={artImplementationSetExecution}
          onChange={(e) => setArtImplementationSetExecution(e.target.checked)}
        />
        <br />
        <br />
      </Col>
      <Col md={4}>
        <h5>[metamorphic_testing_pipeline]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check47"}
          checked={metamorphicTestingPipelineSetExecution}
          onChange={(e) =>
            setMetamorphicTestingPipelineSetExecution(e.target.checked)
          }
        />
        <br />
        <br />
      </Col>
      <Col md={4}>
        <h5>[metamorphic_misclassified_visualisation]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check48"}
          checked={metamorphicMisclassifiedVisualisationSetExecution}
          onChange={(e) =>
            setMetamorphicMisclassifiedVisualisationSetExecution(
              e.target.checked
            )
          }
        />
        <br />
        <br />
      </Col>
      <Col md={4}>
        <h5>[deepxplore_implementation]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check49"}
          checked={deepxploreImplementationSetExecution}
          onChange={(e) =>
            setDeepxploreImplementationSetExecution(e.target.checked)
          }
        />
        <br />
        <br />
      </Col>
      <Col md={4}>
        <h5>[model_tuning_implementation]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check50"}
          checked={modelTuningImplementationSetExecution}
          onChange={(e) =>
            setModelTuningImplementationSetExecution(e.target.checked)
          }
        />
        <br />
        <br />
      </Col>
      <Col md={4}>
        <h5>[all_black_box_execution_only]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check51"}
          checked={allBlackBoxExecutionOnlySetExecution}
          onChange={(e) =>
            setAllBlackBoxExecutionOnlySetExecution(e.target.checked)
          }
        />
        <br />
        <br />
      </Col>
      <Col md={4}>
        <h5>[remove_previous_results]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check52"}
          checked={removePreviousResultsSetExecution}
          onChange={(e) =>
            setRemovePreviousResultsSetExecution(e.target.checked)
          }
        />
        <br />
        <br />
      </Col>
      <Col md={4}>
        <h5>[model_quantization]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check52"}
          checked={modelQuantization}
          onChange={(e) => setModelQuantization(e.target.checked)}
        />
        <br />
        <br />
      </Col>{" "}
      <Col md={4}>
        <h5>[performance_testing]</h5>
        <Form.Check
          inline
          label="set_execution"
          type={"switch"}
          id={"check52"}
          checked={performanceTesting}
          onChange={(e) => setPerformanceTesting(e.target.checked)}
        />
        <br />
        <br />
      </Col>
    </Row>
  );
});

export default Index;
