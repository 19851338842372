import React, { useState, useReducer, useEffect } from "react";

import { validate } from "./validator";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

const FormEle = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.value || "",
    isValid: props.valid || false,
    isTouched: false,
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators,
    });
  };

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  const inputType = showPassword ? "text" : "password";

  if (props.element === "checkbox") {
    return (
      <label htmlFor={props.id}>
        <input id={props.id} type={props.type} className="auth__checkbox" />
        <span className="auth__checkmark"></span>
        {props.label}
      </label>
    );
  }

  return (
    <div
      className={`form-ele__container ${
        !inputState.isValid && inputState.isTouched && "invalid-input"
      }`}
    >
      {props.type === "password" && (
        <span
          className="form-ele__password__show"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? "Hide" : "Show"}
        </span>
      )}
      <input
        id={props.id}
        type={props.type === "password" ? inputType : props.type}
        placeholder={props.placeholder || props.label || ""}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        autoComplete="off"
        title={props.placeholder || props.label || ""}
      />
      {!inputState.isValid && inputState.isTouched && (
        <div className="form-ele__error">
          {props.errorText && <p>{props.errorText}</p>}
        </div>
      )}
    </div>
  );
};

export default FormEle;
