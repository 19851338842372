import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Row, Col, Form, Accordion, Card, Button } from "react-bootstrap";
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
// import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";
const Index = forwardRef((props, ref) => {
  const [configExplain, setConfigExplain] = useState({});
  const [argumentLayerName, setArgumentLayerName] = useState("");
  const [
    argumentClassWiseWhiteBoxExecution,
    setArgumentClassWiseWhiteBoxExecution,
  ] = useState(false);
  const [
    argumentClassWiseBlackBoxExecution,
    setArgumentClassWiseBlackBoxExecution,
  ] = useState(false);

  useEffect(() => {
    fetch("http://localhost:3030" + "/config/image/explain")
      .then((response) => response.json())
      .then((data) => {
        setConfigExplain(data._sections);
        setArgumentLayerName(data._sections.argument.layer_name);
        setArgumentClassWiseWhiteBoxExecution(
          data._sections.argument.class_wise_white_box_execution === "True"
            ? true
            : false
        );
        setArgumentClassWiseBlackBoxExecution(
          data._sections.argument.class_wise_black_box_execution === "True"
            ? true
            : false
        );
      });
  }, []);

  useImperativeHandle(ref, () => ({
    handleClick() {
      handleClick();
    },
  }));
  const handleClick = () => {
    //Model Explainability
    setConfigExplain((prev) => {
      let tempVariable = { ...prev };
      tempVariable.argument.layer_name = argumentLayerName;
      tempVariable.argument.class_wise_white_box_execution =
        argumentClassWiseWhiteBoxExecution === true ? "True" : "False";
      tempVariable.argument.class_wise_black_box_execution =
        argumentClassWiseBlackBoxExecution === true ? "True" : "False";
      return tempVariable;
    });
    fetch("http://localhost:3030" + "/config/image/explain", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(configExplain),
    }).then(() => {
      console.log("Model Explainability Request posted");
    });
  };
  return (
    <div>
      <h5>[argument]</h5>
      <Row>
        <Col md={4}>
          <Form.Label>layer_name</Form.Label>
          <Form.Control
            size="sm"
            defaultValue={argumentLayerName}
            onChange={(e) => setArgumentLayerName(e.target.value)}
          />
        </Col>
        <Col md={4}>
          <Form.Check
            inline
            label="class_wise_white_box_execution"
            type={"switch"}
            id={"check20"}
            checked={argumentClassWiseWhiteBoxExecution}
            onChange={(e) =>
              setArgumentClassWiseWhiteBoxExecution(e.target.checked)
            }
          />
        </Col>
        <Col md={4}>
          <Form.Check
            inline
            label="class_wise_black_box_execution"
            type={"switch"}
            id={"check21"}
            checked={argumentClassWiseBlackBoxExecution}
            onChange={(e) =>
              setArgumentClassWiseBlackBoxExecution(e.target.checked)
            }
          />
        </Col>
      </Row>
      <br />
    </div>
  );
});

export default Index;
