import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Card } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import { Header, Footer } from "components";
import LicenseProfile from "./LicenseProfile";
import UserDetails from "./UserDetails";
import EnterpriseProfile from "./EnterpriseProfile";
import ThemeDetails from "./ThemeDetails";
import AccessControl from "./AccessControl";
import "App.scss";
import { auth, profile } from "services";

var CurrentUser = auth.getCurrentUser();
var CurrentUserMail = CurrentUser ? CurrentUser.get("email") : null;
const NameRegex = /^(?=.{1,30}$)[a-zA-Z]+(?:\s[a-zA-Z]+)?$/;
const ContactRegex = /^\+\d{1,3}-\d{10}$/;

const Adminprofile = ({ admin }) => {
  const [textdisabled, setTextDisabled] = useState(true);
  const [username, setUsername] = useState(CurrentUser.get("username"));
  const [profilepic, setProfilepic] = useState(CurrentUser.get("profile"));
  const [fullname, setFullname] = useState(CurrentUser.get("fullname"));
  const [contact, setContact] = useState(CurrentUser.get("contact"));
  const [about, setAbout] = useState(CurrentUser.get("about"));
  const [Themecolor, setThemecolor] = useState(CurrentUser.get("Themecolor"));
  const [Textcolor, setTextcolor] = useState(CurrentUser.get("Textcolor"));
  const [BGcolor, setBGolor] = useState(CurrentUser.get("BGcolor"));
  const [nameError, setNameError] = useState("");
  const [contactError, setContactError] = useState("");
  const [Admin, setAdmin] = useState([]);
  const extraSpaceRemoved = (value) => {
    return value.replace(/\s+/g, " ").trim();
  };
  let adminuser = admin.toString();
  const update = () => {
    if(nameError === "") {
      CurrentUser.set("fullname", fullname);
    }
    else {
      setFullname(CurrentUser.get("fullname"));
    }
    if(contactError === "") {
      CurrentUser.set("contact", contact);
    }
    else {
      setContact(CurrentUser.get("contact"));
    }
    CurrentUser.set("about", about);
    CurrentUser.set("username", username);
    CurrentUser.save();
  };
  useEffect(() => {
    CurrentUser.set("Textcolor", Textcolor);
    CurrentUser.save();
    document.documentElement.style.setProperty(
      "--text",
      CurrentUser.get("Textcolor")
    );
  }, [Textcolor]);
  useEffect(() => {
    CurrentUser.set("Themecolor", Themecolor);
    CurrentUser.save();
    document.documentElement.style.setProperty(
      "--content",
      CurrentUser.get("Themecolor")
    );
  }, [Themecolor]);

  useEffect(() => {
    CurrentUser.set("BGcolor", BGcolor);
    CurrentUser.save();
    document.documentElement.style.setProperty(
      "--bgColor",
      CurrentUser.get("BGcolor")
    );
  }, [BGcolor]);

  useEffect(() => {
    auth.checkIfAdmin().then((users) => {
      // var myUsers = [];
      if (users != null) {
        users.forEach((user) => {
          if (user.get("email") === CurrentUserMail) {
            setAdmin(user.get("email"));
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (fullname && fullname.length > 0) {
      extraSpaceRemoved(fullname);
      if (!NameRegex.test(fullname)) {
        setNameError("Please enter a valid name (max 30 characters)");
      } else {
        setNameError("");
      }
    }
  }, [fullname]);

  useEffect(() => {
    if (contact && contact.length > 0) {
      if (!ContactRegex.test(contact)) {
        setContactError("Please enter a valid contact number (+91-1234567890)");
      } else {
        setContactError("");
      }
    }
  }, [contact]);

  const onFileChange = (e) => {
    setProfilepic(URL.createObjectURL(e.target.files[0]));
    auth.profilePic(e.target.files[0]).then((res) => {
      console.log(res);
    });
  };

  return (
    <Container style={{ position: "relative" }} className="main">
      <Header SuperAdmin={adminuser} Admin={Admin} title="Report" />
      <br />
      <br />
      {adminuser === CurrentUserMail ? (
        <h3 className="checkadmin">Super Admin</h3>
      ) : null}
      {Admin === CurrentUserMail ? <h3 className="checkadmin">Admin</h3> : null}

      <h1>{`Welcome ${fullname === "" ? "No Name" : fullname}`}</h1>
      <br />
      <br />
      <br />

      <Form>
        <Container fluid>
          <Row className="row">
            <Col xl={4} className="order-xl-2 mb-5 mb-xl-0">
              <Card>
                <Row className="justify-content-center">
                  <Col lg={3} className="order-lg-2">
                    <div className="card-profile-image">
                      <img
                        src={
                          profilepic
                            ? profilepic
                            : "https://demos.creative-tim.com/argon-dashboard/assets-old/img/theme/team-4.jpg"
                        }
                        alt="profile"
                        className="rounded-circle"
                        width="150"
                        height="150"
                      />
                    </div>
                  </Col>
                </Row>
                <Card.Header className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    <div className="upload">
                      <Button size="sm" className="myButton fileuploadbtn">
                        {profilepic ? "Click to Upload" : "Select Photo"}
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={onFileChange}
                        />
                      </Button>
                    </div>
                    {/* <Button className="myButton btn btn-sm">
                      <input type="file" accept="image/*" />
                    </Button>

                    <Button className="myButton btn btn-sm">Update</Button> */}
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="card-profile-stats d-flex justify-content-center mt-md-5"></div>
                  <div className="text-center">
                    <h3>Hi {fullname}</h3>
                    <h6 className="mb-4">About me</h6>
                    <div>
                      <Form.Group className="form-group focused">
                        <Form.Control
                          as="textarea"
                          rows={4}
                          className="form-control"
                          value={textdisabled ? about : null}
                          onChange={(e) => {
                            setAbout(e.target.value);
                          }}
                          placeholder="A few words about you ...!"
                          disabled={textdisabled}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col className="order-xl-2">
              <div>
                <img
                  width={350}
                  height={350}
                  src="https://demos.creative-tim.com/argon-dashboard/assets-old/img/theme/team-4.jpg"
                  alt="profile"
                  className="rounded-circle"
                />
              </div>
              <Card>
                <Card.Body>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Example textarea</Form.Label>
                      <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
              <br />
              <br />
            </Col> */}

            <Col xl={8} className="order-xl-1">
              <Card className="card bg-secondary shadow">
                <Card.Header className="card-header">
                  <Row style={{ alignItems: "center" }}>
                    <Col>
                      <h3 style={{ marginTop: "10px", marginLeft: "10px" }}>
                        My Account
                      </h3>
                    </Col>
                    <Col style={{ textAlign: "right", marginRight: "30px" }}>
                      {textdisabled ? (
                        <div>
                          <Button
                            className="myButton"
                            onClick={() => setTextDisabled(false)}
                          >
                            Edit
                          </Button>
                        </div>
                      ) : (
                        <Button
                          className="myButton"
                          onClick={() => {
                            setTextDisabled(true);
                            update();
                          }}
                        >
                          Update
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Card.Header>

                <Card.Body>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="">
                        <div className="accordinheader ">User information</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <UserDetails
                          CurrentUserMail={CurrentUserMail}
                          setFullname={setFullname}
                          fullname={fullname}
                          setUsername={setUsername}                          
                          username={username}
                          setContact={setContact}
                          contact={contact}
                          nameError={nameError}
                          contactError={contactError}
                          textdisabled={textdisabled}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    {adminuser === CurrentUserMail ? (
                      <div>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            Organization information
                          </Accordion.Header>
                          <Accordion.Body>
                            <EnterpriseProfile textdisabled={textdisabled} />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Access Controls</Accordion.Header>
                          <Accordion.Body>
                            <AccessControl adminuser={adminuser} />
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    ) : null}
                    {Admin === CurrentUserMail ||
                    adminuser === CurrentUserMail ? (
                      <div>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            License information
                          </Accordion.Header>
                          <Accordion.Body>
                            <LicenseProfile />
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    ) : null}
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Theme Options</Accordion.Header>
                      <Accordion.Body>
                        <ThemeDetails
                          Themecolor={Themecolor}
                          setThemecolor={setThemecolor}
                          Textcolor={Textcolor}
                          setTextcolor={setTextcolor}
                          BGcolor={BGcolor}
                          setBGolor={setBGolor}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
      <Footer />
    </Container>
  );
};

export default Adminprofile;
