import React, { useState, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { auth } from "services";

const licensevalid = "/data/license.json";

function LicenseProfile() {
  const [license, setlicense] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imagepipeline, setImagepipeline] = useState(null);
  const [textpipeline, setTextpipeline] = useState(null);
  const [structuredpipeline, setStructuredpipeline] = useState(null);
  const [regressionpipeline, setRegressionpipeline] = useState(null);
  const [audiopipeline, setAudiopipeline] = useState(null);
  const [timeseriespipeline, setTimeseriespipeline] = useState(null);
  const [sematicpipeline, setSematicpipeline] = useState(null);
  const [odpipeline, setOdpipeline] = useState(null);
  const [recommendationpipeline, setRecommendationpipeline] = useState(null);

  let keys = Object.keys(license);

  useEffect(() => {
    auth
      .License()
      .then(async (data) => {
        setImagepipeline(data.get("image"));
        setTextpipeline(data.get("text"));
        setRegressionpipeline(data.get("regression"));
        setTimeseriespipeline(data.get("timeseries"));
        setOdpipeline(data.get("od"));
        setAudiopipeline(data.get("audio"));
        setRecommendationpipeline(data.get("recommendation"));
        setStructuredpipeline(data.get("structured"));
        setSematicpipeline(data.get("sematic"));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // const handleChange = () => {
  //   if (loading) return;
  //   auth
  //     .License()
  //     .then(async (data) => {
  //       data.set("image", imagepipeline);
  //       data.set("text", textpipeline);
  //       data.set("od", odpipeline);
  //       data.set("structured", structuredpipeline);
  //       data.set("regression", regressionpipeline);
  //       data.set("sematic", sematicpipeline);
  //       data.set("audio", audiopipeline);
  //       data.set("timeseries", timeseriespipeline);
  //       data.set("recommendation", recommendationpipeline);
  //       data.save();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    if (loading) return;
    auth
      .License()
      .then((data) => {
        console.log(data);
        data.set("image", imagepipeline);
        data.set("text", textpipeline);
        data.set("od", odpipeline);
        data.set("structured", structuredpipeline);
        data.set("regression", regressionpipeline);
        data.set("sematic", sematicpipeline);
        data.set("audio", audiopipeline);
        data.set("timeseries", timeseriespipeline);
        data.set("recommendation", recommendationpipeline);
        data.save();
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    imagepipeline,
    textpipeline,
    odpipeline,
    structuredpipeline,
    regressionpipeline,
    sematicpipeline,
    audiopipeline,
    timeseriespipeline,
    recommendationpipeline,
  ]);

  useEffect(() => {
    fetch(licensevalid)
      .then((res) => res.json())
      .then((value) => {
        setlicense(value.License_Info);
        // console.log(license[keys[0]]);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      {license[keys[0]] ? (
        <h6 className="mb-4">{`License Valid From ${license[keys[0]]} to ${
          license[keys[1]]
        } `}</h6>
      ) : null}
      <div className="pl-lg-4">
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col lg={6} style={{ marginLeft: 15 }}>
            <Form>
              <Form.Check
                className="switch"
                type="switch"
                id="0switch"
                label="Image Pipeline"
                checked={imagepipeline}
                onChange={() => {
                  setImagepipeline(!imagepipeline);
                  // handleChange();
                }}
              />
              <Form.Check
                className="switch"
                type="switch"
                checked={textpipeline}
                id="1switch"
                label="Text Pipeline"
                onChange={() => setTextpipeline(!textpipeline)}
              />
              <Form.Check
                className="switch"
                type="switch"
                checked={structuredpipeline}
                id="2switch"
                label="Structured Pipline"
                onChange={() => setStructuredpipeline(!structuredpipeline)}
              />
              <Form.Check
                className="switch"
                type="switch"
                checked={regressionpipeline}
                id="3switch"
                label="Regression Pipeline"
                onChange={() => setRegressionpipeline(!regressionpipeline)}
              />
              <Form.Check
                className="switch"
                type="switch"
                checked={audiopipeline}
                id="4switch"
                label="Audio Pipeline"
                onChange={() => setAudiopipeline(!audiopipeline)}
              />
              <Form.Check
                className="switch"
                type="switch"
                checked={timeseriespipeline}
                id="5switch"
                label="TimeSeries Pipeline"
                onChange={() => setTimeseriespipeline(!timeseriespipeline)}
              />
              <Form.Check
                className="switch"
                type="switch"
                checked={odpipeline}
                id="7switch"
                label="OD Pipeline"
                onChange={() => setOdpipeline(!odpipeline)}
              />
              <Form.Check
                className="switch"
                type="switch"
                checked={sematicpipeline}
                id="6switch"
                label="Sematic Segmentation"
                onChange={() => setSematicpipeline(!sematicpipeline)}
              />
              <Form.Check
                // checked={true}
                className="switch"
                type="switch"
                checked={recommendationpipeline}
                id="8switch"
                label="Recommendation Pipeline"
                onChange={() =>
                  setRecommendationpipeline(!recommendationpipeline)
                }
              />
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default LicenseProfile;
