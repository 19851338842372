import React, { useEffect, useState } from "react";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";

const useImageLightBox = () => {
  const [data, setData] = useState("");
  const handleClose = () => setData("");
  const [caption, setCaption] = useState("");
  useEffect(() => {
    const findAndModifyImage = () => {
      console.log("omgu");
      const imageElement = document.querySelector(
        ".ril-image-current.ril__image"
      );
      if (imageElement) {
        imageElement.style.transform =
          "translate3d(-29px, 0px, 0px) scale3d(0.938133, 0.938133, 1)";
        imageElement.style.scale = "1.5";

        // imageElement.removeAttribute("style");
      } else {
        setTimeout(findAndModifyImage, 100);
      }
    };

    if (data) findAndModifyImage();
  }, [data]);

  const Imagebox = () => {
    return (
      <div>
        {!!data && (
          <Lightbox
            imageCaption=<h3>{caption}</h3>
            imageTitle=<h1>Use Mouse Wheel to Zoom</h1>
            mainSrc={data.src ? data.src : data}
            onCloseRequest={handleClose}
          />
        )}
      </div>
    );
  };
  return { Imagebox, setData, setCaption };
};

export default useImageLightBox;
