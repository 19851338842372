import React, { useEffect } from "react";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import { TwitterPicker } from "react-color";
import { auth } from "services";

const ThemeDetails = (props) => {
  const {
    Themecolor,
    setThemecolor,
    Textcolor,
    setTextcolor,
    BGcolor,
    setBGolor,
  } = props;

  const [AlertMssg, setAlertMssg] = useState("");
  // useEffect(() => {
  //   auth
  //     .Themes()
  //     .then((data) => {
  //       setBGolor(data.get("BGcolor"));
  //       setTextcolor(data.get("Textcolor"));
  //       setThemecolor(data.get("Themecolor"));
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);
  // useEffect(() => {
  //   auth
  //     .Themes()
  //     .then((data) => {
  //       data.set("BGcolor", BGcolor);
  //       data.set("Textcolor", Textcolor);
  //       data.set("Themecolor", Themecolor);
  //       data.save().then(() => {
  //         document.documentElement.style.setProperty(
  //           "--text",
  //           data.get("Textcolor")
  //         );
  //         document.documentElement.style.setProperty(
  //           "--bgColor",
  //           data.get("BGcolor")
  //         );

  //         document.documentElement.style.setProperty(
  //           "--content",
  //           data.get("Themecolor")
  //         );
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [Textcolor, BGcolor, Themecolor]);

  return (
    <div>
      <h4>Theme</h4>
      <TwitterPicker
        width=""
        color={Themecolor}
        colors={[
          "#A03C64",
          "#1f1f1f",
          "#FF6900",
          "#FCB900",
          "#8ED1FC",
          "#0693E3",
          "#ABB8C3",
          "#EB144C",
          "#F78DA7",
          "#9900EF",
        ]}
        onChange={(Themecolor) => {
          if (Themecolor.hex !== Textcolor && Themecolor.hex !== BGcolor) {
            setThemecolor(Themecolor.hex);
          } else {
            setAlertMssg("Theme color cannot be same as Text or Background");
          }
        }}
      />
      <br />

      <h4>Text</h4>
      <TwitterPicker
        width=""
        color={Textcolor}
        colors={["#ccc", "#717372", "#fff", "#000"]}
        onChange={(Textcolor) => {
          if (Textcolor.hex !== Themecolor && Textcolor.hex !== BGcolor) {
            setTextcolor(Textcolor.hex);
          } else {
            setAlertMssg("Text color cannot be same as Theme or Background");
          }
        }}
      />
      <br />
      <h4>Background</h4>
      <TwitterPicker
        width=""
        color={BGcolor}
        colors={["#ffffff", "#000"]}
        onChange={(BGcolor) => {
          if (BGcolor.hex !== Themecolor && BGcolor.hex !== Textcolor) {
            setBGolor(BGcolor.hex);
          } else {
            setAlertMssg("Background color cannot be same as Theme or Text");
          }
        }}
      />

      <br />
      {AlertMssg !== ""
        ? (setTimeout(() => {
            setAlertMssg("");
          }, 2000),
          (<Alert variant="danger">{AlertMssg}</Alert>))
        : null}
    </div>
  );
};

export default ThemeDetails;
