import React, { useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";

import { auth } from "services";
import Auth from "./Auth";
import gif from "../../images/2d.gif";
import FormEle from "./FormEle";
import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from "./validator";
import { useForm } from "../../hooks/Auth/useForm";
import "./Auth.scss";

const SignIn = () => {
  useEffect(() => {
    auth.createRoles();
  }, []);

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const submitHandler = (event) => {
    event.preventDefault();
    console.log(formState.inputs); // send this to the backend

    auth
      .login(formState.inputs.email.value, formState.inputs.password.value)
      .then((user) => {
        console.log(user.get("email"));
        window.location = "/";
      })
      .catch((error) => {
        swal("Error", error.message, "error");
      });
  };

  return (
    <Auth
      title="Log In"
      switchText="or Don't have an account?"
      switchLink="/auth/signup"
      img={gif}
      footerLink="/auth/forgot-password"
      footerText="Forgot Password?"
    >
      <form className="auth__body__form" onSubmit={submitHandler}>
        <FormEle
          id="email"
          element="input"
          type="email"
          label="E-Mail"
          validators={[VALIDATOR_EMAIL()]}
          errorText="Please enter a valid email address."
          onInput={inputHandler}
        />
        <FormEle
          id="password"
          element="input"
          type="password"
          label="Password"
          validators={[VALIDATOR_MINLENGTH(1)]}
          errorText="Please enter password."
          onInput={inputHandler}
        />
        <div className="auth__submit__container">
          <FormEle
            id="remember"
            element="checkbox"
            type="checkbox"
            label="Remember me"
            onInput={() => {}}
          />
          <button
            className="submit-button"
            type="submit"
            disabled={!formState.isValid}
          >
            Sign in
          </button>
        </div>
      </form>
    </Auth>
  );
};

export default SignIn;
