import React, { useState, useEffect, useRef } from "react";
import { Alert } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";

import { auth } from "services";
import Auth from "./Auth";
import gif from "../../images/1d.gif";
import FormEle from "./FormEle";
import { VALIDATOR_EMAIL } from "./validator";
import { useForm } from "../../hooks/Auth/useForm";
import "./Auth.scss";

const ForgotPwd = () => {
  const [count, setCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState(false);

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const submitHandler = (event) => {
    event.preventDefault();
    console.log(formState.inputs);
    auth
      .resetPassword(formState.inputs.email.value)
      .then((res) => {
        setSuccessMessage(true);
        setCount(30);
        setTimeout(() => {
          setSuccessMessage(false);
        }, 5000);
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: "Kindly enter registered email Id",
          icon: "error",
          button: "Ok",
        });
      });
  };

  let intervalRef = useRef();

  const countDown = () => {
    setCount((prevCount) => prevCount - 1);
  };

  useEffect(() => {
    if (count > 0) {
      intervalRef.current = setInterval(countDown, 1000);
    }
    return () => clearInterval(intervalRef.current);
  }, [count]);

  return (
    <Auth
      title="Reset Password"
      switchText="or Don't have an account?"
      switchLink="/auth/signup"
      img={gif}
      footerLink="/auth/login"
      footerText="login to your account"
    >
      <form className="auth__body__form" onSubmit={submitHandler}>
        <FormEle
          id="email"
          element="input"
          type="email"
          label="E-Mail"
          validators={[VALIDATOR_EMAIL()]}
          errorText="Please enter a valid email address."
          onInput={inputHandler}
        />
        <div className="auth__submit__container">
          <button
            className="submit-button"
            type="submit"
            disabled={!formState.isValid || count !== 0}
          >
            Send Reset Link
          </button>
        </div>
        {count ? (
          <div className="resend-update">
            {count ? `Resend link in ${count} seconds` : ``}
          </div>
        ) : null}
        <Alert
          variant="success"
          show={successMessage ? true : false}
          onClose={() => setSuccessMessage(false)}
          dismissible
        >
          Email sent successfully!
        </Alert>
      </form>
    </Auth>
  );
};

export default ForgotPwd;
