import React, { useRef, useState } from "react";
import { Row, Col, Container, Form, Accordion, Button } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import MainConfig from "./MainConfig";
import LoadingConfig from "./LoadingConfig";
import Modeling from "./Modeling";
import ModelExplainability from "./ModelExplainability";
import AttackVectors from "./AttackVectors";
import MetamorphicTesting from "./MetamorphicTesting";
import Deepxplore from "./Deepxplore";
import axios from "axios";

const Index = () => {
  const mainConfigRef = useRef();
  const loadingConfigRef = useRef();
  const modelingRef = useRef();
  const modelExplainabilityRef = useRef();
  const attackVectorsRef = useRef();
  const metamorphicTestingRef = useRef();
  const deepxploreRef = useRef();

  const [active, setActive] = useState("3");

  const handleClick = () => {
    mainConfigRef.current.handleClick();
    loadingConfigRef.current.handleClick();
    modelingRef.current.handleClick();
    modelExplainabilityRef.current.handleClick();
    attackVectorsRef.current.handleClick();
    metamorphicTestingRef.current.handleClick();
    deepxploreRef.current.handleClick();
  };

  const clickme = () => {
    // const data = {
    //   hello: "ls -la",
    // };
    // axios
    //   .post("http://localhost:3030/cmd", data)
    //   .then(() => {})
    //   .catch(() => {
    //     console.log("Something went wrong. Please try again later.");
    //   });
    // window.location = '/progress';
  };

  const handlePreset1 = () => {
    mainConfigRef.current.handlePreset1();
  };

  const handlePreset2 = () => {
    mainConfigRef.current.handlePreset2();
  };

  return (
    <Container>
      <Row>
        <Col md={{ span: 12 }}>
          <br />
          <Form.Group controlId="formGridState">
            <Col>
              <Form>
                <Row>
                  <Col md={8} className="selectOption"></Col>
                  <Col md={{ span: 4 }} style={{ marginTop: "-50px" }}>
                    <Button
                      variant="primary"
                      block
                      size="lg"
                      className="myButton"
                      onClick={() => {
                        handleClick();
                        clickme();
                      }}
                    >
                      Launch <FontAwesomeIcon icon={faArrowCircleRight} />
                    </Button>
                  </Col>
                </Row>
              </Form>
              <br />
              <Form>
                <Row>
                  <Col md={2}></Col>
                  <Col md={2}>
                    <Button
                      variant="primary"
                      size="sm"
                      className="myButton"
                      onClick={handlePreset1}
                    >
                      Apply Preset 1
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Button
                      variant="primary"
                      size="sm"
                      className="myButton"
                      onClick={handlePreset2}
                    >
                      Apply Preset 2
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Form.Group>
          <Accordion defaultActiveKey="6" onSelect={(e) => setActive(e)}>
            <Accordion.Item eventKey="6">
              <Accordion.Header>Main Config</Accordion.Header>
              <Accordion.Body>
                {active === "6" ? (
                  <MainConfig ref={mainConfigRef} isActive={true} />
                ) : (
                  <MainConfig ref={mainConfigRef} />
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="0">
              <Accordion.Header>Loading Config</Accordion.Header>
              <Accordion.Body>
                {active === "0" ? (
                  <LoadingConfig ref={loadingConfigRef} isActive={true} />
                ) : (
                  <LoadingConfig ref={loadingConfigRef} />
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Modeling</Accordion.Header>
              <Accordion.Body>
                {active === "1" ? (
                  <Modeling ref={modelingRef} isActive={true} />
                ) : (
                  <Modeling ref={modelingRef} />
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Model Explainability</Accordion.Header>
              <Accordion.Body>
                {active === "2" ? (
                  <ModelExplainability
                    ref={modelExplainabilityRef}
                    isActive={true}
                  />
                ) : (
                  <ModelExplainability ref={modelExplainabilityRef} />
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Attack Vectors</Accordion.Header>
              <Accordion.Body>
                {active === "3" ? (
                  <AttackVectors ref={attackVectorsRef} isActive={true} />
                ) : (
                  <AttackVectors ref={attackVectorsRef} />
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Metamorphic Testing</Accordion.Header>
              <Accordion.Body>
                {active === "4" ? (
                  <MetamorphicTesting
                    ref={metamorphicTestingRef}
                    isActive={true}
                  />
                ) : (
                  <MetamorphicTesting ref={metamorphicTestingRef} />
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Deep Explore</Accordion.Header>
              <Accordion.Body>
                {active === "5" ? (
                  <Deepxplore ref={deepxploreRef} isActive={true} />
                ) : (
                  <Deepxplore ref={deepxploreRef} />
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default Index;
