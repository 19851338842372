import React from 'react';
import { Row, Col, Form, Accordion, Card, Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Master Config
      config_master: {},
      dataset_path_csv_path: '',
      target_column_target_column: '',
      features_train_data_percent: '',
      features_time_step: '',
      model_params_epochs: '',
      model_params_batch_size: '',
      FLAGS_execute_preprocessing: '',
      FLAGS_execute_modelling: '',
      FLAGS_execute_localExplainabilty: '',
      FLAGS_execute_globalExplainabilty: '',
    }
  }

  componentDidMount() {
    //Master Config
    fetch("http://localhost:3030" + "/config/timeseries/master")
      .then(response => response.json())
      .then(data => {
        this.setState({ config_master: data._sections });
        const { config_master } = this.state
        console.log('Master Config');
        console.log(config_master);
        this.setState({ dataset_path_csv_path: config_master.dataset_path.csv_path })
        this.setState({ target_column_target_column: config_master.target_column.target_column })
        this.setState({ features_train_data_percent: config_master.features.train_data_percent })
        this.setState({ features_time_step: config_master.features.time_step })
        this.setState({ model_params_epochs: config_master.model_params.epochs })
        this.setState({ model_params_batch_size: config_master.model_params.batch_size })
        this.setState({ FLAGS_execute_preprocessing: config_master.FLAGS['execute.preprocessing'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_modelling: config_master.FLAGS['execute.modelling'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_localExplainabilty: config_master.FLAGS['execute.localExplainabilty'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_globalExplainabilty: config_master.FLAGS['execute.globalExplainabilty'] === 'True' ? true : false })
      })
  }

  handleClick = () => {
    //Master Config
    const {config_master, dataset_path_csv_path, target_column_target_column, features_train_data_percent, features_time_step, model_params_epochs, model_params_batch_size, FLAGS_execute_preprocessing, FLAGS_execute_modelling, FLAGS_execute_localExplainabilty, FLAGS_execute_globalExplainabilty } = this.state

    config_master.dataset_path.csv_path = dataset_path_csv_path
    config_master.target_column.target_column = target_column_target_column
    config_master.features.train_data_percent = features_train_data_percent
    config_master.features.time_step = features_time_step
    config_master.model_params.epochs = model_params_epochs
    config_master.model_params.batch_size = model_params_batch_size
    config_master.FLAGS['execute.preprocessing'] = FLAGS_execute_preprocessing === true ? 'True' : 'False'
    config_master.FLAGS['execute.modelling'] = FLAGS_execute_modelling === true ? 'True' : 'False'
    config_master.FLAGS['execute.localExplainabilty'] = FLAGS_execute_localExplainabilty === true ? 'True' : 'False'
    config_master.FLAGS['execute.globalExplainabilty'] = FLAGS_execute_globalExplainabilty === true ? 'True' : 'False'

    fetch("http://localhost:3030" + '/config/timeseries/master', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config_master)
    }).then(() => {
      console.log('Master Config Request posted')
    })
  }

  render() {
    //Master Config
    const {dataset_path_csv_path, target_column_target_column, features_train_data_percent, features_time_step, model_params_epochs, model_params_batch_size, FLAGS_execute_preprocessing, FLAGS_execute_modelling, FLAGS_execute_localExplainabilty, FLAGS_execute_globalExplainabilty } = this.state

    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1" block>
            <Row>
              <Col md={8} align="left">
                Master Config
              </Col>
              <Col md={4} align="right">
                {this.props.isActive ? <FontAwesomeIcon icon={faArrowCircleUp} /> : <FontAwesomeIcon icon={faArrowCircleDown} />}
              </Col>
            </Row>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <h5>[dataset_path]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>csv_path</Form.Label>
                <Form.Control size='sm' defaultValue={dataset_path_csv_path} onChange={e => this.setState({ dataset_path_csv_path: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[target_column]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>target_column</Form.Label>
                <Form.Control size='sm' defaultValue={target_column_target_column} onChange={e => this.setState({ target_column_target_column: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[features]</h5>
            <Row>
              <Col md={6}>
                train_data_percent
                <RangeSlider
                  value={features_train_data_percent}
                  min={0}
                  max={2}
                  step={0.1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ features_train_data_percent: changeEvent.target.value })} />
              </Col>
              <Col md={6}>
                time_step
                <RangeSlider
                  value={features_time_step}
                  min={0}
                  max={200}
                  step={2}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ features_time_step: changeEvent.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[model_params]</h5>
            <Row>
              <Col md={6}>
                epochs
                <RangeSlider
                  value={model_params_epochs}
                  min={0}
                  max={2}
                  step={0.1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ model_params_epochs: changeEvent.target.value })} />
              </Col>
              <Col md={6}>
                batch_size
                <RangeSlider
                  value={model_params_batch_size}
                  min={0}
                  max={100}
                  step={2}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ model_params_batch_size: changeEvent.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[FLAGS]</h5>
            <Form.Check inline label="execute.preprocessing" type={'switch'} id={'check1'} checked={FLAGS_execute_preprocessing} onChange={changeEvent => this.setState({ FLAGS_execute_preprocessing: changeEvent.target.checked })} />
            <Form.Check inline label="execute.modelling" type={'switch'} id={'check2'} checked={FLAGS_execute_modelling} onChange={changeEvent => this.setState({ FLAGS_execute_modelling: changeEvent.target.checked })} />
            <Form.Check inline label="execute.localExplainabilty" type={'switch'} id={'check3'} checked={FLAGS_execute_localExplainabilty} onChange={changeEvent => this.setState({ FLAGS_execute_localExplainabilty: changeEvent.target.checked })} />
            <Form.Check inline label="execute.globalExplainabilty" type={'switch'} id={'check4'} checked={FLAGS_execute_globalExplainabilty} onChange={changeEvent => this.setState({ FLAGS_execute_globalExplainabilty: changeEvent.target.checked })} />
            <br />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }
}

export default Index;
