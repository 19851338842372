import React, { useEffect, useState } from "react";
import { Col, Row, Form, Alert } from "react-bootstrap";
import { auth } from "services";

const EnterpriseProfile = (props) => {
  const [address, setAddress] = useState("");
  const [orgName, setOrgName] = useState("");
  const [domain, setDomain] = useState("");
  const [alert, setAlert] = useState("");

  const { textdisabled } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await auth.License();
        setAddress(data.get("address"));
        setOrgName(data.get("orgname"));
        setDomain(data.get("domain"));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const saveData = async () => {
      try {
        const data = await auth.License();
        data.set("address", address);
        data.set("orgname", orgName);
        data.set("domain", domain);
        await data.save();
      } catch (error) {
        console.log(error);
      }
    };
    saveData();
  }, [address, orgName, domain]);

  const onFileChange = async (e) => {
    try {
      await auth.orgLogo(e.target.files[0]);
      setAlert("Photo Changed Successfully");
      setTimeout(() => {
        setAlert("");
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="labels">Organization Name</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              placeholder="eg: testAing"
              onChange={(event) => setOrgName(event.target.value)}
              value={orgName}
              disabled={textdisabled}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="labels">Organization Address</Form.Label>
            <Form.Control
              type="email"
              className="form-control"
              placeholder="eg: 108/B, Sector-58, Noida-201301, Uttar Pradesh"
              onChange={(event) => setAddress(event.target.value)}
              value={address}
              disabled={textdisabled}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="labels">Email Domain</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              placeholder="eg: testaing.com"
              onChange={(event) => setDomain(event.target.value)}
              value={domain}
              disabled={textdisabled}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group>
            <label className="labels">Organization Logo</label>
            <div className="upload">
              <button className="myButton fileuploadbtn btn-sm">
                Choose Photo
                <input type="file" accept=".png" onChange={onFileChange} />
              </button>
            </div>
            <br />
            {alert ? <Alert variant="success">{alert}</Alert> : null}
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default EnterpriseProfile;
