import React from 'react';
import { Row, Col, Container, Form, Accordion, Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import MasterConfig from './MasterConfig';
import Config from './Config';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: '1'
    }
    this.masterConfig = React.createRef()
    this.config = React.createRef()
  }

  componentDidMount() {

  }

  handleClick = () => {
    this.masterConfig.current.handleClick()
    this.config.current.handleClick()
  }

  render() {

    return (
      <Container>
        <Row>
          <Col md={{ span: 12 }}>
            <br />
            <Form.Group as={Col} controlId="formGridState">
              <Form.Row>
                <Col md={8} className="selectOption">

                </Col>
                <Col md={{ span: 4 }} style={{marginTop: '-50px'}}>
                  <Button variant="primary" block size="lg" className="myButton" onClick={this.handleClick}>
                    Launch <FontAwesomeIcon icon={faArrowCircleRight} />
                  </Button>
                </Col>
              </Form.Row>
            </Form.Group>
            <Accordion defaultActiveKey="1" onSelect={e => this.setState({ active: e })}>
              {this.state.active === '1' ? <MasterConfig ref={this.masterConfig} isActive={true} /> : <MasterConfig ref={this.masterConfig} />}
              {this.state.active === '5' ? <Config ref={this.config} isActive={true} /> : <Config ref={this.config} />}
            </Accordion>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Index;
