import React from 'react';
import { Row, Col, Form, Accordion, Card, Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Config
      config: {},
      data_paths_data_path_folder: '',
      data_paths_test_x_df: '',
      data_paths_train_x_df: '',
      data_paths_test_y_df: '',
      data_paths_train_y_df: '',
      data_paths_test_preds: '',
      data_paths_le_name_mapping: '',
      model_paths_model_path_folder: '',
      model_paths_model: '',
      Local_visualization_ice: '',
      Local_visualization_loco_fp: '',
      Local_visualization_loco_fn: '',
      Local_visualization_loco_user: '',
      Local_visualization_shap_fp: '',
      Local_visualization_shap_fn: '',
      Local_visualization_shap_user: '',
      Local_visualization_lime_fp: '',
      Local_visualization_lime_fn: '',
      Local_visualization_lime_user: '',
      Global_visualization_pdp: '',
      Global_visualization_global_visualize: '',
      Global_visualization_decision_tree_surrogates_modelling: '',
      Global_visualization_shap_global_graphics: '',
      Global_visualization_permutation: '',
      Global_visualization_global_index_score: '',
      fairml_visualization_fairml: '',
      fairml_visualization_bias1: '',
      fairml_visualization_bias2: '',
      ART_art: '',
      ART_path: '',
      ART_query_path: '',
      synthetic_data_paths_data_gen: '',
      synthetic_data_paths_synthetic_data: '',
      eda_paths_eda_gen: '',
      eda_paths_eda_html: '',
      eda_paths_eda_json: '',
      counterfactuals_path: '',
      counterfactuals_global_features_dict: '',
      Bias_Parameters_learning_iterations: '',
      Bias_Parameters_positively_labelled_sample_size: '',
      Bias_Parameters_n_feats_define_bias: ''
    }
  }

  componentDidMount() {
    //Config
    fetch("http://localhost:3030/config/structured/config")
      .then(response => response.json())
      .then(data => {
        this.setState({ config: data._sections });
        const { config } = this.state;
        this.setState({ data_paths_data_path_folder: config.data_paths.data_path_folder })
        this.setState({ data_paths_test_x_df: config.data_paths.test_x_df })
        this.setState({ data_paths_train_x_df: config.data_paths.train_x_df })
        this.setState({ data_paths_test_y_df: config.data_paths.test_y_df })
        this.setState({ data_paths_train_y_df: config.data_paths.train_y_df })
        this.setState({ data_paths_test_preds: config.data_paths.test_preds })
        this.setState({ data_paths_le_name_mapping: config.data_paths.le_name_mapping })
        this.setState({ model_paths_model_path_folder: config.model_paths.model_path_folder })
        this.setState({ model_paths_model: config.model_paths.model })
        this.setState({ Local_visualization_ice: config.Local_visualization.ice })
        this.setState({ Local_visualization_loco_fp: config.Local_visualization.loco_fp })
        this.setState({ Local_visualization_loco_fn: config.Local_visualization.loco_fn })
        this.setState({ Local_visualization_loco_user: config.Local_visualization.loco_user })
        this.setState({ Local_visualization_shap_fp: config.Local_visualization.shap_fp })
        this.setState({ Local_visualization_shap_fn: config.Local_visualization.shap_fn })
        this.setState({ Local_visualization_shap_user: config.Local_visualization.shap_user })
        this.setState({ Local_visualization_lime_fp: config.Local_visualization.lime_fp })
        this.setState({ Local_visualization_lime_fn: config.Local_visualization.lime_fn })
        this.setState({ Local_visualization_lime_user: config.Local_visualization.lime_user })
        this.setState({ Global_visualization_pdp: config.Global_visualization.pdp })
        this.setState({ Global_visualization_global_visualize: config.Global_visualization.global_visualize })
        this.setState({ Global_visualization_decision_tree_surrogates_modelling: config.Global_visualization.decision_tree_surrogates_modelling })
        this.setState({ Global_visualization_shap_global_graphics: config.Global_visualization.shap_global_graphics })
        this.setState({ Global_visualization_permutation: config.Global_visualization.permutation })
        this.setState({ Global_visualization_global_index_score: config.Global_visualization.global_index_score })
        this.setState({ fairml_visualization_fairml: config.fairml_visualization.fairml })
        this.setState({ fairml_visualization_bias1: config.fairml_visualization.bias1 })
        this.setState({ fairml_visualization_bias2: config.fairml_visualization.bias2 })
        this.setState({ ART_art: config.ART.art })
        this.setState({ ART_path: config.ART.path })
        this.setState({ ART_query_path: config.ART.query_path })
        this.setState({ synthetic_data_paths_data_gen: config.synthetic_data_paths.data_gen })
        this.setState({ synthetic_data_paths_synthetic_data: config.synthetic_data_paths.synthetic_data })
        this.setState({ eda_paths_eda_gen: config.eda_paths.eda_gen })
        this.setState({ eda_paths_eda_html: config.eda_paths.eda_html })
        this.setState({ eda_paths_eda_json: config.eda_paths.eda_json })
        this.setState({ counterfactuals_path: config.counterfactuals.path })
        this.setState({ counterfactuals_global_features_dict: config.counterfactuals.global_features_dict })
        this.setState({ Bias_Parameters_learning_iterations: config['Bias Parameters'].learning_iterations })
        this.setState({ Bias_Parameters_positively_labelled_sample_size: config['Bias Parameters'].positively_labelled_sample_size })
        this.setState({ Bias_Parameters_n_feats_define_bias: config['Bias Parameters'].n_feats_define_bias })
      })
  }

  handleClick = () => {
    //Config
    const { config, data_paths_data_path_folder, data_paths_test_x_df, data_paths_train_x_df, data_paths_test_y_df, data_paths_train_y_df, data_paths_test_preds, data_paths_le_name_mapping, model_paths_model_path_folder, model_paths_model, Local_visualization_ice, Local_visualization_loco_fp, Local_visualization_loco_fn, Local_visualization_loco_user, Local_visualization_shap_fp, Local_visualization_shap_fn, Local_visualization_shap_user, Local_visualization_lime_fp, Local_visualization_lime_fn, Local_visualization_lime_user, Global_visualization_pdp, Global_visualization_global_visualize, Global_visualization_decision_tree_surrogates_modelling, Global_visualization_shap_global_graphics, Global_visualization_permutation, Global_visualization_global_index_score, fairml_visualization_fairml, fairml_visualization_bias1, fairml_visualization_bias2, ART_art, ART_path, ART_query_path, synthetic_data_paths_data_gen, synthetic_data_paths_synthetic_data, eda_paths_eda_gen, eda_paths_eda_html, eda_paths_eda_json, counterfactuals_path, counterfactuals_global_features_dict, Bias_Parameters_learning_iterations, Bias_Parameters_positively_labelled_sample_size, Bias_Parameters_n_feats_define_bias } = this.state

    config.data_paths.data_path_folder = data_paths_data_path_folder
    config.data_paths.test_x_df = data_paths_test_x_df
    config.data_paths.train_x_df = data_paths_train_x_df
    config.data_paths.test_y_df = data_paths_test_y_df
    config.data_paths.train_y_df = data_paths_train_y_df
    config.data_paths.test_preds = data_paths_test_preds
    config.data_paths.le_name_mapping = data_paths_le_name_mapping
    config.model_paths.model_path_folder = model_paths_model_path_folder
    config.model_paths.model = model_paths_model
    config.Local_visualization.ice = Local_visualization_ice
    config.Local_visualization.loco_fp = Local_visualization_loco_fp
    config.Local_visualization.loco_fn = Local_visualization_loco_fn
    config.Local_visualization.loco_user = Local_visualization_loco_user
    config.Local_visualization.shap_fp = Local_visualization_shap_fp
    config.Local_visualization.shap_fn = Local_visualization_shap_fn
    config.Local_visualization.shap_user = Local_visualization_shap_user
    config.Local_visualization.lime_fp = Local_visualization_lime_fp
    config.Local_visualization.lime_fn = Local_visualization_lime_fn
    config.Local_visualization.lime_user = Local_visualization_lime_user
    config.Global_visualization.pdp = Global_visualization_pdp
    config.Global_visualization.global_visualize = Global_visualization_global_visualize
    config.Global_visualization.decision_tree_surrogates_modelling = Global_visualization_decision_tree_surrogates_modelling
    config.Global_visualization.shap_global_graphics = Global_visualization_shap_global_graphics
    config.Global_visualization.permutation = Global_visualization_permutation
    config.Global_visualization.global_index_score = Global_visualization_global_index_score
    config.fairml_visualization.fairml = fairml_visualization_fairml
    config.fairml_visualization.bias1 = fairml_visualization_bias1
    config.fairml_visualization.bias2 = fairml_visualization_bias2
    config.ART.art = ART_art
    config.ART.path = ART_path
    config.ART.query_path = ART_query_path
    config.synthetic_data_paths.data_gen = synthetic_data_paths_data_gen
    config.synthetic_data_paths.synthetic_data = synthetic_data_paths_synthetic_data
    config.eda_paths.eda_gen = eda_paths_eda_gen
    config.eda_paths.eda_html = eda_paths_eda_html
    config.eda_paths.eda_json = eda_paths_eda_json
    config.counterfactuals.path = counterfactuals_path
    config.counterfactuals.global_features_dict = counterfactuals_global_features_dict
    config['Bias Parameters'].learning_iterations = Bias_Parameters_learning_iterations
    config['Bias Parameters'].positively_labelled_sample_size = Bias_Parameters_positively_labelled_sample_size
    config['Bias Parameters'].n_feats_define_bias = Bias_Parameters_n_feats_define_bias

    fetch("http://localhost:3030/config/structured/config", {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config)
    }).then(() => {
      console.log('Config Request posted')
    })
  }

  render() {
    //Config
    const { data_paths_data_path_folder, data_paths_test_x_df, data_paths_train_x_df, data_paths_test_y_df, data_paths_train_y_df, data_paths_test_preds, data_paths_le_name_mapping, model_paths_model_path_folder, model_paths_model, Local_visualization_ice, Local_visualization_loco_fp, Local_visualization_loco_fn, Local_visualization_loco_user, Local_visualization_shap_fp, Local_visualization_shap_fn, Local_visualization_shap_user, Local_visualization_lime_fp, Local_visualization_lime_fn, Local_visualization_lime_user, Global_visualization_pdp, Global_visualization_global_visualize, Global_visualization_decision_tree_surrogates_modelling, Global_visualization_shap_global_graphics, Global_visualization_permutation, Global_visualization_global_index_score, fairml_visualization_fairml, fairml_visualization_bias1, fairml_visualization_bias2, ART_art, ART_path, ART_query_path, synthetic_data_paths_data_gen, synthetic_data_paths_synthetic_data, eda_paths_eda_gen, eda_paths_eda_html, eda_paths_eda_json, counterfactuals_path, counterfactuals_global_features_dict, Bias_Parameters_learning_iterations, Bias_Parameters_positively_labelled_sample_size, Bias_Parameters_n_feats_define_bias } = this.state

    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="5" block>
            <Row>
              <Col md={8} align="left">
                Config
              </Col>
              <Col md={4} align="right">
                {this.props.isActive ? <FontAwesomeIcon icon={faArrowCircleUp} /> : <FontAwesomeIcon icon={faArrowCircleDown} />}
              </Col>
            </Row>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="5">
          <Card.Body>
            <h5>[data_paths]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>data_path_folder</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_data_path_folder} onChange={e => this.setState({ data_paths_data_path_folder: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>test_x_df</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_test_x_df} onChange={e => this.setState({ data_paths_test_x_df: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>train_x_df</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_train_x_df} onChange={e => this.setState({ data_paths_train_x_df: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>test_y_df</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_test_y_df} onChange={e => this.setState({ data_paths_test_y_df: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>train_y_df</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_train_y_df} onChange={e => this.setState({ data_paths_train_y_df: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>test_preds</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_test_preds} onChange={e => this.setState({ data_paths_test_preds: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>le_name_mapping</Form.Label>
                <Form.Control size='sm' defaultValue={data_paths_le_name_mapping} onChange={e => this.setState({ data_paths_le_name_mapping: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[model_paths]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>model_path_folder</Form.Label>
                <Form.Control size='sm' defaultValue={model_paths_model_path_folder} onChange={e => this.setState({ model_paths_model_path_folder: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>model</Form.Label>
                <Form.Control size='sm' defaultValue={model_paths_model} onChange={e => this.setState({ model_paths_model: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[Local_visualization]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>ice</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_ice} onChange={e => this.setState({ Local_visualization_ice: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>loco_fp</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_loco_fp} onChange={e => this.setState({ Local_visualization_loco_fp: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>loco_fn</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_loco_fn} onChange={e => this.setState({ Local_visualization_loco_fn: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>loco_user</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_loco_user} onChange={e => this.setState({ Local_visualization_loco_user: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>shap_fp</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_shap_fp} onChange={e => this.setState({ Local_visualization_shap_fp: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>shap_fn</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_shap_fn} onChange={e => this.setState({ Local_visualization_shap_fn: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>shap_user</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_shap_user} onChange={e => this.setState({ Local_visualization_shap_user: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>lime_fp</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_lime_fp} onChange={e => this.setState({ Local_visualization_lime_fp: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>lime_fn</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_lime_fn} onChange={e => this.setState({ Local_visualization_lime_fn: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>lime_user</Form.Label>
                <Form.Control size='sm' defaultValue={Local_visualization_lime_user} onChange={e => this.setState({ Local_visualization_lime_user: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[Global_visualization]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>pdp</Form.Label>
                <Form.Control size='sm' defaultValue={Global_visualization_pdp} onChange={e => this.setState({ Global_visualization_pdp: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>global_visualize</Form.Label>
                <Form.Control size='sm' defaultValue={Global_visualization_global_visualize} onChange={e => this.setState({ Global_visualization_global_visualize: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>decision_tree_surrogates_modelling</Form.Label>
                <Form.Control size='sm' defaultValue={Global_visualization_decision_tree_surrogates_modelling} onChange={e => this.setState({ Global_visualization_decision_tree_surrogates_modelling: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>shap_global_graphics</Form.Label>
                <Form.Control size='sm' defaultValue={Global_visualization_shap_global_graphics} onChange={e => this.setState({ Global_visualization_shap_global_graphics: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>permutation</Form.Label>
                <Form.Control size='sm' defaultValue={Global_visualization_permutation} onChange={e => this.setState({ Global_visualization_permutation: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>global_index_score</Form.Label>
                <Form.Control size='sm' defaultValue={Global_visualization_global_index_score} onChange={e => this.setState({ Global_visualization_global_index_score: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[fairml_visualization]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>fairml</Form.Label>
                <Form.Control size='sm' defaultValue={fairml_visualization_fairml} onChange={e => this.setState({ fairml_visualization_fairml: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>bias1</Form.Label>
                <Form.Control size='sm' defaultValue={fairml_visualization_bias1} onChange={e => this.setState({ fairml_visualization_bias1: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>bias2</Form.Label>
                <Form.Control size='sm' defaultValue={fairml_visualization_bias2} onChange={e => this.setState({ fairml_visualization_bias2: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[ART]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>art</Form.Label>
                <Form.Control size='sm' defaultValue={ART_art} onChange={e => this.setState({ ART_art: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>path</Form.Label>
                <Form.Control size='sm' defaultValue={ART_path} onChange={e => this.setState({ ART_path: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>query_path</Form.Label>
                <Form.Control size='sm' defaultValue={ART_query_path} onChange={e => this.setState({ ART_query_path: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[synthetic_data_paths]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>data_gen</Form.Label>
                <Form.Control size='sm' defaultValue={synthetic_data_paths_data_gen} onChange={e => this.setState({ synthetic_data_paths_data_gen: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>synthetic_data</Form.Label>
                <Form.Control size='sm' defaultValue={synthetic_data_paths_synthetic_data} onChange={e => this.setState({ synthetic_data_paths_synthetic_data: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[eda_paths]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>eda_gen</Form.Label>
                <Form.Control size='sm' defaultValue={eda_paths_eda_gen} onChange={e => this.setState({ eda_paths_eda_gen: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>eda_html</Form.Label>
                <Form.Control size='sm' defaultValue={eda_paths_eda_html} onChange={e => this.setState({ eda_paths_eda_html: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>eda_json</Form.Label>
                <Form.Control size='sm' defaultValue={eda_paths_eda_json} onChange={e => this.setState({ eda_paths_eda_json: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[counterfactuals]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>path</Form.Label>
                <Form.Control size='sm' defaultValue={counterfactuals_path} onChange={e => this.setState({ counterfactuals_path: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>global_features_dict</Form.Label>
                <Form.Control size='sm' defaultValue={counterfactuals_global_features_dict} onChange={e => this.setState({ counterfactuals_global_features_dict: e.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[Bias Parameters]</h5>
            <Row>
              <Col md={4}>
                learning_iterations
                <RangeSlider
                  value={Bias_Parameters_learning_iterations}
                  min={0}
                  max={100}
                  step={1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ Bias_Parameters_learning_iterations: changeEvent.target.value })} />
              </Col>
              <Col md={4}>
                positively_labelled_sample_size
                <RangeSlider
                  value={Bias_Parameters_positively_labelled_sample_size}
                  min={0}
                  max={1}
                  step={0.01}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ Bias_Parameters_positively_labelled_sample_size: changeEvent.target.value })} />
              </Col>
              <Col md={4}>
                n_feats_define_bias
                <RangeSlider
                  value={Bias_Parameters_n_feats_define_bias}
                  min={0}
                  max={5}
                  step={0.1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ Bias_Parameters_n_feats_define_bias: changeEvent.target.value })} />
              </Col>
            </Row>
            <br />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }
}

export default Index;
