import React, { useState } from 'react'
import { Container, ProgressBar,Table } from 'react-bootstrap'
import {
    Header
  } from 'components';


const Progress = () => {

  const [stage1, setStage1]= useState([{key:1, progress:"20"},{key:2, progress:"40"},{key:3, progress:"100"},{key:4, progress:"30"},{key:5, progress:"70"},{key:6, progress:"90"}])
    const [finished,setFinished] = useState(true)


  return (
    <Container className="main">
      <Header title="Progress" />
      <br />

      <Table striped bordered>
        <thead>
          <tr>
            <th>Stage 1</th>
            <th>Stage 2</th>
            <th>Stage 3</th>
            <th>Stage 4</th>
            <th>Stage 5</th>
            <th>Stage 6</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {stage1.map((i)=>{
                return(
                    <td> <ProgressBar variant="success" className='progressbar' animated={finished} now={i.progress} /> <span>Something a text about</span></td>
                )
            })}

          </tr>
        </tbody>
      </Table>
    </Container>
  )
}

export default Progress

// import React, { useState } from 'react'
// import { Container, ProgressBar,Table } from 'react-bootstrap'
// import {
//     Header, Footer
//   } from '../../components';
// import "./style.css"


// const Progress = () => {
//   const [stage1, setStage1]= useState("100")
//   const [stage2, setStage2]= useState("90")
//   const [stage3, setStage3]= useState("70")
//   const [stage4, setStage4]= useState("20")
//   const [stage5, setStage5]= useState("100")
//   const [stage6, setStage6]= useState("50")
//   const [success, setSuccess] = useState("success")
//   const [danger, setDanger] = useState("danger")


//   return (
//     <Container className="main">
//             <Header title="Progress" />

//     <Table striped bordered>
//   <thead>
//     <tr>
//       <th>#</th>
//       <th>Stage 1</th>
//       <th>Stage 2</th>
//       <th>Stage 3</th>
//       <th>Stage 4</th>
//       <th>Stage 5</th>

//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <td>1</td>
//       <td><ProgressBar variant={success} className='hello' animated now={stage1}  /><span>Something a text about</span></td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage2} /> <span>Something a text about</span></td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage3} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage4} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage5} /> <span>Something a text about</span> </td>

//     </tr>
//     <tr>
//       <td>2 </td>
//       <td><ProgressBar variant={success} className='hello' animated now={stage1} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage2} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage3} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage4} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage5} /> <span>Something a text about</span> </td>

//     </tr>
//     <tr>
//       <td>3</td>
//       <td><ProgressBar variant={success} className='hello' animated now={stage1} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage2} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage3} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage4} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage5} /> <span>Something a text about</span> </td>

//     </tr>
//     <tr>
//       <td>4</td>
//       <td><ProgressBar variant={success} className='hello' animated now={stage1} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage2} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage3} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage4} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage5} /> <span>Something a text about</span> </td>

//     </tr>
//     <tr>
//       <td>5</td>
//       <td><ProgressBar variant={success} className='hello' animated now={stage1} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage2} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage3} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage4} /> <span>Something a text about</span> </td>
//       <td>  <ProgressBar variant={success} className='hello' animated now={stage5} /> <span>Something a text about</span> </td>
//     </tr>
//   </tbody>
// </Table>
//     </Container>
//   )
// }

// export default Progress
