import React from "react";
import { Link } from "react-router-dom";

import logo from "../../images/logo.png";
import "./Auth.scss";

const Auth = (props) => {
  return (
    <div className="auth__body-container">
      <div className="auth__header">
        <div className="auth__header__logo">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
            />
          </Link>
        </div>
      </div>
      <div className="auth__body">
        <div className="auth__body__left">
          <div className="auth__body__left__media">
            <img src={props.img} alt="gif" />
          </div>
        </div>
        <div className="auth__body__right">
          <div className="auth__body__right__header">
            <h2 title={props.title}>{props.title}</h2>
            <span>
              <Link to={props.switchLink}>{props.switchText}</Link>
            </span>
          </div>
          {props.children}
          {props.footerLink && props.footerText && (
            <div className="auth__footer">
              <Link to={props.footerLink}>{props.footerText}</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Auth;
