import React, { useEffect, useState } from "react";
import Adminprofile from "./Adminprofile";
import { auth } from "services";
// import license from "../../license";

const Profile = () => {
  const [admin, setAdmin] = useState([]);

  // checking Adminrole user's username and current username are equal
  useEffect(() => {
    auth.checkIfSuperAdmin().then((users) => {
      if (users != null) {
        var myUsers = [];
        users.forEach((user) => {
          myUsers.push(user.get("email"));
        });
        setAdmin(myUsers);
      }
    });
  }, []);

  return (
    <>
      <Adminprofile admin={admin} />
    </>
  );
};

export default Profile;
