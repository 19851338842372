import React from 'react';
import { Row, Col, Form, Accordion, Card, Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
// import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Master Config
      config_master: {},
      dataset_path_data_path: '',
      target_column_target_column_name: '',
      drop_columns_drop_column_list: '',
      impute_numeric_columns_numeric_column_list: '',
      impute_categorical_columns_categorical_column_list: '',
      bias_variables_bias_list: '',
      FLAGS_execute_preprocessing: '',
      FLAGS_execute_modelling: '',
      FLAGS_execute_global_explainability: '',
      FLAGS_execute_local_explainability: '',
      FLAGS_execute_fair_ml: '',
      local_explainability_local_explainability_index: '',
    }
  }

  componentDidMount() {
    //Master Config
    fetch("http://localhost:3030" + "/config/regression/master")
      .then(response => response.json())
      .then(data => {
        this.setState({ config_master: data._sections });
        const { config_master } = this.state
        console.log('Master Config');
        console.log(config_master);
        this.setState({ dataset_path_data_path: config_master.dataset_path.data_path })
        this.setState({ target_column_target_column_name: config_master.target_column.target_column_name })
        this.setState({ drop_columns_drop_column_list: config_master.drop_columns.drop_column_list })
        this.setState({ impute_numeric_columns_numeric_column_list: config_master.impute_numeric_columns.numeric_column_list })
        this.setState({ impute_categorical_columns_categorical_column_list: config_master.impute_categorical_columns.categorical_column_list })
        this.setState({ bias_variables_bias_list: config_master.bias_variables.bias_list })
        //console.log(this.state.bias_variables_bias_list)
        this.setState({ FLAGS_execute_preprocessing: config_master.FLAGS['execute.preprocessing'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_modelling: config_master.FLAGS['execute.modelling'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_global_explainability: config_master.FLAGS['execute.global_explainability'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_local_explainability: config_master.FLAGS['execute.local_explainability'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_fair_ml: config_master.FLAGS['execute.fair_ml'] === 'True' ? true : false })
        this.setState({ local_explainability_local_explainability_index: config_master.local_explainability.local_explainability_index })
      })
  }

  handleClick = () => {
    //Master Config
    const { config_master, dataset_path_data_path, target_column_target_column_name, drop_columns_drop_column_list, impute_numeric_columns_numeric_column_list, impute_categorical_columns_categorical_column_list, bias_variables_bias_list, FLAGS_execute_preprocessing, FLAGS_execute_modelling, FLAGS_execute_global_explainability, FLAGS_execute_local_explainability, FLAGS_execute_fair_ml, local_explainability_local_explainability_index } = this.state

    config_master.dataset_path.data_path = dataset_path_data_path
    config_master.target_column.target_column_name = target_column_target_column_name
    config_master.drop_columns.drop_column_list = drop_columns_drop_column_list
    config_master.impute_numeric_columns.numeric_column_list = impute_numeric_columns_numeric_column_list
    config_master.impute_categorical_columns.categorical_column_list = impute_categorical_columns_categorical_column_list
    config_master.bias_variables.bias_list = bias_variables_bias_list
    config_master.FLAGS['execute.preprocessing'] = FLAGS_execute_preprocessing === true ? 'True' : 'False'
    config_master.FLAGS['execute.modelling'] = FLAGS_execute_modelling === true ? 'True' : 'False'
    config_master.FLAGS['execute.global_explainability'] = FLAGS_execute_global_explainability === true ? 'True' : 'False'
    config_master.FLAGS['execute.local_explainability'] = FLAGS_execute_local_explainability === true ? 'True' : 'False'
    config_master.FLAGS['execute.fair_ml'] = FLAGS_execute_fair_ml === true ? 'True' : 'False'
    config_master.local_explainability.local_explainability_index = local_explainability_local_explainability_index

    fetch("http://localhost:3030" + '/config/regression/master', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config_master)
    }).then(() => {
      console.log('Master Config Request posted')
    })
  }

  render() {
    //Master Config
    const { dataset_path_data_path, target_column_target_column_name, drop_columns_drop_column_list, impute_numeric_columns_numeric_column_list, impute_categorical_columns_categorical_column_list, bias_variables_bias_list, FLAGS_execute_preprocessing, FLAGS_execute_modelling, FLAGS_execute_global_explainability, FLAGS_execute_local_explainability, FLAGS_execute_fair_ml, local_explainability_local_explainability_index } = this.state

    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1" block>
            <Row>
              <Col md={8} align="left">
                Master Config
              </Col>
              <Col md={4} align="right">
                {this.props.isActive ? <FontAwesomeIcon icon={faArrowCircleUp} /> : <FontAwesomeIcon icon={faArrowCircleDown} />}
              </Col>
            </Row>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <h5>[dataset_path]</h5>
            <Col md={4}>
              <Form.Label>data_path</Form.Label>
              <Form.Control size='sm' defaultValue={dataset_path_data_path} onChange={e => this.setState({ dataset_path_data_path: e.target.value })} />
            </Col>
            <br />
            <h5>[target_column]</h5>
            <Col md={4}>
              <Form.Label>target_column_name</Form.Label>
              <Form.Control size='sm' defaultValue={target_column_target_column_name} onChange={e => this.setState({ target_column_target_column_name: e.target.value })} />
            </Col>
            <br />
            {/*
            <h5>[drop_columns]</h5>
            <Col md={8}>
              <Form.Label>drop_column_list</Form.Label>
              <Form.Control size='sm' defaultValue={drop_columns_drop_column_list} onChange={e => this.setState({drop_columns_drop_column_list: e.target.value})}/>
            </Col>
            <br />
            <h5>[impute_numeric_columns]</h5>
            <Col md={8}>
              <Form.Label>numeric_column_list</Form.Label>
              <Form.Control size='sm' defaultValue={impute_numeric_columns_numeric_column_list} onChange={e => this.setState({impute_numeric_columns_numeric_column_list: e.target.value})}/>
            </Col>
            <br />
            <h5>[impute_categorical_columns]</h5>
            <Col md={8}>
              <Form.Label>categorical_column_list</Form.Label>
              <Form.Control size='sm' defaultValue={impute_categorical_columns_categorical_column_list} onChange={e => this.setState({impute_categorical_columns_categorical_column_list: e.target.value})}/>
            </Col>
            <br />
            <h5>[bias_variables]</h5>
            <Col md={8}>
              <Form.Label>bias_list</Form.Label>
              <Form.Control size='sm' defaultValue={bias_variables_bias_list} onChange={e => this.setState({bias_variables_bias_list: e.target.value})}/>
            </Col>
            */}
            <br />
            <h5>[FLAGS]</h5>
            <Form.Check inline label="execute.preprocessing" type={'switch'} id={'check1'} checked={FLAGS_execute_preprocessing} onChange={changeEvent => this.setState({ FLAGS_execute_preprocessing: changeEvent.target.checked })} />
            <Form.Check inline label="execute.modelling" type={'switch'} id={'check2'} checked={FLAGS_execute_modelling} onChange={changeEvent => this.setState({ FLAGS_execute_modelling: changeEvent.target.checked })} />
            <Form.Check inline label="execute.global_explainability" type={'switch'} id={'check4'} checked={FLAGS_execute_global_explainability} onChange={changeEvent => this.setState({ FLAGS_execute_global_explainability: changeEvent.target.checked })} />
            <Form.Check inline label="execute.local_explainability" type={'switch'} id={'check7'} checked={FLAGS_execute_local_explainability} onChange={changeEvent => this.setState({ FLAGS_execute_local_explainability: changeEvent.target.checked })} />
            <Form.Check inline label="execute.fair_ml" type={'switch'} id={'check11'} checked={FLAGS_execute_fair_ml} onChange={changeEvent => this.setState({ FLAGS_execute_fair_ml: changeEvent.target.checked })} />
            {/*
            <br /><br />
            <h5>[local_explainability]</h5>
            <Col md={8}>
              <Form.Label>local_explainability_index</Form.Label>
              <Form.Control size='sm' defaultValue={local_explainability_local_explainability_index} onChange={e => this.setState({local_explainability_local_explainability_index: e.target.value})}/>
            </Col>
            */}
            <br />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }
}

export default Index;
