import React, { Suspense, lazy, useEffect } from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { I18nextProvider } from "react-i18next";

import { Login, Signup, Forget, Profile } from "./views/Auth";

import Progress from "./views/Config/Progress";
import Config from "./views/Config";
import Test from "Test";

import i18n from "./i18n";

import { auth } from "services";
import { Loader } from "components";

// import {
//   ImagePipeline,
//   Structured,
//   Regression,
//   TextPipeline,
//   AudioPipeline,
//   TimeSeriesPipeline,
//   ODPipeline,
//   Segmentation,
//   Recommendation,
// } from "./views/Report";

const ImagePipeline = lazy(() => import("./views/Report/ImagePipeline"));
const Structured = lazy(() => import("./views/Report/Structured"));
const Regression = lazy(() => import("./views/Report/Regression"));
const TextPipeline = lazy(() => import("./views/Report/TextPipeline"));
const AudioPipeline = lazy(() => import("./views/Report/AudioPipeline"));
const TimeSeriesPipeline = lazy(() =>
  import("./views/Report/TimeseriesPipeline")
);
const ODPipeline = lazy(() => import("./views/Report/ODPipeline"));
const Segmentation = lazy(() => import("./views/Report/Segmentation"));
const Recommendation = lazy(() => import("./views/Report/Recommendation"));

var license = require("./license.json");
var currentUser = auth.getCurrentUser();

const App = (props) => {
  useEffect(() => {
    if (currentUser) {
      if (currentUser.get("Themecolor")) {
        document.documentElement.style.setProperty(
          "--text",
          currentUser.get("Textcolor")
        );
        document.documentElement.style.setProperty(
          "--bgColor",
          currentUser.get("BGcolor")
        );

        document.documentElement.style.setProperty(
          "--content",
          currentUser.get("Themecolor")
        );
        localStorage.setItem("themecolor", currentUser.get("Themecolor"));
        localStorage.setItem("textcolor", currentUser.get("Textcolor"));
        localStorage.setItem("bgcolor", currentUser.get("BGcolor"));
      } else {
        document.documentElement.style.setProperty("--text", "#fff");
        document.documentElement.style.setProperty("--bgColor", "#000");

        document.documentElement.style.setProperty("--content", "#a03c64");

        localStorage.setItem("themecolor", "#a03c64");
        localStorage.setItem("textcolor", "#fff");
        localStorage.setItem("bgcolor", "#000");
      }
    } else {
      document.documentElement.style.setProperty("--text", "#fff");
      document.documentElement.style.setProperty("--bgColor", "#000");

      document.documentElement.style.setProperty("--content", "#a03c64");

      localStorage.setItem("themecolor", "#a03c64");
      localStorage.setItem("textcolor", "#fff");
      localStorage.setItem("bgcolor", "#000");
    }
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {license.structured ? (
              <PrivateRoute exact path="/structured">
                <Structured />
              </PrivateRoute>
            ) : null}
            {license.structured ? (
              <PrivateRoute exact path="/structured">
                <Structured />
              </PrivateRoute>
            ) : null}
            {license.regression ? (
              <PrivateRoute exact path="/regression">
                <Regression />
              </PrivateRoute>
            ) : null}
            {license.image ? (
              <PrivateRoute exact path="/image">
                <ImagePipeline />
              </PrivateRoute>
            ) : null}
            {license.text ? (
              <PrivateRoute exact path="/text">
                <TextPipeline />
              </PrivateRoute>
            ) : null}
            {license.audio ? (
              <PrivateRoute exact path="/audio">
                <AudioPipeline />
              </PrivateRoute>
            ) : null}
            {license.timeseries ? (
              <PrivateRoute exact path="/timeseries">
                <TimeSeriesPipeline />
              </PrivateRoute>
            ) : null}
            {license.od ? (
              <PrivateRoute exact path="/">
                <ODPipeline />
              </PrivateRoute>
            ) : null}
            {license.segmentation ? (
              <PrivateRoute exact path="/segmentation">
                <Segmentation />
              </PrivateRoute>
            ) : null}
            {license.recommendation ? (
              <PrivateRoute exact path="/recommendation">
                <Recommendation />
              </PrivateRoute>
            ) : null}
            <Route exact path="/test">
              <Test />
            </Route>
            <Route exact path="/auth/login">
              <Login />
            </Route>
            <Route exact path="/auth/signup">
              <Signup />
            </Route>
            <Route exact path="/config">
              <Config />
            </Route>
            <Route exact path="/progress">
              <Progress />
            </Route>
            <Route exact path="/auth/forgot-password">
              <Forget />
            </Route>
            <PrivateRoute exact path="/auth/profile">
              <Profile />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </I18nextProvider>
  );
};
export default App;

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return currentUser != null ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
