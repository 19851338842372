import React from 'react';
import { Row, Col, Form, Accordion, Card, Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Master Config
      config_master: {},
      dataset_path_audio_path: '',
      dataset_path_audio_path_train: '',
      dataset_path_audio_path_test: '',
      image_dims_num_rows: '',
      model_params_num_epochs: '',
      model_params_num_batch_size: '',
      FLAGS_execute_preprocessing: '',
      FLAGS_execute_modelling: '',
      FLAGS_execute_localvisualization: '',
      FLAGS_execute_globalvisualization: '',
      plots_class_distribution_plot: '',
      plots_melspectrogram_plot: '',
      plots_test_train_acc_plot: '',
      plots_test_train_loss_plot: '',
    }
  }

  componentDidMount() {
    //Master Config
    fetch( + "/config/audio/master")
      .then(response => response.json())
      .then(data => {
        this.setState({ config_master: data._sections });
        const { config_master } = this.state
        console.log('Master Config');
        console.log(config_master);
        this.setState({ dataset_path_audio_path: config_master.dataset_path.audio_path })
        this.setState({ dataset_path_audio_path_train: config_master.dataset_path.audio_path_train })
        this.setState({ dataset_path_audio_path_test: config_master.dataset_path.audio_path_test })
        this.setState({ image_dims_num_rows: config_master.image_dims.num_rows })
        this.setState({ model_params_num_epochs: config_master.model_params.num_epochs })
        this.setState({ model_params_num_batch_size: config_master.model_params.num_batch_size })
        this.setState({ FLAGS_execute_preprocessing: config_master.FLAGS['execute.preprocessing'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_modelling: config_master.FLAGS['execute.modelling'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_localvisualization: config_master.FLAGS['execute.localvisualization'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_globalvisualization: config_master.FLAGS['execute.globalvisualization'] === 'True' ? true : false })
        this.setState({ plots_class_distribution_plot: config_master.plots.class_distribution_plot === 'True' ? true : false })
        this.setState({ plots_melspectrogram_plot: config_master.plots.melspectrogram_plot === 'True' ? true : false })
        this.setState({ plots_test_train_acc_plot: config_master.plots.test_train_acc_plot === 'True' ? true : false })
        this.setState({ plots_test_train_loss_plot: config_master.plots.test_train_loss_plot === 'True' ? true : false })
      })
  }

  handleClick = () => {
    //Master Config
    const { config_master, dataset_path_audio_path, dataset_path_audio_path_train, dataset_path_audio_path_test, image_dims_num_rows, model_params_num_epochs, model_params_num_batch_size, FLAGS_execute_preprocessing, FLAGS_execute_modelling, FLAGS_execute_localvisualization, FLAGS_execute_globalvisualization, plots_class_distribution_plot, plots_melspectrogram_plot, plots_test_train_acc_plot, plots_test_train_loss_plot } = this.state

    config_master.dataset_path.audio_path = dataset_path_audio_path
    config_master.dataset_path.audio_path_train = dataset_path_audio_path_train
    config_master.dataset_path.audio_path_test = dataset_path_audio_path_test
    config_master.image_dims.num_rows = image_dims_num_rows
    config_master.model_params.num_epochs = model_params_num_epochs
    config_master.model_params.num_batch_size = model_params_num_batch_size
    config_master.FLAGS['execute.preprocessing'] = FLAGS_execute_preprocessing === true ? 'True' : 'False'
    config_master.FLAGS['execute.modelling'] = FLAGS_execute_modelling === true ? 'True' : 'False'
    config_master.FLAGS['execute.localvisualization'] = FLAGS_execute_localvisualization === true ? 'True' : 'False'
    config_master.FLAGS['execute.globalvisualization'] = FLAGS_execute_globalvisualization === true ? 'True' : 'False'
    config_master.plots.class_distribution_plot = plots_class_distribution_plot === true ? 'True' : 'False'
    config_master.plots.melspectrogram_plot = plots_melspectrogram_plot === true ? 'True' : 'False'
    config_master.plots.test_train_acc_plot = plots_test_train_acc_plot === true ? 'True' : 'False'
    config_master.plots.test_train_loss_plot = plots_test_train_loss_plot === true ? 'True' : 'False'

    fetch( + '/config/audio/master', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config_master)
    }).then(() => {
      console.log('Master Config Request posted')
    })
  }

  render() {
    //Master Config
    const { dataset_path_audio_path, dataset_path_audio_path_train, dataset_path_audio_path_test, image_dims_num_rows, model_params_num_epochs, model_params_num_batch_size, FLAGS_execute_preprocessing, FLAGS_execute_modelling, FLAGS_execute_localvisualization, FLAGS_execute_globalvisualization, plots_class_distribution_plot, plots_melspectrogram_plot, plots_test_train_acc_plot, plots_test_train_loss_plot } = this.state

    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1" block>
            <Row>
              <Col md={8} align="left">
                Master Config
              </Col>
              <Col md={4} align="right">
                {this.props.isActive ? <FontAwesomeIcon icon={faArrowCircleUp} /> : <FontAwesomeIcon icon={faArrowCircleDown} />}
              </Col>
            </Row>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <h5>[dataset_path]</h5>
            <Row>
              <Col md={4}>
                <Form.Label>audio_path</Form.Label>
                <Form.Control size='sm' defaultValue={dataset_path_audio_path} onChange={e => this.setState({ dataset_path_audio_path: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>audio_path_train</Form.Label>
                <Form.Control size='sm' defaultValue={dataset_path_audio_path_train} onChange={e => this.setState({ dataset_path_audio_path_train: e.target.value })} />
              </Col>
              <Col md={4}>
                <Form.Label>audio_path_test</Form.Label>
                <Form.Control size='sm' defaultValue={dataset_path_audio_path_test} onChange={e => this.setState({ dataset_path_audio_path_test: e.target.value })} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={6}>
                <h5>[image_dims]</h5>
                num_rows
                <RangeSlider
                  value={image_dims_num_rows}
                  min={0}
                  max={100}
                  step={1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ image_dims_num_rows: changeEvent.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[model_params]</h5>
            <Row>
              <Col md={6}>
                num_epochs
                <RangeSlider
                  value={model_params_num_epochs}
                  min={0}
                  max={100}
                  step={1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ model_params_num_epochs: changeEvent.target.value })} />
              </Col>
              <Col md={6}>
                num_batch_size
                <RangeSlider
                  value={model_params_num_batch_size}
                  min={0}
                  max={500}
                  step={2}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ model_params_num_batch_size: changeEvent.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[FLAGS]</h5>
            <Form.Check inline label="execute.preprocessing" type={'switch'} id={'check1'} checked={FLAGS_execute_preprocessing} onChange={changeEvent => this.setState({ FLAGS_execute_preprocessing: changeEvent.target.checked })} />
            <Form.Check inline label="execute.modelling" type={'switch'} id={'check2'} checked={FLAGS_execute_modelling} onChange={changeEvent => this.setState({ FLAGS_execute_modelling: changeEvent.target.checked })} />
            <Form.Check inline label="execute.localvisualization" type={'switch'} id={'check3'} checked={FLAGS_execute_localvisualization} onChange={changeEvent => this.setState({ FLAGS_execute_localvisualization: changeEvent.target.checked })} />
            <Form.Check inline label="execute.globalvisualization" type={'switch'} id={'check4'} checked={FLAGS_execute_globalvisualization} onChange={changeEvent => this.setState({ FLAGS_execute_globalvisualization: changeEvent.target.checked })} />
            <br /><br />
            <h5>[plots]</h5>
            <Form.Check inline label="class_distribution_plot" type={'switch'} id={'check5'} checked={plots_class_distribution_plot} onChange={changeEvent => this.setState({ plots_class_distribution_plot: changeEvent.target.checked })} />
            <Form.Check inline label="melspectrogram_plot" type={'switch'} id={'check6'} checked={plots_melspectrogram_plot} onChange={changeEvent => this.setState({ plots_melspectrogram_plot: changeEvent.target.checked })} />
            <Form.Check inline label="test_train_acc_plot" type={'switch'} id={'check7'} checked={plots_test_train_acc_plot} onChange={changeEvent => this.setState({ plots_test_train_acc_plot: changeEvent.target.checked })} />
            <Form.Check inline label="test_train_loss_plot" type={'switch'} id={'check8'} checked={plots_test_train_loss_plot} onChange={changeEvent => this.setState({ plots_test_train_loss_plot: changeEvent.target.checked })} />
            <br />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }
}

export default Index;
