import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Row, Col, Form, Accordion, Card, Button } from "react-bootstrap";
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import RangeSlider from "react-bootstrap-range-slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";

const Index = forwardRef((props, ref) => {
  const [configLoading, setConfigLoading] = useState({});
  const [pathInputPath, setPathInputPath] = useState("");
  const [removeDuplicateSetExecution, setRemoveDuplicateSetExecution] =
    useState(false);
  const [removeOutlierSetExecution, setRemoveOutlierSetExecution] =
    useState(false);
  const [removeOutlierThreshold, setRemoveOutlierThreshold] = useState(0);
  const [loadingDataSetExecution, setLoadingDataSetExecution] = useState(false);
  const [loadingDataImageHeight, setLoadingDataImageHeight] = useState(0);
  const [loadingDataImageWidth, setLoadingDataImageWidth] = useState(0);
  const [loadingDataTestSize, setLoadingDataTestSize] = useState(0);
  useEffect(() => {
    fetch("http://localhost:3030" + "/config/image/loading")
      .then((response) => response.json())
      .then((data) => {
        const responseData = data._sections;
        setConfigLoading(responseData);
        setPathInputPath(responseData.path.input_path);
        setRemoveDuplicateSetExecution(
          responseData.remove_duplicate.set_execution === "True" ? true : false
        );
        setRemoveOutlierSetExecution(
          responseData.remove_outlier.set_execution === "True" ? true : false
        );
        setRemoveOutlierThreshold(responseData.remove_outlier.threshold);
        setLoadingDataSetExecution(
          responseData.loading_data.set_execution === "True" ? true : false
        );
        setLoadingDataImageHeight(responseData.loading_data.image_height);
        setLoadingDataImageWidth(responseData.loading_data.image_width);
        setLoadingDataTestSize(responseData.loading_data.test_size);
      });
  }, []);
  useImperativeHandle(ref, () => ({
    handleClick() {
      handleClick();
    },
  }));

  const handleClick = () => {
    //Loading Config

    setConfigLoading((prev) => {
      let tempVariable = { ...prev };
      tempVariable.path.input_path = pathInputPath;
      tempVariable.remove_duplicate.set_execution =
        removeDuplicateSetExecution === true ? "True" : "False";

      tempVariable.remove_outlier.set_execution =
        removeOutlierSetExecution === true ? "True" : "False";

      tempVariable.remove_outlier.threshold = removeOutlierThreshold;

      tempVariable.loading_data.set_execution =
        loadingDataSetExecution === true ? "True" : "False";

      tempVariable.loading_data.image_height = loadingDataImageHeight;

      tempVariable.loading_data.image_width = loadingDataImageWidth;

      tempVariable.loading_data.test_size = loadingDataTestSize;
      return tempVariable;
    });
    fetch("http://localhost:3030" + "/config/image/loading", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(configLoading),
    }).then(() => {
      console.log("Loading Request posted");
    });
  };
  return (
    <div>
      <h5>[path]</h5>
      <Row>
        <Col md={4}>
          {/*<Form.File id="inputPath" label="Input Path" onChange={(e) => this.setState({path_input_path: './Dataset/'+e.target.files[0].name})}/>*/}
          <Form.Label>input_path</Form.Label>
          <Form.Control
            size="sm"
            defaultValue={pathInputPath}
            onChange={(e) => setPathInputPath(e.target.value)}
          />
        </Col>
      </Row>
      <br />
      <h5>[remove_duplicate]</h5>
      <Row>
        <Col md={6}>
          <Form.Check
            inline
            label="Set Execution"
            type={"switch"}
            id={"check17"}
            checked={removeDuplicateSetExecution}
            onChange={(e) => setRemoveDuplicateSetExecution(e.target.checked)}
          />
        </Col>
        <Col md={6}></Col>
      </Row>
      <br />
      <h5>[remove_outlier]</h5>
      <Row>
        <Col md={6}>
          <Form.Check
            inline
            label="Set Execution"
            type={"switch"}
            id={"check18"}
            checked={removeOutlierSetExecution}
            onChange={(e) => setRemoveOutlierSetExecution(e.target.checked)}
          />
        </Col>
        <Col md={6}>
          threshold
          <RangeSlider
            value={removeOutlierThreshold}
            min={0}
            max={1}
            step={0.1}
            tooltip={"on"}
            tooltipPlacement={"bottom"}
            onChange={(e) => setRemoveOutlierThreshold(e.target.value)}
          />
        </Col>
      </Row>
      <h5>[loading_data]</h5>
      <Row>
        <Col md={3}>
          <Form.Check
            inline
            label="Set Execution"
            type={"switch"}
            id={"check19"}
            checked={loadingDataSetExecution}
            onChange={(e) => setLoadingDataSetExecution(e.target.checked)}
          />
        </Col>
        <Col md={3}>
          image_height
          <RangeSlider
            value={loadingDataImageHeight}
            min={32}
            max={128}
            step={2}
            tooltip={"on"}
            tooltipPlacement={"bottom"}
            onChange={(e) => setLoadingDataImageHeight(e.target.value)}
          />
        </Col>
        <Col md={3}>
          image_width
          <RangeSlider
            value={loadingDataImageWidth}
            min={32}
            max={128}
            step={2}
            tooltip={"on"}
            tooltipPlacement={"bottom"}
            onChange={(e) => setLoadingDataImageWidth(e.target.value)}
          />
        </Col>
        <Col md={3}>
          test_size
          <RangeSlider
            value={loadingDataTestSize}
            min={0}
            max={1}
            step={0.1}
            tooltip={"on"}
            tooltipPlacement={"bottom"}
            onChange={(e) => setLoadingDataTestSize(e.target.value)}
          />
        </Col>
      </Row>
      <br />
    </div>
  );
});
export default Index;
