import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Row, Col, Form, Accordion, Card, Button } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import RangeSlider from "react-bootstrap-range-slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";
const Index = forwardRef((props, ref) => {
  const [config, setConfig] = useState({});
  const [partTwoShear, setPartTwoShear] = useState(false);
  const [partTwoRotation, setPartTwoRotation] = useState(false);
  const [partTwoTranslationX, setPartTwoTranslationX] = useState(false);
  const [partTwoTranslationY, setPartTwoTranslationY] = useState(false);
  const [partTwoBrightness, setPartTwoBrightness] = useState(false);
  const [partTwoContrast, setPartTwoContrast] = useState(false);
  const [partTwoZoomXY, setPartTwoZoomXY] = useState(false);
  const [partTwoBlurAvg, setPartTwoBlurAvg] = useState(false);
  const [partTwoTxTy, setPartTwoTxTy] = useState(false);
  const [
    partTwoIndividualTransformations,
    setPartTwoIndividualTransformations,
  ] = useState(false);
  const [partThreeDeviations, setPartThreeDeviations] = useState(false);
  const [modelDimensionsImageHeight, setModelDimensionsImageHeight] =
    useState(0);
  const [modelDimensionsImageWidth, setModelDimensionsImageWidth] = useState(0);
  useEffect(() => {
    fetch("http://localhost:3030/config/image/meta")
      .then((response) => response.json())
      .then((data) => {
        const responseData = data._sections;
        setConfig(responseData);
        setPartTwoShear(
          responseData["PART TWO"]["flags.shear"] === "True" ? true : false
        );
        setPartTwoRotation(
          responseData["PART TWO"]["flags.rotation"] === "True" ? true : false
        );
        setPartTwoTranslationX(
          responseData["PART TWO"]["flags.translation_x"] === "True"
            ? true
            : false
        );
        setPartTwoTranslationY(
          responseData["PART TWO"]["flags.translation_y"] === "True"
            ? true
            : false
        );
        setPartTwoBrightness(
          responseData["PART TWO"]["flags.brightness"] === "True" ? true : false
        );
        setPartTwoContrast(
          responseData["PART TWO"]["flags.contrast"] === "True" ? true : false
        );
        setPartTwoZoomXY(
          responseData["PART TWO"]["flags.zoom_xy"] === "True" ? true : false
        );
        setPartTwoBlurAvg(
          responseData["PART TWO"]["flags.blur_avg"] === "True" ? true : false
        );
        setPartTwoTxTy(
          responseData["PART TWO"]["flags.txty"] === "True" ? true : false
        );
        setPartTwoIndividualTransformations(
          responseData["PART TWO"]["flags.individual_transformations"] ===
            "True"
            ? true
            : false
        );
        setPartThreeDeviations(
          responseData["PART THREE"][
            "execute.part.three.accuracy.consolidation.deviations"
          ] === "True"
            ? true
            : false
        );
        let temp = responseData["MODEL"]["img.dimensions"].split(",");
        setModelDimensionsImageHeight(parseInt(temp[0]));
        setModelDimensionsImageWidth(parseInt(temp[1]));
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, []);
  useImperativeHandle(ref, () => ({
    handleClick() {
      handleClick();
    },
  }));
  const handleClick = () => {
    setConfig((prev) => {
      let tempVarialbe = { ...prev };

      tempVarialbe["PART TWO"]["flags.shear"] =
        partTwoShear === true ? "True" : "False";

      tempVarialbe["PART TWO"]["flags.rotation"] =
        partTwoRotation === true ? "True" : "False";

      tempVarialbe["PART TWO"]["flags.translation_x"] =
        partTwoTranslationX === true ? "True" : "False";

      tempVarialbe["PART TWO"]["flags.translation_y"] =
        partTwoTranslationY === true ? "True" : "False";

      tempVarialbe["PART TWO"]["flags.brightness"] =
        partTwoBrightness === true ? "True" : "False";

      tempVarialbe["PART TWO"]["flags.contrast"] =
        partTwoContrast === true ? "True" : "False";

      tempVarialbe["PART TWO"]["flags.zoom_xy"] =
        partTwoZoomXY === true ? "True" : "False";

      tempVarialbe["PART TWO"]["flags.blur_avg"] =
        partTwoBlurAvg === true ? "True" : "False";

      tempVarialbe["PART TWO"]["flags.txty"] =
        partTwoTxTy === true ? "True" : "False";

      tempVarialbe["PART TWO"]["flags.individual_transformations"] =
        partTwoIndividualTransformations === true ? "True" : "False";

      tempVarialbe["PART THREE"][
        "execute.part.three.accuracy.consolidation.deviations"
      ] = partThreeDeviations === true ? "True" : "False";

      tempVarialbe["MODEL"]["img.dimensions"] =
        modelDimensionsImageHeight + "," + modelDimensionsImageWidth;

      return tempVarialbe;
    });
    fetch("http://localhost:3030" + "/config/image/meta", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config),
    }).then(() => {
      console.log("Metamorphic Testing Request posted");
    });
  };
  return (
    <div>
      <h5>[PART TWO]</h5>
      <Form>
        <Form.Check
          inline
          label="Shear"
          type={"switch"}
          id={"check22"}
          checked={partTwoShear}
          onChange={(e) => setPartTwoShear(e.target.checked)}
        />
        <Form.Check
          inline
          label="Rotation"
          type={"switch"}
          id={"check23"}
          checked={partTwoRotation}
          onChange={(e) => setPartTwoRotation(e.target.checked)}
        />
        <Form.Check
          inline
          label="Translation_x"
          type={"switch"}
          id={"check24"}
          checked={partTwoTranslationX}
          onChange={(e) => setPartTwoTranslationX(e.target.checked)}
        />
        <Form.Check
          inline
          label="Translation_y"
          type={"switch"}
          id={"check25"}
          checked={partTwoTranslationY}
          onChange={(e) => setPartTwoTranslationY(e.target.checked)}
        />
        <Form.Check
          inline
          label="Brightness"
          type={"switch"}
          id={"check26"}
          checked={partTwoBrightness}
          onChange={(e) => setPartTwoBrightness(e.target.checked)}
        />
        <Form.Check
          inline
          label="Contrast"
          type={"switch"}
          id={"check27"}
          checked={partTwoContrast}
          onChange={(e) => setPartTwoContrast(e.target.checked)}
        />
        <Form.Check
          inline
          label="Zoom_xy"
          type={"switch"}
          id={"check28"}
          checked={partTwoZoomXY}
          onChange={(e) => setPartTwoZoomXY(e.target.checked)}
        />
        <Form.Check
          inline
          label="Blur_avg"
          type={"switch"}
          id={"check29"}
          checked={partTwoBlurAvg}
          onChange={(e) => setPartTwoBlurAvg(e.target.checked)}
        />
        <Form.Check
          inline
          label="txty"
          type={"switch"}
          id={"check30"}
          checked={partTwoTxTy}
          onChange={(e) => setPartTwoTxTy(e.target.checked)}
        />
        <Form.Check
          inline
          label="Individual_Transformations"
          type={"switch"}
          id={"check31"}
          checked={partTwoIndividualTransformations}
          onChange={(e) =>
            setPartTwoIndividualTransformations(e.target.checked)
          }
        />
        <br />
        <br />
        <h5>[PART THREE]</h5>
        <Form.Check
          inline
          label="execute.part.three.accuracy.consolidation.deviations"
          type={"switch"}
          id={"check32"}
          checked={partThreeDeviations}
          onChange={(e) => setPartThreeDeviations(e.target.checked)}
        />
        <br />
        <br />
        <h5>[MODEL]</h5>
        <Form.Label>img.dimensions</Form.Label>
        <br />
        <Row>
          <Col md={6}>
            Image height
            <RangeSlider
              value={modelDimensionsImageHeight}
              min={32}
              max={256}
              step={2}
              tooltip={"on"}
              onChange={(e) => setModelDimensionsImageHeight(e.target.value)}
            />
          </Col>
          <Col md={6}>
            Image width
            <RangeSlider
              value={modelDimensionsImageWidth}
              min={32}
              max={256}
              step={2}
              tooltip={"on"}
              onChange={(e) => setModelDimensionsImageWidth(e.target.value)}
            />
          </Col>
        </Row>
      </Form>
      <br />
    </div>
  );
});

export default Index;
