import React from 'react';
import { Row, Col, Form, Accordion, Card, Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Master Config
      config_master: {},
      dataset_path_data_path: '',
      target_column_target_column_name: '',
      drop_columns_drop_column_list: '',
      impute_numeric_columns_numeric_column_list: '',
      impute_categorical_columns_categorical_column_list: '',
      bias_variables_bias_list: '',
      synthetic_data_parameters_epochs: '',
      synthetic_data_parameters_samples: '',
      counterfactual_parameter_n_samples: '',
      FLAGS_execute_preprocessing: '',
      FLAGS_execute_modelling: '',
      FLAGS_execute_exploratory_data_analysis: '',
      FLAGS_execute_global_explainability: '',
      FLAGS_execute_global_kernel_explainability: '',
      FLAGS_execute_global_linear_explainability: '',
      FLAGS_execute_local_explainability: '',
      FLAGS_execute_local_kernel_explainability: '',
      FLAGS_execute_local_linear_explainability: '',
      FLAGS_execute_bias: '',
      FLAGS_execute_fair_ml: '',
      FLAGS_execute_art: '',
      FLAGS_execute_synthetic_data_generator: '',
      FLAGS_execute_counterfactual_generator: '',
      explainability_sub_flags_execute_shap_local: '',
      explainability_sub_flags_execute_shap_global: '',
      explainability_sub_flags_execute_pdp: '',
      explainability_sub_flags_execute_ice: '',
      explainability_sub_flags_execute_lime: '',
      explainability_sub_flags_execute_loco: '',
      explainability_sub_flags_execute_dts: '',
      explainability_sub_flags_execute_permutation_feature_importance: '',
      explainability_sub_flags_execute_sklearn_feature_importance: '',
      local_explainability_local_explainability_index: '',
    }
  }

  componentDidMount() {
    //Master Config
    fetch("http://localhost:3030" + "/config/structured/master")
      .then(response => response.json())
      .then(data => {
        this.setState({ config_master: data._sections });
        const { config_master } = this.state
        console.log('Master Config');
        console.log(config_master);
        this.setState({ dataset_path_data_path: config_master.dataset_path.data_path })
        this.setState({ target_column_target_column_name: config_master.target_column.target_column_name })
        this.setState({ drop_columns_drop_column_list: config_master.drop_columns.drop_column_list })
        this.setState({ impute_numeric_columns_numeric_column_list: config_master.impute_numeric_columns.numeric_column_list })
        this.setState({ impute_categorical_columns_categorical_column_list: config_master.impute_categorical_columns.categorical_column_list })
        this.setState({ bias_variables_bias_list: config_master.bias_variables.bias_list })
        //console.log(this.state.bias_variables_bias_list)
        this.setState({ synthetic_data_parameters_epochs: config_master.synthetic_data_parameters.epochs })
        this.setState({ synthetic_data_parameters_samples: config_master.synthetic_data_parameters.samples })
        this.setState({ counterfactual_parameter_n_samples: config_master.counterfactual_parameter.n_samples })
        this.setState({ FLAGS_execute_preprocessing: config_master.FLAGS['execute.preprocessing'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_modelling: config_master.FLAGS['execute.modelling'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_exploratory_data_analysis: config_master.FLAGS['execute.exploratory_data_analysis'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_global_explainability: config_master.FLAGS['execute.global_explainability'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_global_kernel_explainability: config_master.FLAGS['execute.global_kernel_explainability'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_global_linear_explainability: config_master.FLAGS['execute.global_linear_explainability'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_local_explainability: config_master.FLAGS['execute.local_explainability'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_local_kernel_explainability: config_master.FLAGS['execute.local_kernel_explainability'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_local_linear_explainability: config_master.FLAGS['execute.local_linear_explainability'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_bias: config_master.FLAGS['execute.bias'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_fair_ml: config_master.FLAGS['execute.fair_ml'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_art: config_master.FLAGS['execute.art'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_synthetic_data_generator: config_master.FLAGS['execute.synthetic_data_generator'] === 'True' ? true : false })
        this.setState({ FLAGS_execute_counterfactual_generator: config_master.FLAGS['execute.counterfactual_generator'] === 'True' ? true : false })
        this.setState({ explainability_sub_flags_execute_shap_local: config_master.explainability_sub_flags['execute.shap_local'] === 'True' ? true : false })
        this.setState({ explainability_sub_flags_execute_shap_global: config_master.explainability_sub_flags['execute.shap_global'] === 'True' ? true : false })
        this.setState({ explainability_sub_flags_execute_pdp: config_master.explainability_sub_flags['execute.pdp'] === 'True' ? true : false })
        this.setState({ explainability_sub_flags_execute_ice: config_master.explainability_sub_flags['execute.ice'] === 'True' ? true : false })
        this.setState({ explainability_sub_flags_execute_lime: config_master.explainability_sub_flags['execute.lime'] === 'True' ? true : false })
        this.setState({ explainability_sub_flags_execute_loco: config_master.explainability_sub_flags['execute.loco'] === 'True' ? true : false })
        this.setState({ explainability_sub_flags_execute_dts: config_master.explainability_sub_flags['execute.dts'] === 'True' ? true : false })
        this.setState({ explainability_sub_flags_execute_permutation_feature_importance: config_master.explainability_sub_flags['execute.permutation_feature_importance'] === 'True' ? true : false })
        this.setState({ explainability_sub_flags_execute_sklearn_feature_importance: config_master.explainability_sub_flags['execute.sklearn_feature_importance'] === 'True' ? true : false })
        this.setState({ local_explainability_local_explainability_index: config_master.local_explainability.local_explainability_index })
      })
  }

  handleClick = () => {
    //Master Config
    const { config_master, dataset_path_data_path, target_column_target_column_name, drop_columns_drop_column_list, impute_numeric_columns_numeric_column_list, impute_categorical_columns_categorical_column_list, bias_variables_bias_list, synthetic_data_parameters_epochs, synthetic_data_parameters_samples, counterfactual_parameter_n_samples, FLAGS_execute_preprocessing, FLAGS_execute_modelling, FLAGS_execute_exploratory_data_analysis, FLAGS_execute_global_explainability, FLAGS_execute_global_kernel_explainability, FLAGS_execute_global_linear_explainability, FLAGS_execute_local_explainability, FLAGS_execute_local_kernel_explainability, FLAGS_execute_local_linear_explainability, FLAGS_execute_bias, FLAGS_execute_fair_ml, FLAGS_execute_art, FLAGS_execute_synthetic_data_generator, FLAGS_execute_counterfactual_generator, explainability_sub_flags_execute_shap_local, explainability_sub_flags_execute_shap_global, explainability_sub_flags_execute_pdp, explainability_sub_flags_execute_ice, explainability_sub_flags_execute_lime, explainability_sub_flags_execute_loco, explainability_sub_flags_execute_dts, explainability_sub_flags_execute_permutation_feature_importance, explainability_sub_flags_execute_sklearn_feature_importance, local_explainability_local_explainability_index } = this.state

    config_master.dataset_path.data_path = dataset_path_data_path
    config_master.target_column.target_column_name = target_column_target_column_name
    config_master.drop_columns.drop_column_list = drop_columns_drop_column_list
    config_master.impute_numeric_columns.numeric_column_list = impute_numeric_columns_numeric_column_list
    config_master.impute_categorical_columns.categorical_column_list = impute_categorical_columns_categorical_column_list
    config_master.bias_variables.bias_list = bias_variables_bias_list
    config_master.synthetic_data_parameters.epochs = synthetic_data_parameters_epochs
    config_master.synthetic_data_parameters.samples = synthetic_data_parameters_samples
    config_master.counterfactual_parameter.n_samples = counterfactual_parameter_n_samples
    config_master.FLAGS['execute.preprocessing'] = FLAGS_execute_preprocessing === true ? 'True' : 'False'
    config_master.FLAGS['execute.modelling'] = FLAGS_execute_modelling === true ? 'True' : 'False'
    config_master.FLAGS['execute.exploratory_data_analysis'] = FLAGS_execute_exploratory_data_analysis === true ? 'True' : 'False'
    config_master.FLAGS['execute.global_explainability'] = FLAGS_execute_global_explainability === true ? 'True' : 'False'
    config_master.FLAGS['execute.global_kernel_explainability'] = FLAGS_execute_global_kernel_explainability === true ? 'True' : 'False'
    config_master.FLAGS['execute.global_linear_explainability'] = FLAGS_execute_global_linear_explainability === true ? 'True' : 'False'
    config_master.FLAGS['execute.local_explainability'] = FLAGS_execute_local_explainability === true ? 'True' : 'False'
    config_master.FLAGS['execute.local_kernel_explainability'] = FLAGS_execute_local_kernel_explainability === true ? 'True' : 'False'
    config_master.FLAGS['execute.local_linear_explainability'] = FLAGS_execute_local_linear_explainability === true ? 'True' : 'False'
    config_master.FLAGS['execute.bias'] = FLAGS_execute_bias === true ? 'True' : 'False'
    config_master.FLAGS['execute.fair_ml'] = FLAGS_execute_fair_ml === true ? 'True' : 'False'
    config_master.FLAGS['execute.art'] = FLAGS_execute_art === true ? 'True' : 'False'
    config_master.FLAGS['execute.synthetic_data_generator'] = FLAGS_execute_synthetic_data_generator === true ? 'True' : 'False'
    config_master.FLAGS['execute.counterfactual_generator'] = FLAGS_execute_counterfactual_generator === true ? 'True' : 'False'
    config_master.explainability_sub_flags['execute.shap_local'] = explainability_sub_flags_execute_shap_local === true ? 'True' : 'False'
    config_master.explainability_sub_flags['execute.shap_global'] = explainability_sub_flags_execute_shap_global === true ? 'True' : 'False'
    config_master.explainability_sub_flags['execute.pdp'] = explainability_sub_flags_execute_pdp === true ? 'True' : 'False'
    config_master.explainability_sub_flags['execute.ice'] = explainability_sub_flags_execute_ice === true ? 'True' : 'False'
    config_master.explainability_sub_flags['execute.lime'] = explainability_sub_flags_execute_lime === true ? 'True' : 'False'
    config_master.explainability_sub_flags['execute.loco'] = explainability_sub_flags_execute_loco === true ? 'True' : 'False'
    config_master.explainability_sub_flags['execute.dts'] = explainability_sub_flags_execute_dts === true ? 'True' : 'False'
    config_master.explainability_sub_flags['execute.permutation_feature_importance'] = explainability_sub_flags_execute_permutation_feature_importance === true ? 'True' : 'False'
    config_master.explainability_sub_flags['execute.sklearn_feature_importance'] = explainability_sub_flags_execute_sklearn_feature_importance === true ? 'True' : 'False'
    config_master.local_explainability.local_explainability_index = local_explainability_local_explainability_index

    fetch("http://localhost:3030" + '/config/structured/master', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config_master)
    }).then(() => {
      console.log('Master Config Request posted')
    })
  }

  render() {
    //Master Config
    const { dataset_path_data_path, target_column_target_column_name, drop_columns_drop_column_list, impute_numeric_columns_numeric_column_list, impute_categorical_columns_categorical_column_list, bias_variables_bias_list, synthetic_data_parameters_epochs, synthetic_data_parameters_samples, counterfactual_parameter_n_samples, FLAGS_execute_preprocessing, FLAGS_execute_modelling, FLAGS_execute_exploratory_data_analysis, FLAGS_execute_global_explainability, FLAGS_execute_global_kernel_explainability, FLAGS_execute_global_linear_explainability, FLAGS_execute_local_explainability, FLAGS_execute_local_kernel_explainability, FLAGS_execute_local_linear_explainability, FLAGS_execute_bias, FLAGS_execute_fair_ml, FLAGS_execute_art, FLAGS_execute_synthetic_data_generator, FLAGS_execute_counterfactual_generator, explainability_sub_flags_execute_shap_local, explainability_sub_flags_execute_shap_global, explainability_sub_flags_execute_pdp, explainability_sub_flags_execute_ice, explainability_sub_flags_execute_lime, explainability_sub_flags_execute_loco, explainability_sub_flags_execute_dts, explainability_sub_flags_execute_permutation_feature_importance, explainability_sub_flags_execute_sklearn_feature_importance, local_explainability_local_explainability_index } = this.state

    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1" block>
            <Row>
              <Col md={8} align="left">
                Master Config
              </Col>
              <Col md={4} align="right">
                {this.props.isActive ? <FontAwesomeIcon icon={faArrowCircleUp} /> : <FontAwesomeIcon icon={faArrowCircleDown} />}
              </Col>
            </Row>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <h5>[dataset_path]</h5>
            <Col md={4}>
              <Form.Label>data_path</Form.Label>
              <Form.Control size='sm' defaultValue={dataset_path_data_path} onChange={e => this.setState({ dataset_path_data_path: e.target.value })} />
            </Col>
            <br />
            <h5>[target_column]</h5>
            <Col md={4}>
              <Form.Label>target_column_name</Form.Label>
              <Form.Control size='sm' defaultValue={target_column_target_column_name} onChange={e => this.setState({ target_column_target_column_name: e.target.value })} />
            </Col>
            <br />
            {/*
            <h5>[drop_columns]</h5>
            <Col md={8}>
              <Form.Label>drop_column_list</Form.Label>
              <Form.Control size='sm' defaultValue={drop_columns_drop_column_list} onChange={e => this.setState({drop_columns_drop_column_list: e.target.value})}/>
            </Col>
            <br />
            <h5>[impute_numeric_columns]</h5>
            <Col md={8}>
              <Form.Label>numeric_column_list</Form.Label>
              <Form.Control size='sm' defaultValue={impute_numeric_columns_numeric_column_list} onChange={e => this.setState({impute_numeric_columns_numeric_column_list: e.target.value})}/>
            </Col>
            <br />
            <h5>[impute_categorical_columns]</h5>
            <Col md={8}>
              <Form.Label>categorical_column_list</Form.Label>
              <Form.Control size='sm' defaultValue={impute_categorical_columns_categorical_column_list} onChange={e => this.setState({impute_categorical_columns_categorical_column_list: e.target.value})}/>
            </Col>
            <br />
            <h5>[bias_variables]</h5>
            <Col md={8}>
              <Form.Label>bias_list</Form.Label>
              <Form.Control size='sm' defaultValue={bias_variables_bias_list} onChange={e => this.setState({bias_variables_bias_list: e.target.value})}/>
            </Col>
            */}
            <br />
            <h5>[synthetic_data_parameters]</h5>
            <Row>
              <Col md={6}>
                epochs
                <RangeSlider
                  value={synthetic_data_parameters_epochs}
                  min={0}
                  max={100}
                  step={1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ synthetic_data_parameters_epochs: changeEvent.target.value })} />
              </Col>
              <Col md={6}>
                samples
                <RangeSlider
                  value={synthetic_data_parameters_samples}
                  min={0}
                  max={100}
                  step={1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ synthetic_data_parameters_samples: changeEvent.target.value })} />
              </Col>
            </Row>
            <br />
            <h5>[counterfactual_parameter]</h5>
            <Col md={6}>
              n_samples
              <RangeSlider
                value={counterfactual_parameter_n_samples}
                min={0}
                max={100}
                step={1}
                tooltip={'on'}
                tooltipPlacement={'bottom'}
                onChange={changeEvent => this.setState({ counterfactual_parameter_n_samples: changeEvent.target.value })} />
            </Col>
            <br />
            <h5>[FLAGS]</h5>
            <Form.Check inline label="execute.preprocessing" type={'switch'} id={'check1'} checked={FLAGS_execute_preprocessing} onChange={changeEvent => this.setState({ FLAGS_execute_preprocessing: changeEvent.target.checked })} />
            <Form.Check inline label="execute.modelling" type={'switch'} id={'check2'} checked={FLAGS_execute_modelling} onChange={changeEvent => this.setState({ FLAGS_execute_modelling: changeEvent.target.checked })} />
            <Form.Check inline label="execute.exploratory_data_analysis" type={'switch'} id={'check3'} checked={FLAGS_execute_exploratory_data_analysis} onChange={changeEvent => this.setState({ FLAGS_execute_exploratory_data_analysis: changeEvent.target.checked })} />
            <Form.Check inline label="execute.global_explainability" type={'switch'} id={'check4'} checked={FLAGS_execute_global_explainability} onChange={changeEvent => this.setState({ FLAGS_execute_global_explainability: changeEvent.target.checked })} />
            <Form.Check inline label="execute.global_kernel_explainability" type={'switch'} id={'check5'} checked={FLAGS_execute_global_kernel_explainability} onChange={changeEvent => this.setState({ FLAGS_execute_global_kernel_explainability: changeEvent.target.checked })} />
            <Form.Check inline label="execute.global_linear_explainability" type={'switch'} id={'check6'} checked={FLAGS_execute_global_linear_explainability} onChange={changeEvent => this.setState({ FLAGS_execute_global_linear_explainability: changeEvent.target.checked })} />
            <Form.Check inline label="execute.local_explainability" type={'switch'} id={'check7'} checked={FLAGS_execute_local_explainability} onChange={changeEvent => this.setState({ FLAGS_execute_local_explainability: changeEvent.target.checked })} />
            <Form.Check inline label="execute.local_kernel_explainability" type={'switch'} id={'check8'} checked={FLAGS_execute_local_kernel_explainability} onChange={changeEvent => this.setState({ FLAGS_execute_local_kernel_explainability: changeEvent.target.checked })} />
            <Form.Check inline label="execute.local_linear_explainability" type={'switch'} id={'check9'} checked={FLAGS_execute_local_linear_explainability} onChange={changeEvent => this.setState({ FLAGS_execute_local_linear_explainability: changeEvent.target.checked })} />
            <Form.Check inline label="execute.bias" type={'switch'} id={'check10'} checked={FLAGS_execute_bias} onChange={changeEvent => this.setState({ FLAGS_execute_bias: changeEvent.target.checked })} />
            <Form.Check inline label="execute.fair_ml" type={'switch'} id={'check11'} checked={FLAGS_execute_fair_ml} onChange={changeEvent => this.setState({ FLAGS_execute_fair_ml: changeEvent.target.checked })} />
            <Form.Check inline label="execute.art" type={'switch'} id={'check12'} checked={FLAGS_execute_art} onChange={changeEvent => this.setState({ FLAGS_execute_art: changeEvent.target.checked })} />
            <Form.Check inline label="execute.synthetic_data_generator" type={'switch'} id={'check13'} checked={FLAGS_execute_synthetic_data_generator} onChange={changeEvent => this.setState({ FLAGS_execute_synthetic_data_generator: changeEvent.target.checked })} />
            <Form.Check inline label="execute.counterfactual_generator" type={'switch'} id={'check14'} checked={FLAGS_execute_counterfactual_generator} onChange={changeEvent => this.setState({ FLAGS_execute_counterfactual_generator: changeEvent.target.checked })} />
            <br /><br />
            <h5>[explainability_sub_flags]</h5>
            <Form.Check inline label="execute.shap_local" type={'switch'} id={'check15'} checked={explainability_sub_flags_execute_shap_local} onChange={changeEvent => this.setState({ explainability_sub_flags_execute_shap_local: changeEvent.target.checked })} />
            <Form.Check inline label="execute.shap_global" type={'switch'} id={'check16'} checked={explainability_sub_flags_execute_shap_global} onChange={changeEvent => this.setState({ explainability_sub_flags_execute_shap_global: changeEvent.target.checked })} />
            <Form.Check inline label="execute.pdp" type={'switch'} id={'check17'} checked={explainability_sub_flags_execute_pdp} onChange={changeEvent => this.setState({ explainability_sub_flags_execute_pdp: changeEvent.target.checked })} />
            <Form.Check inline label="execute.ice" type={'switch'} id={'check18'} checked={explainability_sub_flags_execute_ice} onChange={changeEvent => this.setState({ explainability_sub_flags_execute_ice: changeEvent.target.checked })} />
            <Form.Check inline label="execute.lime" type={'switch'} id={'check19'} checked={explainability_sub_flags_execute_lime} onChange={changeEvent => this.setState({ explainability_sub_flags_execute_lime: changeEvent.target.checked })} />
            <Form.Check inline label="execute.loco" type={'switch'} id={'check20'} checked={explainability_sub_flags_execute_loco} onChange={changeEvent => this.setState({ explainability_sub_flags_execute_loco: changeEvent.target.checked })} />
            <Form.Check inline label="execute.dts" type={'switch'} id={'check21'} checked={explainability_sub_flags_execute_dts} onChange={changeEvent => this.setState({ explainability_sub_flags_execute_dts: changeEvent.target.checked })} />
            <Form.Check inline label="execute.permutation_feature_importance" type={'switch'} id={'check22'} checked={explainability_sub_flags_execute_permutation_feature_importance} onChange={changeEvent => this.setState({ explainability_sub_flags_execute_permutation_feature_importance: changeEvent.target.checked })} />
            <Form.Check inline label="execute.sklearn_feature_importance" type={'switch'} id={'check23'} checked={explainability_sub_flags_execute_sklearn_feature_importance} onChange={changeEvent => this.setState({ explainability_sub_flags_execute_sklearn_feature_importance: changeEvent.target.checked })} />
            {/*
            <br /><br />
            <h5>[local_explainability]</h5>
            <Col md={8}>
              <Form.Label>local_explainability_index</Form.Label>
              <Form.Control size='sm' defaultValue={local_explainability_local_explainability_index} onChange={e => this.setState({local_explainability_local_explainability_index: e.target.value})}/>
            </Col>
            */}
            <br />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }
}

export default Index;
