import React from 'react';
import { Row, Col, Accordion, Card, Button, Form } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { textApi } from 'services';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Loading Config
      config_loading: {},
      dataset_dataset_path: '',
      dataset_input_column: '',
      dataset_target_column: '',
      preprocessing_remove_html_tags: 0,
      preprocessing_lowercase: 0,
      preprocessing_replace_misspell_words: 0,
      preprocessing_remove_numbers: 0,
      preprocessing_remove_non_alpha_numeric_char: 0,
      preprocessing_remove_stop_words: 0,
      preprocessing_lemmatize: 0,
      preprocessing_stemming: 0,
      preprocessing_imp_word_to_preserve: '',
      preprocessing_remove_duplicates: 0,
      preprocessing_remove_outliers: 0,
      preprocessing_remove_outliers_threshold: 0,
      preprocessing_remove_class_less_then_k_datapoints: 0,
      preprocessing_remove_class_less_then_k_datapoints_sample: 0,
      plots_word_frequency_plot: 0,
      plots_word_cloud_plot: 0,
      plots_class_distribution_plot: 0,
      plots_word_count_plot: 0,
      train_val_test_split_train_set_size: 0,
      train_val_test_split_validation_set_size: 0,
      train_val_test_split_test_set_size: 0,
      train_val_test_split_random_state: 0,
      train_val_test_split_stratify: 0,
      embedding_embedding_countvectoriser_execution: 0,
      embedding_embedding_tfidfvectoriser_execution: 0,
      embedding_max_feature: '',
      embedding_n_gram_range_start: 0,
      embedding_n_gram_range_end: 0,
    }
  }

  componentDidMount() {
    //Loading Config
    //textApi.fetch
    fetch("http://localhost:3030/config/text/loading")
      .then(response => response.json())
      .then(data => {
        this.setState({ config_loading: data._sections });
        const { config_loading } = this.state
        this.setState({ dataset_dataset_path: config_loading.dataset.dataset_path })
        this.setState({ dataset_input_column: config_loading.dataset.input_column })
        this.setState({ dataset_target_column: config_loading.dataset.target_column })
        this.setState({ preprocessing_remove_html_tags: config_loading.preprocessing.remove_html_tags === 'True' ? true : false })
        this.setState({ preprocessing_lowercase: config_loading.preprocessing.lowercase === 'True' ? true : false })
        this.setState({ preprocessing_replace_misspell_words: config_loading.preprocessing.replace_misspell_words === 'True' ? true : false })
        this.setState({ preprocessing_remove_numbers: config_loading.preprocessing.remove_numbers === 'True' ? true : false })
        this.setState({ preprocessing_remove_non_alpha_numeric_char: config_loading.preprocessing.remove_non_alpha_numeric_char === 'True' ? true : false })
        this.setState({ preprocessing_remove_stop_words: config_loading.preprocessing.remove_stop_words === 'True' ? true : false })
        this.setState({ preprocessing_lemmatize: config_loading.preprocessing.lemmatize === 'True' ? true : false })
        this.setState({ preprocessing_stemming: config_loading.preprocessing.stemming === 'True' ? true : false })
        this.setState({ preprocessing_imp_word_to_preserve: config_loading.preprocessing.imp_word_to_preserve })
        this.setState({ preprocessing_remove_duplicates: config_loading.preprocessing.remove_duplicates === 'True' ? true : false })
        this.setState({ preprocessing_remove_outliers: config_loading.preprocessing.remove_outliers === 'True' ? true : false })
        this.setState({ preprocessing_remove_outliers_threshold: config_loading.preprocessing.remove_outliers_threshold })
        this.setState({ preprocessing_remove_class_less_then_k_datapoints: config_loading.preprocessing.remove_class_less_then_k_datapoints === 'True' ? true : false })
        this.setState({ preprocessing_remove_class_less_then_k_datapoints_sample: config_loading.preprocessing.remove_class_less_then_k_datapoints_sample })
        this.setState({ plots_word_frequency_plot: config_loading.plots.word_frequency_plot === 'True' ? true : false })
        this.setState({ plots_word_cloud_plot: config_loading.plots.word_cloud_plot === 'True' ? true : false })
        this.setState({ plots_class_distribution_plot: config_loading.plots.class_distribution_plot === 'True' ? true : false })
        this.setState({ plots_word_count_plot: config_loading.plots.word_count_plot === 'True' ? true : false })
        this.setState({ train_val_test_split_train_set_size: config_loading.train_val_test_split.train_set_size })
        this.setState({ train_val_test_split_validation_set_size: config_loading.train_val_test_split.validation_set_size })
        this.setState({ train_val_test_split_test_set_size: config_loading.train_val_test_split.test_set_size })
        this.setState({ train_val_test_split_random_state: config_loading.train_val_test_split.random_state })
        this.setState({ train_val_test_split_stratify: config_loading.train_val_test_split.stratify === 'True' ? true : false })
        this.setState({ embedding_embedding_countvectoriser_execution: config_loading.embedding['embedding.countvectoriser.execution'] === 'True' ? true : false })
        this.setState({ embedding_embedding_tfidfvectoriser_execution: config_loading.embedding['embedding.tfidfvectoriser.execution'] === 'True' ? true : false })
        this.setState({ embedding_max_feature: config_loading.embedding.max_feature })
        this.setState({ embedding_n_gram_range_start: config_loading.embedding.n_gram_range_start })
        this.setState({ embedding_n_gram_range_end: config_loading.embedding.n_gram_range_end })
      });
  }

  handleClick = () => {
    //Loading Config
    const { config_loading, dataset_dataset_path, dataset_input_column, dataset_target_column, preprocessing_remove_html_tags, preprocessing_lowercase, preprocessing_replace_misspell_words, preprocessing_remove_numbers, preprocessing_remove_non_alpha_numeric_char, preprocessing_remove_stop_words, preprocessing_lemmatize, preprocessing_stemming, preprocessing_imp_word_to_preserve, preprocessing_remove_duplicates, preprocessing_remove_outliers, preprocessing_remove_outliers_threshold, preprocessing_remove_class_less_then_k_datapoints, preprocessing_remove_class_less_then_k_datapoints_sample, plots_word_frequency_plot, plots_word_cloud_plot, plots_class_distribution_plot, plots_word_count_plot, train_val_test_split_train_set_size, train_val_test_split_validation_set_size, train_val_test_split_test_set_size, train_val_test_split_random_state, train_val_test_split_stratify, embedding_embedding_countvectoriser_execution, embedding_embedding_tfidfvectoriser_execution, embedding_max_feature, embedding_n_gram_range_start, embedding_n_gram_range_end } = this.state

    config_loading.dataset.dataset_path = dataset_dataset_path
    config_loading.dataset.input_column = dataset_input_column
    config_loading.dataset.target_column = dataset_target_column
    config_loading.preprocessing.remove_html_tags = preprocessing_remove_html_tags === true ? 'True' : 'False'
    config_loading.preprocessing.lowercase = preprocessing_lowercase === true ? 'True' : 'False'
    config_loading.preprocessing.replace_misspell_words = preprocessing_replace_misspell_words === true ? 'True' : 'False'
    config_loading.preprocessing.remove_numbers = preprocessing_remove_numbers === true ? 'True' : 'False'
    config_loading.preprocessing.remove_non_alpha_numeric_char = preprocessing_remove_non_alpha_numeric_char === true ? 'True' : 'False'
    config_loading.preprocessing.remove_stop_words = preprocessing_remove_stop_words === true ? 'True' : 'False'
    config_loading.preprocessing.lemmatize = preprocessing_lemmatize === true ? 'True' : 'False'
    config_loading.preprocessing.stemming = preprocessing_stemming === true ? 'True' : 'False'
    config_loading.preprocessing.imp_word_to_preserve = preprocessing_imp_word_to_preserve
    config_loading.preprocessing.remove_duplicates = preprocessing_remove_duplicates === true ? 'True' : 'False'
    config_loading.preprocessing.remove_outliers = preprocessing_remove_outliers === true ? 'True' : 'False'
    config_loading.preprocessing.remove_outliers_threshold = preprocessing_remove_outliers_threshold
    config_loading.preprocessing.remove_class_less_then_k_datapoints = preprocessing_remove_class_less_then_k_datapoints === true ? 'True' : 'False'
    config_loading.preprocessing.remove_class_less_then_k_datapoints_sample = preprocessing_remove_class_less_then_k_datapoints_sample
    config_loading.plots.word_frequency_plot = plots_word_frequency_plot === true ? 'True' : 'False'
    config_loading.plots.word_cloud_plot = plots_word_cloud_plot === true ? 'True' : 'False'
    config_loading.plots.class_distribution_plot = plots_class_distribution_plot === true ? 'True' : 'False'
    config_loading.plots.word_count_plot = plots_word_count_plot === true ? 'True' : 'False'
    config_loading.train_val_test_split.train_set_size = train_val_test_split_train_set_size
    config_loading.train_val_test_split.validation_set_size = train_val_test_split_validation_set_size
    config_loading.train_val_test_split.test_set_size = train_val_test_split_test_set_size
    config_loading.train_val_test_split.random_state = train_val_test_split_random_state
    config_loading.train_val_test_split.stratify = train_val_test_split_stratify === true ? 'True' : 'False'
    config_loading.embedding['embedding.countvectoriser.execution'] = embedding_embedding_countvectoriser_execution === true ? 'True' : 'False'
    config_loading.embedding['embedding.tfidfvectoriser.execution'] = embedding_embedding_tfidfvectoriser_execution === true ? 'True' : 'False'
    config_loading.embedding.max_feature = embedding_max_feature
    config_loading.embedding.n_gram_range_start = embedding_n_gram_range_start
    config_loading.embedding.n_gram_range_end = embedding_n_gram_range_end

    fetch("http://localhost:3030/config/text/loading", {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config_loading)
    }).then(() => {
      console.log('Loading Request posted')
    })
  }

  render() {
    //Loading Config
    const { dataset_dataset_path, dataset_input_column, dataset_target_column, preprocessing_remove_html_tags, preprocessing_lowercase, preprocessing_replace_misspell_words, preprocessing_remove_numbers, preprocessing_remove_non_alpha_numeric_char, preprocessing_remove_stop_words, preprocessing_lemmatize, preprocessing_stemming, preprocessing_imp_word_to_preserve, preprocessing_remove_duplicates, preprocessing_remove_outliers, preprocessing_remove_outliers_threshold, preprocessing_remove_class_less_then_k_datapoints, preprocessing_remove_class_less_then_k_datapoints_sample, plots_word_frequency_plot, plots_word_cloud_plot, plots_class_distribution_plot, plots_word_count_plot, train_val_test_split_train_set_size, train_val_test_split_validation_set_size, train_val_test_split_test_set_size, train_val_test_split_random_state, train_val_test_split_stratify, embedding_embedding_countvectoriser_execution, embedding_embedding_tfidfvectoriser_execution, embedding_max_feature, embedding_n_gram_range_start, embedding_n_gram_range_end } = this.state;

    return (
      <Accordion.Item eventKey="0">
        <Accordion.Header >
          Loading Config
        </Accordion.Header>
        <Accordion.Body>
          <h5>[dataset]</h5>
          <Row>
            <Col md={4}>
              <Form.Label>dataset_path</Form.Label>
              <Form.Control size='sm' defaultValue={dataset_dataset_path} onChange={e => this.setState({ dataset_dataset_path: e.target.value })} />
            </Col>
            <Col md={4}>
              <Form.Label>input_column</Form.Label>
              <Form.Control size='sm' defaultValue={dataset_input_column} onChange={e => this.setState({ dataset_input_column: e.target.value })} />
            </Col>
            <Col md={4}>
              <Form.Label>target_column</Form.Label>
              <Form.Control size='sm' defaultValue={dataset_target_column} onChange={e => this.setState({ dataset_target_column: e.target.value })} />
            </Col>
          </Row>
          <br />
          <h5>[preprocessing]</h5>
          <Row>
            <Col md={4}>
              <Form.Check inline label="remove_html_tags" type={'switch'} id={'check1'} checked={preprocessing_remove_html_tags} onChange={changeEvent => this.setState({ preprocessing_remove_html_tags: changeEvent.target.checked })} />
              <br />
            </Col>
            <Col md={4}>
              <Form.Check inline label="lowercase" type={'switch'} id={'check2'} checked={preprocessing_lowercase} onChange={changeEvent => this.setState({ preprocessing_lowercase: changeEvent.target.checked })} />
              <br />
            </Col>
            <Col md={4}>
              <Form.Check inline label="replace_misspell_words" type={'switch'} id={'check3'} checked={preprocessing_replace_misspell_words} onChange={changeEvent => this.setState({ preprocessing_replace_misspell_words: changeEvent.target.checked })} />
              <br />
            </Col>
            <Col md={4}>
              <Form.Check inline label="remove_numbers" type={'switch'} id={'check4'} checked={preprocessing_remove_numbers} onChange={changeEvent => this.setState({ preprocessing_remove_numbers: changeEvent.target.checked })} />
              <br />
            </Col>
            <Col md={4}>
              <Form.Check inline label="remove_non_alpha_numeric_char" type={'switch'} id={'check5'} checked={preprocessing_remove_non_alpha_numeric_char} onChange={changeEvent => this.setState({ preprocessing_remove_non_alpha_numeric_char: changeEvent.target.checked })} />
              <br />
            </Col>
            <Col md={4}>
              <Form.Check inline label="remove_stop_words" type={'switch'} id={'check6'} checked={preprocessing_remove_stop_words} onChange={changeEvent => this.setState({ preprocessing_remove_stop_words: changeEvent.target.checked })} />
              <br />
            </Col>
            <Col md={4}>
              <Form.Check inline label="lemmatize" type={'switch'} id={'check7'} checked={preprocessing_lemmatize} onChange={changeEvent => this.setState({ preprocessing_lemmatize: changeEvent.target.checked })} />
              <br />
            </Col>
            <Col md={4}>
              <Form.Check inline label="stemming" type={'switch'} id={'check8'} checked={preprocessing_stemming} onChange={changeEvent => this.setState({ preprocessing_stemming: changeEvent.target.checked })} />
              <br />
            </Col>
            <Col md={4}>
              <Form.Label>imp_word_to_preserve</Form.Label>
              <Form.Control size='sm' defaultValue={preprocessing_imp_word_to_preserve} onChange={e => this.setState({ preprocessing_imp_word_to_preserve: e.target.value })} />
              <br />
            </Col>
            <Col md={4}>
              <Form.Check inline label="remove_duplicates" type={'switch'} id={'check9'} checked={preprocessing_remove_duplicates} onChange={changeEvent => this.setState({ preprocessing_remove_duplicates: changeEvent.target.checked })} />
              <br />
            </Col>
            <Col md={4}>
              <Form.Check inline label="remove_outliers" type={'switch'} id={'check10'} checked={preprocessing_remove_outliers} onChange={changeEvent => this.setState({ preprocessing_remove_outliers: changeEvent.target.checked })} />
              <br />
            </Col>
            <Col md={4}>
              remove_outliers_threshold
              <RangeSlider
                value={preprocessing_remove_outliers_threshold}
                min={0}
                max={2}
                step={0.01}
                tooltip={'on'}
                tooltipPlacement={'bottom'}
                onChange={changeEvent => this.setState({ preprocessing_remove_outliers_threshold: changeEvent.target.value })} />
            </Col>
            <Form.Check inline label="remove_class_less_then_k_datapoints" type={'switch'} id={'check11'} checked={preprocessing_remove_class_less_then_k_datapoints} onChange={changeEvent => this.setState({ preprocessing_remove_class_less_then_k_datapoints: changeEvent.target.checked })} />
            <Col md={4}>
              remove_class_less_then_k_datapoints_sample
              <RangeSlider
                value={preprocessing_remove_class_less_then_k_datapoints_sample}
                min={0}
                max={100}
                step={1}
                tooltip={'on'}
                tooltipPlacement={'bottom'}
                onChange={changeEvent => this.setState({ preprocessing_remove_class_less_then_k_datapoints_sample: changeEvent.target.value })} />
            </Col>
          </Row>
          <br />
          <h5>[plots]</h5>
          <Row>
            <Col md={3}>
              <Form.Check inline label="word_frequency_plot" type={'switch'} id={'check12'} checked={plots_word_frequency_plot} onChange={changeEvent => this.setState({ plots_word_frequency_plot: changeEvent.target.checked })} />
            </Col>
            <Col md={3}>
              <Form.Check inline label="word_cloud_plot" type={'switch'} id={'check13'} checked={plots_word_cloud_plot} onChange={changeEvent => this.setState({ plots_word_cloud_plot: changeEvent.target.checked })} />
            </Col>
            <Col md={3}>
              <Form.Check inline label="class_distribution_plot" type={'switch'} id={'check14'} checked={plots_class_distribution_plot} onChange={changeEvent => this.setState({ plots_class_distribution_plot: changeEvent.target.checked })} />
            </Col>
            <Col md={3}>
              <Form.Check inline label="word_count_plot" type={'switch'} id={'check15'} checked={plots_word_count_plot} onChange={changeEvent => this.setState({ plots_word_count_plot: changeEvent.target.checked })} />
            </Col>
          </Row>
          <br />
          <br />
          <h5>[train_val_test_split]</h5>
          <Row>
            <Col md={3}>
              train_set_size
              <RangeSlider
                value={train_val_test_split_train_set_size}
                min={0}
                max={2}
                step={0.1}
                tooltip={'on'}
                tooltipPlacement={'bottom'}
                onChange={changeEvent => this.setState({ train_val_test_split_train_set_size: changeEvent.target.value })} />
            </Col>
            <Col md={3}>
              validation_set_size
              <RangeSlider
                value={train_val_test_split_validation_set_size}
                min={0}
                max={2}
                step={0.1}
                tooltip={'on'}
                tooltipPlacement={'bottom'}
                onChange={changeEvent => this.setState({ train_val_test_split_validation_set_size: changeEvent.target.value })} />
            </Col>
            <Col md={3}>
              test_set_size
              <RangeSlider
                value={train_val_test_split_test_set_size}
                min={0}
                max={2}
                step={0.1}
                tooltip={'on'}
                tooltipPlacement={'bottom'}
                onChange={changeEvent => this.setState({ train_val_test_split_test_set_size: changeEvent.target.value })} />
            </Col>
            <Col md={3}>
              <Form.Check inline label="stratify" type={'switch'} id={'check16'} checked={train_val_test_split_stratify} onChange={changeEvent => this.setState({ train_val_test_split_stratify: changeEvent.target.checked })} />
            </Col>
          </Row>
          <br />
          <br />
          <h5>[embedding]</h5>
          <Row>
            <Col md={4}>
              <Form.Check inline label="embedding_countvectoriser_execution" type={'switch'} id={'check17'} checked={embedding_embedding_countvectoriser_execution} onChange={changeEvent => this.setState({ embedding_embedding_countvectoriser_execution: changeEvent.target.checked })} />
            </Col>
            <Col md={4}>
              <Form.Check inline label="embedding_tfidfvectoriser_execution" type={'switch'} id={'check18'} checked={embedding_embedding_tfidfvectoriser_execution} onChange={changeEvent => this.setState({ embedding_embedding_tfidfvectoriser_execution: changeEvent.target.checked })} />
            </Col>
            <Col md={4}>
              <Form.Label>max_feature</Form.Label>
              <Form.Control size='sm' defaultValue={embedding_max_feature} onChange={e => this.setState({ embedding_max_feature: e.target.value })} />
            </Col>
            <Col md={4}>
              n_gram_range_start
              <RangeSlider
                value={embedding_n_gram_range_start}
                min={0}
                max={10}
                step={1}
                tooltip={'on'}
                tooltipPlacement={'bottom'}
                onChange={changeEvent => this.setState({ embedding_n_gram_range_start: changeEvent.target.value })} />
            </Col>
            <Col md={4}>
              n_gram_range_end
              <RangeSlider
                value={embedding_n_gram_range_end}
                min={0}
                max={10}
                step={1}
                tooltip={'on'}
                tooltipPlacement={'bottom'}
                onChange={changeEvent => this.setState({ embedding_n_gram_range_end: changeEvent.target.value })} />
            </Col>
          </Row>
      </Accordion.Body>
    </Accordion.Item>
    )
  }
}

export default Index;
