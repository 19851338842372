import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { withTranslation } from "react-i18next";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  let strs = s.split("_");
  for (let i = 0; i < strs.length; i++) {
    strs[i] = strs[i].charAt(0).toUpperCase() + strs[i].slice(1);
  }
  return strs.join(" ");
};

function Navigation(props) {
  var { data } = props;
  const linkHtml = data.map((link, i) => {
    var linkStr = "#" + link.split(" ").join("").toLowerCase();
    link = link.charAt(0).toUpperCase() + link.slice(1);
    return (
      <Nav.Item key={i}>
        <Nav.Link href={linkStr}>{capitalize(link)} </Nav.Link>
      </Nav.Item>
    );
  });
  var linkStr = "#" + data[0];
  return (
    <Navbar
      collapseOnSelect
      variant="primary"
      expand="lg"
      sticky="top"
      style={{
        zIndex: 100,
        backgroundColor: `${localStorage.getItem("bgcolor") ?? "#000"}`,
        borderBottom: `1px solid ${
          localStorage.getItem("themecolor") ?? "#000"
        }`,
      }}
    >
      <Container>
        <Navbar.Brand href="#home">
          <h1 className="navBrand">Report</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="myNavbar" />
        <Navbar.Collapse id="myNavbar">
          <Nav defaultActiveKey={linkStr} variant="pills">
            {linkHtml}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
