import React, { useState, useEffect } from "react";
import { Button, Alert, Row, Form, Col } from "react-bootstrap";
import { auth } from "services";
const AccessControl = ({ adminuser }) => {
  const [AlertMssg, setAlertMssg] = useState("");
  const [addadmin, setAddadmin] = useState("");
  const [adminlist, setAdminlist] = useState([]);

  useEffect(() => {
    auth.checkIfAdmin().then((users) => {
      if (users != null) {
        var myUsers = [];
        users.forEach((user) => {
          myUsers.push(user.get("username"));
        });
        setAdminlist(myUsers);
      }
    });
  }, [addadmin]);

  let hello = adminlist.filter((user) => user === addadmin);

  const newadmin = async (e) => {
    if (hello[0] === addadmin) {
      setAlertMssg("User Already Admin");
      return;
    }
    if (addadmin === "") {
      setAlertMssg("Please Enter Email");
    } else if (addadmin === adminuser) {
      setAlertMssg("Super Admin cannot be a Admin");
    } else if (addadmin.includes("testaing.com")) {
      auth.addnewadmin(addadmin).then((value) => {
        setAlertMssg(value);
        setAddadmin("");
        setTimeout(() => {
          setAlertMssg("");
        }, 5000);
      });
    }
  };

  const removeadmin = (id) => {
    if (hello[0] !== addadmin) {
      setAlertMssg("User Not a admin");
      return;
    }
    if (addadmin === "") {
      setAlertMssg("Please Enter Email");
    } else {
      auth.removeadmin(addadmin).then((value) => {
        console.log(value);
        setAlertMssg(value);
        setAddadmin("");
        setTimeout(() => {
          setAlertMssg("");
        }, 5000);
      });
    }
  };

  return (
    <div>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label className="labels">Email </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              placeholder="eg: himal.b@testaing.com"
              onChange={(event) => {
                setAddadmin(event.target.value);
                setAlertMssg("");
              }}
              value={addadmin}
            />
          </Form.Group>
          <div style={{ display: "flex" }}>
            <Button
              style={{ marginRight: "10px" }}
              onClick={(e) => {
                newadmin();
              }}
              className="myButton"
            >
              Provide Access
            </Button>
            <Button
              onClick={(e) => {
                removeadmin();
                // window.location.reload(true);
              }}
              className="myButton"
            >
              Remove Access
            </Button>
          </div>
        </Col>

        <Col lg={6}>
          <div className="form-group focused">
            <br /> <label className="labels">Admin Lists</label>
            {adminlist.map((keys) => (
              <li key={keys}>{keys}</li>
            ))}
          </div>
        </Col>
      </Row>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group focused">
            <br />
            {AlertMssg ? (
              <Alert
                variant={
                  AlertMssg === "User Removed Sucessfully"
                    ? "danger"
                    : "success"
                }
              >
                {AlertMssg}
              </Alert>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessControl;
