import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Row,
  Col,
  Form,
  Accordion,
  Card,
  Button,
  Tabs,
  Tab,
  TabContainer,
} from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import RangeSlider from "react-bootstrap-range-slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";
const Index = forwardRef((props, ref) => {
  const [configArt, setConfigArt] = useState({});
  const [parametersNSample, setParametersNSample] = useState(0);
  const [fgsmSetExecution, setFgsmSetExecution] = useState(false);
  const [fgsmEpsilon, setFgsmEpsilon] = useState(0);
  const [ifsSetExecution, setIfsSetExecution] = useState(false);
  const [upSetExecution, setUpSetExecution] = useState(false);
  const [upMaxIter, setUpMaxIter] = useState(0);
  const [upTargeted, setUpTargeted] = useState(false);
  const [upAttacker, setUpAttacker] = useState(0);
  const [bimSetExecution, setBimSetExecution] = useState(false);
  const [jsmSetExecution, setJsmSetExecution] = useState(false);
  const [dfSetExecution, setDfSetExecution] = useState(false);
  const [enSetExecution, setEnSetExecution] = useState(false);
  const [nfSetExecution, setNfSetExecution] = useState(false);
  const [clinfSetExecution, setClinfSetExecution] = useState(false);
  const [hsjSetExecution, setHsjSetExecution] = useState(false);
  const [paSetExecution, setPaSetExecution] = useState(false);
  const [zooSetExecution, setZooSetExecution] = useState(false);
  const [stSetExecution, setStSetExecution] = useState(false);
  const [stMaxTrans, setStMaxTrans] = useState(0);
  const [stNumTrans, setStNumTrans] = useState(0);
  const [stMaxRot, setStMaxRot] = useState(0);
  const [stNumRot, setStNumRot] = useState(0);
  const [dbbSetExecution, setDbbSetExecution] = useState(false);
  const [dbbDelta, setDbbDelta] = useState(0);
  const [dbbEpsilon, setDbbEpsilon] = useState(0);
  const [dbbTargeted, setDbbTargeted] = useState(false);

  useEffect(() => {
    fetch("http://localhost:3030/config/image/art")
      .then((response) => response.json())
      .then((data) => {
        const responseData = data._sections;
        setConfigArt(responseData);
        setParametersNSample(responseData.parameters.n_sample);
        setFgsmSetExecution(
          responseData.FGSM.set_execution === "True" ? true : false
        );
        setFgsmEpsilon(responseData.FGSM.epsilon);
        setIfsSetExecution(
          responseData.IFS.set_execution === "True" ? true : false
        );
        setUpSetExecution(
          responseData.UP.set_execution === "True" ? true : false
        );
        setUpMaxIter(responseData.UP.max_iter);
        setUpTargeted(responseData.UP.targeted === "True" ? true : false);
        setUpAttacker(responseData.UP.attacker);
        setBimSetExecution(
          responseData.BIM.set_execution === "True" ? true : false
        );
        setJsmSetExecution(
          responseData.JSM.set_execution === "True" ? true : false
        );
        setDfSetExecution(
          responseData.DF.set_execution === "True" ? true : false
        );
        setEnSetExecution(
          responseData.EN.set_execution === "True" ? true : false
        );
        setNfSetExecution(
          responseData.NF.set_execution === "True" ? true : false
        );
        setClinfSetExecution(
          responseData.CLInf.set_execution === "True" ? true : false
        );
        setHsjSetExecution(
          responseData.HSJ.set_execution === "True" ? true : false
        );
        setPaSetExecution(
          responseData.PA.set_execution === "True" ? true : false
        );
        setZooSetExecution(
          responseData.ZOO.set_execution === "True" ? true : false
        );
        setStSetExecution(
          responseData.ST.set_execution === "True" ? true : false
        );
        setStMaxTrans(responseData.ST.max_translation);
        setStNumTrans(responseData.ST.num_translations);
        setStMaxRot(responseData.ST.max_rotation);
        setStNumRot(responseData.ST.num_rotations);
        setDbbSetExecution(
          responseData.DBB.set_execution === "True" ? true : false
        );
        setDbbDelta(responseData.DBB.delta);
        setDbbEpsilon(responseData.DBB.epsilon);
        setDbbTargeted(responseData.DBB.targeted === "True" ? true : false);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, []);

  useImperativeHandle(ref, () => ({
    handleClick() {
      handleClick();
    },
  }));
  const handleClick = () => {
    //Attack Vectors
    setConfigArt((prev) => {
      let tempVariable = { ...prev };
      tempVariable.parameters.n_sample = parametersNSample;
      tempVariable.FGSM.set_execution =
        fgsmSetExecution === true ? "True" : "False";

      tempVariable.FGSM.epsilon = fgsmEpsilon;

      tempVariable.IFS.set_execution =
        ifsSetExecution === true ? "True" : "False";

      tempVariable.UP.set_execution =
        upSetExecution === true ? "True" : "False";
      tempVariable.UP.max_iter = upMaxIter;

      tempVariable.UP.targeted = upTargeted === true ? "True" : "False";
      tempVariable.UP.attacker = upAttacker;

      tempVariable.BIM.set_execution =
        bimSetExecution === true ? "True" : "False";

      tempVariable.JSM.set_execution =
        jsmSetExecution === true ? "True" : "False";

      tempVariable.DF.set_execution =
        dfSetExecution === true ? "True" : "False";

      tempVariable.EN.set_execution =
        enSetExecution === true ? "True" : "False";

      tempVariable.NF.set_execution =
        nfSetExecution === true ? "True" : "False";

      tempVariable.CLInf.set_execution =
        clinfSetExecution === true ? "True" : "False";

      tempVariable.HSJ.set_execution =
        hsjSetExecution === true ? "True" : "False";

      tempVariable.PA.set_execution =
        paSetExecution === true ? "True" : "False";

      tempVariable.ZOO.set_execution =
        zooSetExecution === true ? "True" : "False";

      tempVariable.ST.set_execution =
        stSetExecution === true ? "True" : "False";

      tempVariable.ST.max_translation = stMaxTrans;

      tempVariable.ST.num_translations = stNumTrans;

      tempVariable.ST.max_rotation = stMaxRot;

      tempVariable.ST.num_rotations = stNumRot;

      tempVariable.DBB.set_execution =
        dbbSetExecution === true ? "True" : "False";

      tempVariable.DBB.delta = dbbDelta;

      tempVariable.DBB.epsilon = dbbEpsilon;

      tempVariable.DBB.targeted = dbbTargeted === true ? "True" : "False";
      return tempVariable;
    });

    fetch("http://localhost:3030" + "/config/image/art", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(configArt),
    }).then(() => {
      console.log("Art Request posted");
    });
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <h5>[parameters]</h5>
          n_sample
          <RangeSlider
            value={parametersNSample}
            min={32}
            max={128}
            step={2}
            tooltip={"on"}
            tooltipPlacement={"bottom"}
            onChange={(e) => setParametersNSample(e.target.value)}
          />
        </Col>

        {/* <Col md={6}>
          <h5>Categorization of attacks</h5>
          <Form.Control as="select" size="sm" custom>
            <option>Low Threat & Fast Execution</option>
            <option>Moderate</option>
            <option>Exhaustive</option>
          </Form.Control>
        </Col> */}
      </Row>
      <br />
      <br />
      <Tab.Container>
        <Tabs defaultActiveKey="0" id="technique">
          <Tab eventKey="0" title="[FGSM]">
            <Row>
              <Col md={4}>
                <Form.Check
                  inline
                  label="set_execution"
                  type={"switch"}
                  id={"check1"}
                  checked={fgsmSetExecution}
                  onChange={(e) => setFgsmSetExecution(e.target.checked)}
                />
              </Col>
              <Col md={4}>
                epsilon
                <RangeSlider
                  value={fgsmEpsilon}
                  min={0}
                  max={1}
                  step={0.1}
                  tooltip={"on"}
                  onChange={(e) => setFgsmEpsilon(e.target.value)}
                />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="1" title="[IFS]">
            <Form.Check
              inline
              label="set_execution"
              type={"switch"}
              id={"check2"}
              checked={ifsSetExecution}
              onChange={(e) => setIfsSetExecution(e.target.checked)}
            />
          </Tab>
          <Tab eventKey="2" title="[UP]">
            <Row>
              <Col md={3}>
                <Form.Check
                  inline
                  label="set_execution"
                  type={"switch"}
                  id={"check3"}
                  checked={upSetExecution}
                  onChange={(e) => setUpSetExecution(e.target.checked)}
                />
              </Col>
              <Col md={5}>
                max_iter
                <RangeSlider
                  value={upMaxIter}
                  min={0}
                  max={10}
                  step={1}
                  tooltip={"on"}
                  onChange={(e) => setUpMaxIter(e.target.value)}
                />
              </Col>
              <Col md={3}>
                <Form.Check
                  inline
                  label="targeted"
                  type={"switch"}
                  id={"check4"}
                  checked={upTargeted}
                  onChange={(e) => setUpTargeted(e.target.checked)}
                />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="3" title="[BIM]">
            <Form.Check
              inline
              label="set_execution"
              type={"switch"}
              id={"check5"}
              checked={bimSetExecution}
              onChange={(e) => setBimSetExecution(e.target.checked)}
            />
          </Tab>
          <Tab eventKey="4" title="[JSM]">
            <Form.Check
              inline
              label="set_execution"
              type={"switch"}
              id={"check6"}
              checked={jsmSetExecution}
              onChange={(e) => setJsmSetExecution(e.target.checked)}
            />
          </Tab>
          <Tab eventKey="5" title="[DF]">
            <Form.Check
              inline
              label="set_execution"
              type={"switch"}
              id={"check7"}
              checked={dfSetExecution}
              onChange={(e) => setDfSetExecution(e.target.checked)}
            />
          </Tab>
          <Tab eventKey="6" title="[EN]">
            <Form.Check
              inline
              label="set_execution"
              type={"switch"}
              id={"check8"}
              checked={enSetExecution}
              onChange={(e) => setEnSetExecution(e.target.checked)}
            />
          </Tab>
          <Tab eventKey="7" title="[NF]">
            <Form.Check
              inline
              label="set_execution"
              type={"switch"}
              id={"check9"}
              checked={nfSetExecution}
              onChange={(e) => setNfSetExecution(e.target.checked)}
            />
          </Tab>
          <Tab eventKey="8" title="[Clinf]">
            <Form.Check
              inline
              label="set_execution"
              type={"switch"}
              id={"check10"}
              checked={clinfSetExecution}
              onChange={(e) => setClinfSetExecution(e.target.checked)}
            />
          </Tab>
          <Tab eventKey="9" title="[HSJ]">
            <Form.Check
              inline
              label="set_execution"
              type={"switch"}
              id={"check11"}
              checked={hsjSetExecution}
              onChange={(e) => setHsjSetExecution(e.target.checked)}
            />
          </Tab>
          <Tab eventKey="10" title="[PA]">
            <Form.Check
              inline
              label="set_execution"
              type={"switch"}
              id={"check12"}
              checked={paSetExecution}
              onChange={(e) => setPaSetExecution(e.target.checked)}
            />
          </Tab>
          <Tab eventKey="11" title="[ZOO]">
            <Form.Check
              inline
              label="set_execution"
              type={"switch"}
              id={"check13"}
              checked={zooSetExecution}
              onChange={(e) => setZooSetExecution(e.target.checked)}
            />
          </Tab>
          <Tab eventKey="12" title="[ST]">
            <Row>
              <Col md={2}>
                <Form.Check
                  inline
                  label="set_execution"
                  type={"switch"}
                  id={"check14"}
                  checked={stSetExecution}
                  onChange={(e) => setStSetExecution(e.target.checked)}
                />
              </Col>
              <Col md={2}>
                max_translation
                <RangeSlider
                  value={stMaxTrans}
                  min={0}
                  max={10}
                  step={1}
                  tooltip={"on"}
                  tooltipPlacement={"bottom"}
                  onChange={(e) => setStMaxTrans(e.target.value)}
                />
              </Col>
              <Col md={2}>
                num_translations
                <RangeSlider
                  value={stNumTrans}
                  min={0}
                  max={100}
                  step={2}
                  tooltip={"on"}
                  tooltipPlacement={"bottom"}
                  onChange={(e) => setStNumTrans(e.target.value)}
                />
              </Col>
              <Col md={2}>
                max_rotation
                <RangeSlider
                  value={stMaxRot}
                  min={0}
                  max={10}
                  step={1}
                  tooltip={"on"}
                  tooltipPlacement={"bottom"}
                  onChange={(e) => setStMaxRot(e.target.value)}
                />
              </Col>
              <Col md={2}>
                num_rotations
                <RangeSlider
                  value={stNumRot}
                  min={0}
                  max={100}
                  step={2}
                  tooltip={"on"}
                  tooltipPlacement={"bottom"}
                  onChange={(e) => setStNumRot(e.target.value)}
                />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="13" title="[DBB]">
            <Row>
              <Col md={2}>
                <Form.Check
                  inline
                  label="set_execution"
                  type={"switch"}
                  id={"check15"}
                  checked={dbbSetExecution}
                  onChange={(e) => setDbbSetExecution(e.target.checked)}
                />
              </Col>
              <Col md={4}>
                delta
                <RangeSlider
                  value={dbbDelta}
                  min={0}
                  max={0.01}
                  step={0.001}
                  tooltip={"on"}
                  tooltipPlacement={"bottom"}
                  onChange={(e) => setDbbDelta(e.target.value)}
                />
              </Col>
              <Col md={4}>
                epsilon
                <RangeSlider
                  value={dbbEpsilon}
                  min={0}
                  max={0.01}
                  step={0.001}
                  tooltip={"on"}
                  tooltipPlacement={"bottom"}
                  onChange={(e) => setDbbEpsilon(e.target.value)}
                />
              </Col>
              <Col md={2}>
                <Form.Check
                  inline
                  label="targeted"
                  type={"switch"}
                  id={"check16"}
                  checked={dbbTargeted}
                  onChange={(e) => setDbbTargeted(e.target.checked)}
                />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Tab.Container>
    </div>
  );
});
export default Index;
