import React from 'react';
import { Row, Col, Form, Accordion, Card, Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { textApi } from "services";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Model Explainability
      config_explain: {},
      global_explainability_execute_explainability_shap_global: 0,
      global_explainability_execute_explainability_decision_tree_surrogate: 0,
      global_explainability_execute_explainability_sklearn_implicit: 0,
      parameters_shap_global_linear_visualise_n_feature: 0,
      parameters_shap_global_tree_n_samples: 0,
      parameters_logistic_regression_visualise_n_feature: 0,
      parameters_decision_tree_surrogate_visualise_n_feature: 0,
      parameters_lime_exp_num_features: 0,
      parameters_lime_exp_top_labels: 0,
      parameters_fairml_visualise_n_feature: 0,
      local_explainability_execute_explainability_lime_exp: 0,
      local_explainability_execute_explainability_shap_local: 0,
      local_explainability_execute_explainability_eli5_exp: 0,
      fairml_execute_explainability_fairml: 0,
    }
  }

  componentDidMount() {
    //Model Explainability
    fetch("http://localhost:3030" + "/config/text/explain")
      .then(response => response.json())
      .then(data => {
        this.setState({ config_explain: data._sections });
        const { config_explain } = this.state
        console.log('Model Explainability');
        console.log(config_explain);
        this.setState({ global_explainability_execute_explainability_shap_global: config_explain.global_explainability['execute.explainability.shap_global'] === 'True' ? true : false })
        this.setState({ global_explainability_execute_explainability_decision_tree_surrogate: config_explain.global_explainability['execute.explainability.decision_tree_surrogate'] === 'True' ? true : false })
        this.setState({ global_explainability_execute_explainability_sklearn_implicit: config_explain.global_explainability['execute.explainability.sklearn_implicit'] === 'True' ? true : false })
        this.setState({ parameters_shap_global_linear_visualise_n_feature: config_explain.parameters['shap_global_linear.visualise_n_feature'] })
        this.setState({ parameters_shap_global_tree_n_samples: config_explain.parameters['shap_global_tree.n_samples'] })
        this.setState({ parameters_logistic_regression_visualise_n_feature: config_explain.parameters['logistic_regression.visualise_n_feature'] })
        this.setState({ parameters_decision_tree_surrogate_visualise_n_feature: config_explain.parameters['decision_tree_surrogate.visualise_n_feature'] })
        this.setState({ parameters_lime_exp_num_features: config_explain.parameters['lime_exp.num_features'] })
        this.setState({ parameters_lime_exp_top_labels: config_explain.parameters['lime_exp.top_labels'] })
        this.setState({ parameters_fairml_visualise_n_feature: config_explain.parameters['fairml.visualise_n_feature'] })
        this.setState({ local_explainability_execute_explainability_lime_exp: config_explain.local_explainability['execute.explainability.lime_exp'] === 'True' ? true : false })
        this.setState({ local_explainability_execute_explainability_shap_local: config_explain.local_explainability['execute.explainability.shap_local'] === 'True' ? true : false })
        this.setState({ local_explainability_execute_explainability_eli5_exp: config_explain.local_explainability['execute.explainability.eli5_exp'] === 'True' ? true : false })
        this.setState({ fairml_execute_explainability_fairml: config_explain.fairml['execute.explainability.fairml'] === 'True' ? true : false })
      })
  }

  handleClick = () => {
    //Model Explainability
    const { config_explain, global_explainability_execute_explainability_shap_global, global_explainability_execute_explainability_decision_tree_surrogate, global_explainability_execute_explainability_sklearn_implicit, parameters_shap_global_linear_visualise_n_feature, parameters_shap_global_tree_n_samples, parameters_logistic_regression_visualise_n_feature, parameters_decision_tree_surrogate_visualise_n_feature, parameters_lime_exp_num_features, parameters_lime_exp_top_labels, parameters_fairml_visualise_n_feature, local_explainability_execute_explainability_lime_exp, local_explainability_execute_explainability_shap_local, local_explainability_execute_explainability_eli5_exp, fairml_execute_explainability_fairml } = this.state

    config_explain.global_explainability['execute.explainability.shap_global'] = global_explainability_execute_explainability_shap_global === true ? 'True' : 'False'
    config_explain.global_explainability['execute.explainability.decision_tree_surrogate'] = global_explainability_execute_explainability_decision_tree_surrogate === true ? 'True' : 'False'
    config_explain.global_explainability['execute.explainability.sklearn_implicit'] = global_explainability_execute_explainability_sklearn_implicit === true ? 'True' : 'False'
    config_explain.parameters['shap_global_linear.visualise_n_feature'] = parameters_shap_global_linear_visualise_n_feature
    config_explain.parameters['shap_global_tree.n_samples'] = parameters_shap_global_tree_n_samples
    config_explain.parameters['logistic_regression.visualise_n_feature'] = parameters_logistic_regression_visualise_n_feature
    config_explain.parameters['decision_tree_surrogate.visualise_n_feature'] = parameters_decision_tree_surrogate_visualise_n_feature
    config_explain.parameters['lime_exp.num_features'] = parameters_lime_exp_num_features
    config_explain.parameters['lime_exp.top_labels'] = parameters_lime_exp_top_labels
    config_explain.parameters['fairml.visualise_n_feature'] = parameters_fairml_visualise_n_feature
    config_explain.local_explainability['execute.explainability.lime_exp'] = local_explainability_execute_explainability_lime_exp === true ? 'True' : 'False'
    config_explain.local_explainability['execute.explainability.shap_local'] = local_explainability_execute_explainability_shap_local === true ? 'True' : 'False'
    config_explain.local_explainability['execute.explainability.eli5_exp'] = local_explainability_execute_explainability_eli5_exp === true ? 'True' : 'False'
    config_explain.fairml['execute.explainability.fairml'] = fairml_execute_explainability_fairml === true ? 'True' : 'False'

    fetch("http://localhost:3030" + '/config/text/explain', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config_explain)
    }).then(() => {
      console.log('Model Explainability Request posted')
    })
  }

  render() {
    //Model Explainability
    const { global_explainability_execute_explainability_shap_global, global_explainability_execute_explainability_decision_tree_surrogate, global_explainability_execute_explainability_sklearn_implicit, parameters_shap_global_linear_visualise_n_feature, parameters_shap_global_tree_n_samples, parameters_logistic_regression_visualise_n_feature, parameters_decision_tree_surrogate_visualise_n_feature, parameters_lime_exp_num_features, parameters_lime_exp_top_labels, parameters_fairml_visualise_n_feature, local_explainability_execute_explainability_lime_exp, local_explainability_execute_explainability_shap_local, local_explainability_execute_explainability_eli5_exp, fairml_execute_explainability_fairml } = this.state

    return (
      <Accordion.Item eventKey="2">
        <Accordion.Header >
          Model Explainability
        </Accordion.Header>
        <Accordion.Body>
            <h5>[global_explainability]</h5>
            <Form.Check inline label="execute.explainability.shap_global" type={'switch'} id={'check23'} checked={global_explainability_execute_explainability_shap_global} onChange={changeEvent => this.setState({ global_explainability_execute_explainability_shap_global: changeEvent.target.checked })} />
            <Form.Check inline label="execute.explainability.decision_tree_surrogate" type={'switch'} id={'check24'} checked={global_explainability_execute_explainability_decision_tree_surrogate} onChange={changeEvent => this.setState({ global_explainability_execute_explainability_decision_tree_surrogate: changeEvent.target.checked })} />
            <Form.Check inline label="execute.explainability.sklearn_implicit" type={'switch'} id={'check25'} checked={global_explainability_execute_explainability_sklearn_implicit} onChange={changeEvent => this.setState({ global_explainability_execute_explainability_sklearn_implicit: changeEvent.target.checked })} />
            <br />
            <br />
            <h5>[parameters]</h5>
            <Row>
              <Col md={4}>
                shap_global_linear.visualise_n_feature
                <RangeSlider
                  value={parameters_shap_global_linear_visualise_n_feature}
                  min={0}
                  max={100}
                  step={1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ parameters_shap_global_linear_visualise_n_feature: changeEvent.target.value })} />
              </Col>
              <Col md={4}>
                shap_global_tree.n_samples
                <RangeSlider
                  value={parameters_shap_global_tree_n_samples}
                  min={0}
                  max={10000}
                  step={10}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ parameters_shap_global_tree_n_samples: changeEvent.target.value })} />
              </Col>
              <Col md={4}>
                logistic_regression.visualise_n_feature
                <RangeSlider
                  value={parameters_logistic_regression_visualise_n_feature}
                  min={0}
                  max={100}
                  step={1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ parameters_logistic_regression_visualise_n_feature: changeEvent.target.value })} />
              </Col>
            </Row><br />
            <Row>
              <Col md={4}>
                decision_tree_surrogate.visualise_n_feature
                <RangeSlider
                  value={parameters_decision_tree_surrogate_visualise_n_feature}
                  min={0}
                  max={100}
                  step={1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ parameters_decision_tree_surrogate_visualise_n_feature: changeEvent.target.value })} />
              </Col>
              <Col md={4}>
                lime_exp.num_features
                <RangeSlider
                  value={parameters_lime_exp_num_features}
                  min={0}
                  max={20}
                  step={1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ parameters_lime_exp_num_features: changeEvent.target.value })} />
              </Col>
              <Col md={4}>
                lime_exp.top_labels
                <RangeSlider
                  value={parameters_lime_exp_top_labels}
                  min={0}
                  max={20}
                  step={1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ parameters_lime_exp_top_labels: changeEvent.target.value })} />
              </Col>
            </Row><br />
            <Row>
              <Col md={4}>
                fairml.visualise_n_feature
                <RangeSlider
                  value={parameters_fairml_visualise_n_feature}
                  min={0}
                  max={100}
                  step={1}
                  tooltip={'on'}
                  tooltipPlacement={'bottom'}
                  onChange={changeEvent => this.setState({ parameters_fairml_visualise_n_feature: changeEvent.target.value })} />
              </Col>
            </Row>
            <br />
            <br />
            <h5>[local_explainability]</h5>
            <Form.Check inline label="execute.explainability.lime_exp" type={'switch'} id={'check26'} checked={local_explainability_execute_explainability_lime_exp} onChange={changeEvent => this.setState({ local_explainability_execute_explainability_lime_exp: changeEvent.target.checked })} />
            <Form.Check inline label="execute.explainability.shap_local" type={'switch'} id={'check27'} checked={local_explainability_execute_explainability_shap_local} onChange={changeEvent => this.setState({ local_explainability_execute_explainability_shap_local: changeEvent.target.checked })} />
            <Form.Check inline label="execute.explainability.eli5_exp" type={'switch'} id={'check28'} checked={local_explainability_execute_explainability_eli5_exp} onChange={changeEvent => this.setState({ local_explainability_execute_explainability_eli5_exp: changeEvent.target.checked })} />
            <br />
            <br />
            <h5>[fairml]</h5>
            <Form.Check inline label="execute.explainability.fairml" type={'switch'} id={'check29'} checked={fairml_execute_explainability_fairml} onChange={changeEvent => this.setState({ fairml_execute_explainability_fairml: changeEvent.target.checked })} />
          </Accordion.Body>
        </Accordion.Item>
    )
  }
}

export default Index;
