import React from 'react';
import { Row, Col, Form, Accordion, Card, Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
// import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Deepxplore
      modeling_execute_modeling_logistic_regression2: 0,
      modeling_execute_modeling_random_forest2: 0,
      modeling_parameters_random_search2: 0,
      modeling_parameters_grid_search2: 0
    }
  }

  componentDidMount() {
    //Deepxplore
    fetch("http://localhost:3030" + "/config/text/deep")
      .then(response => response.json())
      .then(data => {
        this.setState({ config_deepxplore: data._sections });
        const { config_deepxplore } = this.state
        console.log('Deepxplore');
        console.log(config_deepxplore);
        this.setState({ modeling_execute_modeling_logistic_regression2: config_deepxplore.modeling['execute.modeling.logistic_regression'] === 'True' ? true : false })
        this.setState({ modeling_execute_modeling_random_forest2: config_deepxplore.modeling['execute.modeling.random_forest'] === 'True' ? true : false })
        this.setState({ modeling_parameters_random_search2: config_deepxplore.modeling['parameters.random_search'] === 'True' ? true : false })
        this.setState({ modeling_parameters_grid_search2: config_deepxplore.modeling['parameters.grid_search'] === 'True' ? true : false })
      })
  }

  handleClick = () => {
    //Deepxplore
    const { config_deepxplore, modeling_execute_modeling_logistic_regression2, modeling_execute_modeling_random_forest2, modeling_parameters_random_search2, modeling_parameters_grid_search2 } = this.state

    config_deepxplore.modeling['execute.modeling.logistic_regression'] = modeling_execute_modeling_logistic_regression2 === true ? 'True' : 'False'
    config_deepxplore.modeling['execute.modeling.random_forest'] = modeling_execute_modeling_random_forest2 === true ? 'True' : 'False'
    config_deepxplore.modeling['parameters.random_search'] = modeling_parameters_random_search2 === true ? 'True' : 'False'
    config_deepxplore.modeling['parameters.grid_search'] = modeling_parameters_grid_search2 === true ? 'True' : 'False'

    fetch("http://localhost:3030" + '/config/text/deep', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config_deepxplore)
    }).then(() => {
      console.log('Deepxplore Request posted')
    })
  }

  render() {
    //Deepxplore
    const { modeling_execute_modeling_logistic_regression2, modeling_execute_modeling_random_forest2, modeling_parameters_random_search2, modeling_parameters_grid_search2 } = this.state

    return (
      <Accordion.Item eventKey="3">
        <Accordion.Header >
          Deepxplore
        </Accordion.Header>
        <Accordion.Body>
            <h5>[modeling]</h5>
            <Row>
              <Col md={4}>
                <Form.Check inline label="execute.modeling.logistic_regression" type={'switch'} id={'check30'} checked={modeling_execute_modeling_logistic_regression2} onChange={changeEvent => this.setState({ modeling_execute_modeling_logistic_regression2: changeEvent.target.checked })} />
                <br />
              </Col>
              <Col md={4}>
                <Form.Check inline label="execute.modeling.random_forest" type={'switch'} id={'check31'} checked={modeling_execute_modeling_random_forest2} onChange={changeEvent => this.setState({ modeling_execute_modeling_random_forest2: changeEvent.target.checked })} />
                <br />
              </Col>
              <Col md={4}>
                <Form.Check inline label="parameters.random_search" type={'switch'} id={'check32'} checked={modeling_parameters_random_search2} onChange={changeEvent => this.setState({ modeling_parameters_random_search2: changeEvent.target.checked })} />
                <br />
              </Col>
              <Col md={4}>
                <Form.Check inline label="parameters.grid_search" type={'switch'} id={'check33'} checked={modeling_parameters_grid_search2} onChange={changeEvent => this.setState({ modeling_parameters_grid_search2: changeEvent.target.checked })} />
                <br />
              </Col>
            </Row>
        </Accordion.Body>
      </Accordion.Item>
    )
  }
}

export default Index;
